import styled from "@emotion/styled";
import SingleInputForm from "comps/forms/SingleInputForm";
import RubyText from "comps/RubyText";
import useBasicQuizHitProcess from "hooks/useBasicQuizHitProcess";
import useBasicQuizMatchMethods from "hooks/useBasicQuizMatchMethods";
import { Container } from "react-bootstrap";
import BaseQuiz from "types/quiz/BaseQuiz";
import QuizComp from "types/quiz/QuizComp";
import WithAnswer from "types/quiz/WithAnswer";
import WithNihongo from "types/quiz/WithNihongo";

export interface TKQuiz extends WithAnswer<WithNihongo<BaseQuiz>> {}

const Wrapper = styled(Container)`
  margin: 4rem 0;
  font-size: 3rem;
  text-align: center;
`;

const ToKoreanQuiz: QuizComp<TKQuiz> = ({
  quiz: { id, answer, answers, nihongo },
  onDone,
}) => {
  const { isMatch } = useBasicQuizMatchMethods();
  const processHit = useBasicQuizHitProcess({
    onDone,
    quiz_id: id,
    answerPredicate: (t) => {
      return [answer, ...answers].reduce((prev, a) => {
        return prev || isMatch(t, a);
      }, false);
    },
    onWrong: () => {
      alert(answer);
    },
  });

  return (
    <Wrapper>
      <RubyText>{nihongo}</RubyText>
      <SingleInputForm onSubmit={processHit} />
    </Wrapper>
  );
};

export default ToKoreanQuiz;
