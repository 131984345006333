import { genDefaultChapterProps } from "../utils";
import MainConv22 from "./steps/MainConv";

const ch22 = genDefaultChapterProps(22);

ch22.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv22 />,
});

export default ch22;
