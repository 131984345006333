import {
  removePunctuations,
  rubyTextToHiragana,
  rubyTextToNihongo,
} from "utils";

const match = (a: string, b: string) => {
  const an = rubyTextToNihongo(removePunctuations(a));
  const ah = rubyTextToHiragana(removePunctuations(a));

  const bn = rubyTextToNihongo(removePunctuations(b));
  const bh = rubyTextToHiragana(removePunctuations(b));

  return an === bn || ah === bh;
};

const useBasicQuizMatchMethods = () => {
  const isMatch = (target: string, answer: string | string[]) => {
    if (typeof answer === "string") return match(target, answer);
    else
      return answer.reduce((prev, a) => {
        return prev || match(target, a);
      }, false);
  };

  return { isMatch };
};

export default useBasicQuizMatchMethods;
