import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv20: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch20/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch20/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさん、",
                      trans: "김 씨,",
                    },
                    {
                      nihongo: "これは",
                      trans: "이것은",
                    },
                    {
                      nihongo: "{何|なん}ですか。",
                      trans: "무엇입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{高|こう}{校|こう}の",
                      trans: "고등학교",
                    },
                    {
                      nihongo: "ときの",
                      trans: "때의",
                    },
                    {
                      nihongo: "{写|しゃ}{真|しん}です。",
                      trans: "사진입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{見|み}ても",
                      trans: "봐도",
                    },
                    {
                      nihongo: "いいですか。",
                      trans: "되겠습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ええ、",
                      trans: "예,",
                    },
                    {
                      nihongo: "でも、",
                      trans: "하지만",
                    },
                    {
                      nihongo: "{笑|わら}っては",
                      trans: "웃으면",
                    },
                    {
                      nihongo: "いけませんよ。",
                      trans: "안됩니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{今|いま}とは",
                      trans: "지금과는",
                    },
                    {
                      nihongo: "{全|ぜん}{然|ぜん}",
                      trans: "전혀",
                    },
                    {
                      nihongo: "{違|ちが}いますから。",
                      trans: "다르기 때문에.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "へえ、",
                      trans: "에~",
                    },
                    {
                      nihongo: "この",
                      trans: "이",
                    },
                    {
                      nihongo: "{人|ひと}が",
                      trans: "사람이",
                    },
                    {
                      nihongo: "{本|ほん}{当|とう}に",
                      trans: "정말로",
                    },
                    {
                      nihongo: "キムさんですか。",
                      trans: "김 씨 입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですよ。",
                      trans: "그렇습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{高|こう}{校|こう}の",
                      trans: "고등학교",
                    },
                    {
                      nihongo: "ときは",
                      trans: "때는",
                    },
                    {
                      nihongo: "{今|いま}より",
                      trans: "지금보다",
                    },
                    {
                      nihongo: "１０キロも",
                      trans: "10킬로나",
                    },
                    {
                      nihongo: "{太|ふと}って",
                      trans: "살쪘기",
                    },
                    {
                      nihongo: "いましたから。",
                      trans: "때문에.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "どうやって",
                      trans: "어떻게",
                    },
                    {
                      nihongo: "やせましたか。",
                      trans: "살을 뺏나요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/10.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{毎|まい}{日|にち}",
                      trans: "매일",
                    },
                    {
                      nihongo: "ジムに",
                      trans: "헬스클럽에",
                    },
                    {
                      nihongo: "{通|かよ}って、",
                      trans: "다니고,",
                    },
                    {
                      nihongo: "{運|うん}{動|どう}を",
                      trans: "운동을",
                    },
                    {
                      nihongo: "しました。",
                      trans: "했습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch20/main-conv/11.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{頑|かん}{張|ば}り",
                      trans: "열심히",
                    },
                    {
                      nihongo: "ましたね。",
                      trans: "했군요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv20;
