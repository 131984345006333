import MyProgress from "comps/MyProgress";
import HitRecord from "comps/Quizzes/_comps/HitRecord";
import useAuth from "hooks/useAuth";
import { Col, Row } from "react-bootstrap";

interface Props {
  total: number;
  done: number;
  quiz_id: string;
}

const BasicQuizzesProgress: React.FC<Props> = ({ total, done, quiz_id }) => {
  const { isSignedIn } = useAuth();

  return (
    <Row>
      <Col>
        <MyProgress total={total} done={done} />
      </Col>
      {isSignedIn && (
        <Col>
          <HitRecord quiz_id={quiz_id} />
        </Col>
      )}
    </Row>
  );
};

export default BasicQuizzesProgress;
