import {
  removePunctuations as rp,
  rubyTextToHiragana,
  rubyTextToNihongo,
  strToRuby,
} from "utils";
import { GetQuizzesQuery } from "__generated__/graphql";

type TQuiz = GetQuizzesQuery["quizzes"]["quizzes"][0];

class QuizManager {
  reverse?: boolean;
  quiz: TQuiz;
  constructor(quiz: TQuiz, reverse?: boolean) {
    this.quiz = quiz;
    this.reverse = reverse;
  }

  question() {
    if (this.reverse) return this.quiz.answer;
    return strToRuby(this.quiz.question);
  }

  isAnswerCorrect(answer: any) {
    if (this.reverse) {
      return (
        answer === rubyTextToHiragana(this.quiz.question) ||
        answer === rubyTextToNihongo(this.quiz.question)
      );
    } else {
      return (
        rp(answer) === rp(this.quiz.answer) ||
        this.quiz.answers.map(rp).includes(rp(answer))
      );
    }
  }

  correctAnswer() {
    if (this.reverse) {
      return `${rubyTextToNihongo(this.quiz.question)}(${rubyTextToHiragana(
        this.quiz.question
      )})`;
    }
    return this.quiz.answer;
  }
}

export default QuizManager;
