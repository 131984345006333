import { genDefaultChapterProps } from "../utils";
import MainConv19 from "./steps/MainConv";

const ch19 = genDefaultChapterProps(19);

ch19.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv19 />,
});

export default ch19;
