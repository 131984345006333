import { genDefaultChapterProps } from "../utils";
import Ch13MainConv from "./steps/Ch13MainConv";

const ch13 = genDefaultChapterProps(13);

ch13.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch13MainConv />,
});

export default ch13;
