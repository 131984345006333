import ExpandableMenu from "comps/sidebar/ExpandableMenu";
import qs from "qs";
import { PropsWithChildren } from "react";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { QuizzesQueryInput } from "__generated__/graphql";

const StartQuizButton: React.FC<PropsWithChildren<QuizzesQueryInput>> = ({
  children,
  tags,
  level,
  answer,
  examples,
  hiragana,
  question,
}) => {
  const query = qs.stringify({
    tags,
    level,
    answer,
    examples,
    hiragana,
    question,
  });
  const to = `start-quiz?${query}`;
  return (
    <Link to={to} className="btn btn-link">
      {children}
    </Link>
  );
};

const SideBarMenus: React.FC = () => {
  return (
    <Stack>
      <ExpandableMenu menu="완전초보">
        <Stack>
          <StartQuizButton tags={["히라가나 기본"]}>히라가나</StartQuizButton>
          <StartQuizButton tags={["가타카나 기본"]}>가타카나</StartQuizButton>
        </Stack>
      </ExpandableMenu>
      <ExpandableMenu menu="JLPT 5급">
        <Stack>
          <StartQuizButton tags={["가타카나", "단어"]}>
            가타카나 단어
          </StartQuizButton>
          <StartQuizButton tags={["이형용사"]}>이형용사</StartQuizButton>
          <StartQuizButton tags={["나형용사"]}>나형용사</StartQuizButton>
          <StartQuizButton tags={["동사"]}>동사</StartQuizButton>
        </Stack>
      </ExpandableMenu>
    </Stack>
  );
};

export default SideBarMenus;
