import MyProgress from "comps/MyProgress";
import HitRecord from "comps/Quizzes/_comps/HitRecord";
import useAuth from "hooks/useAuth";
import useListIdxMethods from "hooks/useListIdxMethods";
import useQuizHitMethod from "hooks/useQuizHitMethod";
import useSortQuizByHits from "hooks/useSortQuizByHits";
import { Col, Container, Row } from "react-bootstrap";
import { GetQuizHitsQuery } from "__generated__/graphql";
import DOXQuiz, { TDOXQuiz } from "./DOXQuiz";

interface Props {
  onEnd?: VoidFunction;
  quizzes: TDOXQuiz[];
  quizHits?: GetQuizHitsQuery["quizHits"];
}

const DialogueOXQuizzes: React.FC<Props> = ({
  onEnd,
  quizzes: unsortedQuizzes,
  quizHits,
}) => {
  const quizzes = useSortQuizByHits({
    unsortedQuizzes,
    quizHits,
  });

  const total = quizzes.length;
  const { index, increaseIndex } = useListIdxMethods({ total, onEnd });

  const curQuiz = quizzes[index];

  const { isSignedIn } = useAuth();
  const { quizHitCorrect, quizHitWrong } = useQuizHitMethod();

  const isCorrectAnswer = (answer: boolean) => {
    return curQuiz.answer === answer;
  };

  const handleQuizAnswer = (answer: any) => {
    if (isCorrectAnswer(answer)) {
      if (isSignedIn) {
        quizHitCorrect(curQuiz.id);
      }
    } else {
      alert(curQuiz.reason);
      if (isSignedIn) {
        quizHitWrong(curQuiz.id);
      }
    }
    increaseIndex();
  };

  return (
    <Container>
      <Row>
        <Col>
          <MyProgress total={total} done={index} />
        </Col>
        <Col>
          <HitRecord quiz_id={curQuiz.id} />
        </Col>
      </Row>
      <DOXQuiz {...curQuiz} onAnswer={handleQuizAnswer} />
    </Container>
  );
};

export default DialogueOXQuizzes;
