import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv6: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch6/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch6/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch6/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "この",
                      trans: "이",
                    },
                    {
                      nihongo: "{白|しろ}い",
                      trans: "하얀",
                    },
                    {
                      nihongo: "ケータイは",
                      trans: "휴대폰은",
                    },
                    {
                      nihongo: "キムさんのですか。",
                      trans: "김 씨의 것입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch6/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "それは",
                      trans: "그것은",
                    },
                    {
                      nihongo: "{私|わたし}のです。",
                      trans: "제 것입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch6/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{軽|かる}くて",
                      trans: "가볍고",
                    },
                    {
                      nihongo: "いいですね。",
                      trans: "좋네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch6/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                    {
                      nihongo: "でも、",
                      trans: "하지만,",
                    },
                    {
                      nihongo: "{古|ふる}いです。",
                      trans: "낡았어요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch6/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんの",
                      trans: "야마다 씨의",
                    },
                    {
                      nihongo: "ケータイは",
                      trans: "휴대폰은",
                    },
                    {
                      nihongo: "どうですか。",
                      trans: "어떻습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch6/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}のケータイは",
                      trans: "제 휴대폰은",
                    },
                    {
                      nihongo: "{新|あたら}しいですが、",
                      trans: "새롭지만,",
                    },
                    {
                      nihongo: "{重|おも}いです。",
                      trans: "무겁습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv6;
