import { genDefaultChapterProps } from "../utils";
import Ch3MainConv from "./steps/MainConv";

const ch3 = genDefaultChapterProps(3);

ch3.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch3MainConv />,
});

export default ch3;
