/**
 * @description for internal use only
 */
class AudioController {
  audio: HTMLAudioElement;
  constructor(url?: string) {
    this.audio = new Audio(url);
  }

  stop() {
    this.audio.removeEventListener("canplaythrough", this.#_play);
    if (!this.audio.paused) {
      this.audio.pause();
    }
    this.audio.currentTime = 0;
  }

  #_play = () => {
    this.audio.play();
  };

  play() {
    this.stop();
    this.audio.addEventListener("canplaythrough", this.#_play, {
      once: true,
    });
  }
}

export default AudioController;
