import { Container, ProgressBar } from "react-bootstrap";

interface Props {
  total: number;
  done: number;
}
const MyProgress: React.FC<Props> = ({ total = 0, done = 0 }) => {
  if (total === 0) {
    total = 1;
    done = 1;
  }
  const ratio = Math.round((done / total) * 100);
  const remain = total - done;
  return (
    <Container>
      <div>
        <strong>남은갯수 :</strong>
        <span className="text-danger fs-1">{remain}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>총 갯수 :</strong> <span className="fs-1">{total}</span>
      </div>
      <ProgressBar max={total} now={done} label={`${ratio}%`} animated />
    </Container>
  );
};

export default MyProgress;
