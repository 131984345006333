import MainConv from "../../MainConv";
import { buildAssetsUrl } from "utils/url";

const Ch13MainConv: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch13/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch13/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "{夏|なつ}{休|やす}みは",
                      trans: "여름 휴가는",
                    },
                    { nihongo: "どうでしたか。", trans: "어땠습니까?" },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{旅|りょ}{行|こう}が",
                      trans: "여행이",
                    },
                    {
                      nihongo: "{楽|たの}しかったです。",
                      trans: "즐거웠습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{旅|りょ}{行|こう}は",
                      trans: "여행은",
                    },
                    {
                      nihongo: "いつから",
                      trans: "언제부터",
                    },
                    {
                      nihongo: "いつまで",
                      trans: "언제까지",
                    },
                    {
                      nihongo: "でしたか。",
                      trans: "였습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "７月２９日から",
                      trans: "7월 29일부터",
                    },
                    {
                      nihongo: "８月４日まででした。",
                      trans: "8월 4일까지였습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いい",
                      trans: "좋은",
                    },
                    {
                      nihongo: "{天|てん}{気|き}",
                      trans: "날씨",
                    },
                    {
                      nihongo: "でしたが、",
                      trans: "였습니다만,",
                    },
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{暑|あつ}かったです。",
                      trans: "더웠습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ホテルは",
                      trans: "호텔은",
                    },
                    {
                      nihongo: "どうでしたか。",
                      trans: "어땠습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch13/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "あまり",
                      trans: "그다지",
                    },
                    {
                      nihongo: "{安|やす}く",
                      trans: "싸지",
                    },
                    {
                      nihongo: "ありませんでしたが、",
                      trans: "않았습니다만,",
                    },
                    {
                      nihongo: "{料|りょう}{理|り}も",
                      trans: "요리도",
                    },
                    {
                      nihongo: "おいしくて、",
                      trans: "맛있고,",
                    },
                    {
                      nihongo: "{部|へ}{屋|や}も",
                      trans: "방도",
                    },
                    {
                      nihongo: "きれいでした。",
                      trans: "깨끗했습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default Ch13MainConv;
