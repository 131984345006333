import BaseVoiceController from "./BaseVoiceCtroller";
import VoiceController, {
  VoiceControllerConstructorOptions,
} from "./VoiceController";

export interface DialogueVoiceControllerConstructorOptions {
  full: VoiceControllerConstructorOptions;
  sentences: VoiceControllerConstructorOptions[];
}

class DialogueVoiceController implements BaseVoiceController {
  full: VoiceController;
  sentences: VoiceController[];

  constructor(options: DialogueVoiceControllerConstructorOptions) {
    this.full = new VoiceController(options.full);
    this.sentences = options.sentences.map((op) => new VoiceController(op));
  }

  get allVoices() {
    return [this.full, ...this.sentences];
  }

  stopAll() {
    this.allVoices.forEach((voice) => voice.stop());
  }

  playFull() {
    this.stopAll();
    this.full.play();
  }

  /**
   * @param index start from 0
   */
  playSentence(index: number) {
    this.stopAll();
    this.sentences[index].play();
  }

  play() {
    this.playFull();
  }
  stop() {
    this.stopAll();
  }
}

export default DialogueVoiceController;
