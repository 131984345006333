import { genDefaultChapterProps } from "../utils";
import MainConv18 from "./steps/MainConv";

const ch18 = genDefaultChapterProps(18);

ch18.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv18 />,
});

export default ch18;
