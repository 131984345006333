import MyBreadcrumb from "comps/MyBreadcrumb";
import WordsByQuizInput from "comps/Words/WordsByQuizInput";
import QuizzesByQuizInput from "comps/_quizzes/QuizzesByQuizInput";
import { Navigate, Route, Routes } from "react-router-dom";
import ChapterBase from "../ChapterBase";
import CountryQuizzes from "./steps/CountryQuizzes";
import CountryWords from "./steps/CountryWords";
import JobQuizzes from "./steps/JobQuizzes";
import JobWords from "./steps/JobWords";
import Learn from "./steps/Learn";
import MainConv2 from "./steps/MainConv";
import PDQuizzes from "./steps/PDQuizzes";

const Chapter2: React.FC = () => {
  return (
    <ChapterBase>
      <ChapterBase.Header>
        <ChapterBase.Heading>CH 2.</ChapterBase.Heading>
        <MyBreadcrumb>
          <MyBreadcrumb.Item to="word">기본단어</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="word-quiz">기본단어 퀴즈</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="main-conversation">
            메인 대화
          </MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="learn">익히기</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="find-dialogue-picture">
            맞는 그림 찾기
          </MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="fill-blank">빈칸 채우기</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="words-job">단어(직업)</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="words-job-quiz">퀴즈(직업)</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="words-country">단어(나라)</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="words-country-quiz">
            퀴즈(나라)
          </MyBreadcrumb.Item>
          {/* <MyBreadcrumb.Item to="to-korean-quiz">한글쓰기</MyBreadcrumb.Item>
        <MyBreadcrumb.Item to="to-nihongo-quiz">일본어쓰기</MyBreadcrumb.Item>
        <MyBreadcrumb.Item to="dictation-quiz">듣고쓰기</MyBreadcrumb.Item> */}
        </MyBreadcrumb>
      </ChapterBase.Header>
      <Routes>
        <Route path="/" element={<Navigate to="word" replace={true} />} />
        <Route
          path="word"
          element={
            <WordsByQuizInput
              onEndTo="../word-quiz"
              quizInput={{
                tags: [`skusku-1-2`],
              }}
            />
          }
        />
        <Route
          path="word-quiz"
          element={
            <QuizzesByQuizInput
              onEndTo={`../main-conversation`}
              quizInput={{
                tags: [`skusku-1-2`],
              }}
            />
          }
        />

        <Route path="main-conversation" element={<MainConv2 />} />

        <Route path="learn" element={<Learn />} />

        <Route path="find-dialogue-picture" element={null} />
        <Route
          path="fill-blank"
          element={<PDQuizzes onEndTo="../words-job" />}
        />
        <Route
          path="words-job"
          element={<JobWords onEndTo="../words-job-quiz" />}
        />
        <Route
          path="words-job-quiz"
          element={<JobQuizzes onEndTo="../words-country" />}
        />
        <Route
          path="words-country"
          element={<CountryWords onEndTo="../words-country-quiz" />}
        />
        <Route
          path="words-country-quiz"
          element={<CountryQuizzes onEndTo="../../chapter-3" />}
        />
      </Routes>
    </ChapterBase>
  );
};

export default Chapter2;
