import { useLocation } from "react-router-dom";

const withRemountByLocation =
  <T,>(Comp: React.ComponentType<T>) =>
  (props: T) => {
    const location = useLocation();
    return <Comp {...(props as T)} key={location.key} />;
  };

export default withRemountByLocation;
