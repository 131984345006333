import Dialogue from "comps/Dialogue";

export interface TPhrase {
  nihongo: string; // ruby text
  trans: string;
}

const Phrase: React.FC<TPhrase> = ({ nihongo, trans }) => {
  return <Dialogue.Phrase trans={trans}>{nihongo}</Dialogue.Phrase>;
};

export default Phrase;
