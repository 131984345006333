import { useMemo } from "react";
import BaseVoiceController from "utils/voices/BaseVoiceCtroller";
import VoiceController from "utils/voices/VoiceController";

const useVoice = (voice?: string): BaseVoiceController | undefined => {
  return useMemo(() => {
    if (!voice) return undefined;
    return new VoiceController(voice); // TODO: 이거 voiceController 쓸지 다른거 쓸지 고민
  }, [voice]);
};

export default useVoice;
