import { genDefaultChapterProps } from "../utils";
import Ch9MainConv from "./steps/MainConv";

const ch9 = genDefaultChapterProps(9);

ch9.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch9MainConv />,
});

export default ch9;
