import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv2: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch2/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch2/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch2/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさん、",
                      trans: "김 씨,",
                    },
                    {
                      nihongo: "こんにちは。",
                      trans: "안녕하세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch2/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "こんにちは。",
                      trans: "안녕하세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch2/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんの",
                      trans: "김 씨의",
                    },
                    {
                      nihongo: "お{仕|し}{事|ごと}は",
                      trans: "직업은",
                    },
                    {
                      nihongo: "{何|なん}ですか。",
                      trans: "무엇입니까.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch2/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{会|かい}{社|しゃ}{員|いん}です。",
                      trans: "회사원입니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch2/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんも",
                      trans: "야마다 씨도",
                    },
                    {
                      nihongo: "{会|かい}{社|しゃ}{員|いん}ですか。",
                      trans: "회사원 이세요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch2/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "{会|かい}{社|しゃ}{員|いん}じゃありません。",
                      trans: "회사원이 아닙니다.",
                    },
                    {
                      nihongo: "{大|だい}{学|がく}{生|せい}です。",
                      trans: "대학생입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv2;
