import { rubyTextToHiragana } from "utils";
import AudioController from "./AudioController";
import BaseVoiceController from "./BaseVoiceCtroller";
import tts from "./tts";

let voiceSingleton: BaseVoiceController | null = null;

/**
 * @description at least one source should be exist
 */
export type VoiceControllerConstructorOptions =
  | {
      voiceUrl?: string;
      alterText?: string; //ruby format
    }
  | string;

class VoiceController implements BaseVoiceController {
  audioCtrl?: AudioController;
  alterText: string = "";

  constructor(op: VoiceControllerConstructorOptions) {
    if (typeof op === "string") {
      return new VoiceController({
        voiceUrl: op,
      });
    }

    if (!op.voiceUrl && !op.alterText) {
      throw new Error("source not found");
    }

    if (op.voiceUrl) {
      this.audioCtrl = new AudioController(op.voiceUrl);
    }
    this.alterText = rubyTextToHiragana(op.alterText || "");
  }

  play() {
    voiceSingleton?.stop();
    voiceSingleton = this;
    if (this.audioCtrl) {
      this.audioCtrl.play();
    } else {
      tts.speak(this.alterText);
    }
  }

  stop() {
    if (this.audioCtrl) {
      this.audioCtrl.stop();
    } else {
      tts.cancelAllSpeak();
    }
  }
}

export default VoiceController;
