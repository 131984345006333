import { strToRuby } from "utils";

interface Props {
  children?: string;
}

const RubyText: React.FC<Props> = ({ children = "" }) => {
  return <div>{strToRuby(children)}</div>;
};

export default RubyText;
