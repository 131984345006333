import styled from "@emotion/styled";
import Dialogue from "comps/Dialogue";
import BorderBluredImg from "comps/imgs/BorderBlurredImg";
import useAutoPlayOnMount from "hooks/useAutoPlayOnMount";
import useDialogueVoiceController from "hooks/useDialogueVoiceController";
import { Container, Row } from "react-bootstrap";
import TSentence from "types/Sentence";

const Title = styled.div`
  line-height: 1em;
  font-size: 5rem;
  font-family: "Dongle", "Noto Sans KR", "Noto Sans JP", "Roboto", sans-serif;
`;

const Img = styled(BorderBluredImg)`
  width: 60%; // TODO: 크기 responsive 로 될수 있도록
  height: 400px; // TODO: 크기 responsive 로 될수 있도록
`;

export interface TConv {
  head: string;
  imgUrl: string;
  fullVoiceUrl?: string;
  sentences: TSentence[];
}

interface Props extends TConv {
  total: number;
  order: number;
}

const Conv: React.FC<Props> = ({
  head,
  imgUrl,
  sentences,
  total,
  order,
  fullVoiceUrl,
}) => {
  const voiceCtrl = useDialogueVoiceController({
    full: {
      voiceUrl: fullVoiceUrl,
      alterText: sentences.map((s) => s.nihongo).join(),
    },
    sentences: sentences.map((s) => ({
      voiceUrl: s.voiceUrl,
      alterText: s.nihongo,
    })),
  });

  useAutoPlayOnMount(voiceCtrl);

  return (
    <Container>
      <Row>
        <Title>
          {head}({order}/{total})
        </Title>
      </Row>
      <Row>
        <Img
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      </Row>
      <Dialogue>
        {sentences.map((s, idx) => {
          return (
            <Dialogue.P key={idx}>
              <Dialogue.Speecher>
                <Dialogue.Sentence>{s.speaker}</Dialogue.Sentence>
              </Dialogue.Speecher>
              <Dialogue.Speech>
                <Dialogue.Row>
                  <Dialogue.SentenceSpeechButton
                    onClick={() => {
                      voiceCtrl.playSentence(idx);
                    }}
                  />
                  <Dialogue.Sentence trans={s.trans}>
                    {s.nihongo}
                  </Dialogue.Sentence>
                </Dialogue.Row>
              </Dialogue.Speech>
            </Dialogue.P>
          );
        })}
      </Dialogue>
    </Container>
  );
};

export default Conv;
