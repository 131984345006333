import styled from "@emotion/styled";
import cn from "classnames";
import { HTMLAttributes } from "react";

const H6 = styled.h6`
  margin: 0;
  font-weight: bold;
  padding: 0 1em;
`;

const ChapterHeading: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <H6 className={cn("chapter-heading", className)} {...props}>
      {children}
    </H6>
  );
};

export default ChapterHeading;
