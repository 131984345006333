import { genDefaultChapterProps } from "../utils";
import Ch4MainConv from "./steps/MainConv";

const ch4 = genDefaultChapterProps(4);

ch4.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch4MainConv />,
});

export default ch4;
