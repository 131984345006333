import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv22: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch22/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch22/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "{先|せん}{週|しゅう}{末|まつ}は",
                      trans: "지난 주말에는",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "しましたか。",
                      trans: "했습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{久|ひさ}しぶりに",
                      trans: "오랜만에",
                    },
                    {
                      nihongo: "{日|に}{本|ほん}に",
                      trans: "일본에",
                    },
                    {
                      nihongo: "{帰|かえ}りました。",
                      trans: "갔습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{楽|たの}しかったです。",
                      trans: "즐거웠습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "うらやましいですね。",
                      trans: "부럽네요.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{日|に}{本|ほん}に",
                      trans: "일본에",
                    },
                    {
                      nihongo: "{行|い}って",
                      trans: "가서",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "しましたか。",
                      trans: "했습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{夏|なつ}ですから、",
                      trans: "여름이니까,",
                    },
                    {
                      nihongo: "{花|はな}{火|び}を",
                      trans: "불꽃놀이를",
                    },
                    {
                      nihongo: "{見|み}たり、",
                      trans: "보거나",
                    },
                    {
                      nihongo: "ゆかたを",
                      trans: "유카타를",
                    },
                    {
                      nihongo: "{着|き}て",
                      trans: "입고",
                    },
                    {
                      nihongo: "まつりに",
                      trans: "축제(마쯔리)에",
                    },
                    {
                      nihongo: "{行|い}ったりしました。",
                      trans: "가거나 했습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんは",
                      trans: "김 씨는",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "しましたか。",
                      trans: "했습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}は",
                      trans: "저는",
                    },
                    {
                      nihongo: "うちで",
                      trans: "집에서",
                    },
                    {
                      nihongo: "ごろごろしたり、",
                      trans: "빈둥빈둥거리거나,",
                    },
                    {
                      nihongo: "テレビを",
                      trans: "텔레비전을",
                    },
                    {
                      nihongo: "{見|み}たりしました。",
                      trans: "보거나 했습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ずっと、",
                      trans: "계속,",
                    },
                    {
                      nihongo: "うちに",
                      trans: "집에",
                    },
                    {
                      nihongo: "いましたか。",
                      trans: "있었습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch22/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{大|たい}{変|へん}な",
                      trans: "힘든",
                    },
                    {
                      nihongo: "{仕|し}{事|ごと}が",
                      trans: "일이",
                    },
                    {
                      nihongo: "{終|お}わったばかりですから、",
                      trans: "막 끝났기 때문에,",
                    },
                    {
                      nihongo: "ずっと",
                      trans: "계속",
                    },
                    {
                      nihongo: "うちで",
                      trans: "집에서",
                    },
                    {
                      nihongo: "{休|やす}みました。",
                      trans: "쉬었습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv22;
