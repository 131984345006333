/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query GetQuizzes($input: QuizzesQueryInput, $offset: Int, $limit: Int) {\n    quizzes(input: $input, offset: $offset, limit: $limit) {\n      totalCount,\n      quizzes {\n        id\n        tags\n        level\n        question\n        hiragana\n        examples\n        answer\n        answers\n        imgUrl\n      }\n    }\n  }\n": types.GetQuizzesDocument,
    "\n  query GetQuizHits($quiz_ids: [String!]!) {\n    quizHits(quiz_ids: $quiz_ids) {\n      quiz_id\n      total\n      correct\n      wrong\n    }\n  }\n": types.GetQuizHitsDocument,
    "\n  mutation signUp($id: String!, $password: String!) {\n    signUp(id: $id, password: $password) {\n      token\n    }\n  }\n": types.SignUpDocument,
    "\n  mutation signIn($id: String!, $password: String!) {\n    signIn(id: $id, password: $password) {\n      token\n    }\n  }\n": types.SignInDocument,
    "\n  mutation signInGoogle($googleToken: String!) {\n    signInGoogle(googleToken: $googleToken) {\n      token\n    }\n  }\n": types.SignInGoogleDocument,
    "\n  query GetProfile {\n    profile {\n      user_id\n    }\n  }\n": types.GetProfileDocument,
    "\n  query GetQuizHit($quiz_id: String!) {\n    quizHit(quiz_id: $quiz_id) {\n      total\n      correct\n      wrong\n    }\n  }\n": types.GetQuizHitDocument,
    "\n  mutation quizHitCorrect($quiz_id: String!) {\n    quizHitCorrect(quiz_id: $quiz_id) {\n      id\n    }\n  }\n": types.QuizHitCorrectDocument,
    "\n  mutation quizHitWrong($quiz_id: String!) {\n    quizHitWrong(quiz_id: $quiz_id) {\n      id\n    }\n  }\n": types.QuizHitWrongDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuizzes($input: QuizzesQueryInput, $offset: Int, $limit: Int) {\n    quizzes(input: $input, offset: $offset, limit: $limit) {\n      totalCount,\n      quizzes {\n        id\n        tags\n        level\n        question\n        hiragana\n        examples\n        answer\n        answers\n        imgUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetQuizzes($input: QuizzesQueryInput, $offset: Int, $limit: Int) {\n    quizzes(input: $input, offset: $offset, limit: $limit) {\n      totalCount,\n      quizzes {\n        id\n        tags\n        level\n        question\n        hiragana\n        examples\n        answer\n        answers\n        imgUrl\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuizHits($quiz_ids: [String!]!) {\n    quizHits(quiz_ids: $quiz_ids) {\n      quiz_id\n      total\n      correct\n      wrong\n    }\n  }\n"): (typeof documents)["\n  query GetQuizHits($quiz_ids: [String!]!) {\n    quizHits(quiz_ids: $quiz_ids) {\n      quiz_id\n      total\n      correct\n      wrong\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation signUp($id: String!, $password: String!) {\n    signUp(id: $id, password: $password) {\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation signUp($id: String!, $password: String!) {\n    signUp(id: $id, password: $password) {\n      token\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation signIn($id: String!, $password: String!) {\n    signIn(id: $id, password: $password) {\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation signIn($id: String!, $password: String!) {\n    signIn(id: $id, password: $password) {\n      token\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation signInGoogle($googleToken: String!) {\n    signInGoogle(googleToken: $googleToken) {\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation signInGoogle($googleToken: String!) {\n    signInGoogle(googleToken: $googleToken) {\n      token\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProfile {\n    profile {\n      user_id\n    }\n  }\n"): (typeof documents)["\n  query GetProfile {\n    profile {\n      user_id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetQuizHit($quiz_id: String!) {\n    quizHit(quiz_id: $quiz_id) {\n      total\n      correct\n      wrong\n    }\n  }\n"): (typeof documents)["\n  query GetQuizHit($quiz_id: String!) {\n    quizHit(quiz_id: $quiz_id) {\n      total\n      correct\n      wrong\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation quizHitCorrect($quiz_id: String!) {\n    quizHitCorrect(quiz_id: $quiz_id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation quizHitCorrect($quiz_id: String!) {\n    quizHitCorrect(quiz_id: $quiz_id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation quizHitWrong($quiz_id: String!) {\n    quizHitWrong(quiz_id: $quiz_id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation quizHitWrong($quiz_id: String!) {\n    quizHitWrong(quiz_id: $quiz_id) {\n      id\n    }\n  }\n"];

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function gql(source: string): unknown;

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;