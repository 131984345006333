import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv17: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch17/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch17/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{昨日|きのう}は",
                      trans: "어제는",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "しましたか。",
                      trans: "했습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{友|とも}だちと",
                      trans: "친구와",
                    },
                    {
                      nihongo: "{晩|ばん}ごはんを",
                      trans: "저녁을",
                    },
                    {
                      nihongo: "{食|た}べながら、",
                      trans: "먹으면서,",
                    },
                    {
                      nihongo: "おしゃべりを",
                      trans: "수다를",
                    },
                    {
                      nihongo: "しました。",
                      trans: "떨었습니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{楽|たの}しかったですよ。",
                      trans: "즐거웠습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいですね。",
                      trans: "좋네요.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}も",
                      trans: "저도",
                    },
                    {
                      nihongo: "おいしいものが",
                      trans: "맛있는 것이",
                    },
                    {
                      nihongo: "{食|た}べたいです。",
                      trans: "먹고 싶습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "じゃあ、",
                      trans: "그러면,",
                    },
                    {
                      nihongo: "{今日|きょう}",
                      trans: "오늘",
                    },
                    {
                      nihongo: "{一|いっ}{緒|しょ}に",
                      trans: "함께",
                    },
                    {
                      nihongo: "{行|い}きませんか。",
                      trans: "가지 않을래요?",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いい{店|みせ}が",
                      trans: "좋은 가게가",
                    },
                    {
                      nihongo: "ありますから。",
                      trans: "있으니까요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{本|ほん}{当|とう}に",
                      trans: "정말로",
                    },
                    {
                      nihongo: "{行|い}きたいですが、",
                      trans: "가고 싶습니다만,",
                    },
                    {
                      nihongo: "{今日|きょう}も",
                      trans: "오늘도",
                    },
                    {
                      nihongo: "{仕|し}{事|ごと}が",
                      trans: "일이",
                    },
                    {
                      nihongo: "{多|おお}くて…。",
                      trans: "많아서...",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{今日|きょう}は",
                      trans: "오늘은",
                    },
                    {
                      nihongo: "{土|ど}{曜|よう}{日|び}ですよ。",
                      trans: "토요일이에요.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{今日|きょう}も",
                      trans: "오늘도",
                    },
                    {
                      nihongo: "{会|かい}{社|しゃ}に",
                      trans: "회사에",
                    },
                    {
                      nihongo: "{行|い}きますか。",
                      trans: "갑니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{最|さい}{近|きん}",
                      trans: "요즘",
                    },
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{忙|いそが}しくて、",
                      trans: "바빠서,",
                    },
                    {
                      nihongo: "{土|ど}{曜|よう}{日|び}も",
                      trans: "토요일에도",
                    },
                    {
                      nihongo: "{仕|し}{事|ごと}が",
                      trans: "일이",
                    },
                    {
                      nihongo: "あります。",
                      trans: "있습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch17/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}も",
                      trans: "저도",
                    },
                    {
                      nihongo: "{休|やすみ}が",
                      trans: "휴가를",
                    },
                    {
                      nihongo: "ほしいです。",
                      trans: "갖고 싶습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv17;
