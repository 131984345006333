import learnItems from "comps/learns";
import informs from "comps/informs";

const Lecture1: React.FC = () => {
  return (
    <learnItems.Container>
      <learnItems.Title index={1} title="인칭대명사" description="~씨" />
      <informs.Tip>
        상대방의 이름을 모를 때는 [あなた]를 사용하는 경우도 있지만,
        손윗사람에게 사용하면 실례가 되므로 주의한다.
      </informs.Tip>
    </learnItems.Container>
  );
};

export default Lecture1;
