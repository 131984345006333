import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv5: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch5/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch5/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "{学|がっ}{校|こう}は",
                      trans: "학교는",
                    },
                    {
                      nihongo: "うち",
                      trans: "집",
                    },
                    {
                      nihongo: "から",
                      trans: "에서",
                    },
                    {
                      nihongo: "{遠|とお}いですか。",
                      trans: "멉니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "{遠|とお}く",
                      trans: "멀지",
                    },
                    {
                      nihongo: "ありません。",
                      trans: "않습니다.",
                    },
                    {
                      nihongo: "{近|ちか}いです。",
                      trans: "가깝습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{韓|かん}{国|こく}{人|じん}の",
                      trans: "한국인",
                    },
                    {
                      nihongo: "{友|とも}だちが",
                      trans: "친구가",
                    },
                    {
                      nihongo: "{多|おお}いですか。",
                      trans: "많습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{多|おお}いです。",
                      trans: "많습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{韓|かん}{国|こく}{語|ご}の",
                      trans: "한국어",
                    },
                    {
                      nihongo: "{勉|べん}{強|きょう}は",
                      trans: "공부는",
                    },
                    {
                      nihongo: "{難|むずか}しく}",
                      trans: "어렵지",
                    },
                    {
                      nihongo: "ありませんか。",
                      trans: "않습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですね。",
                      trans: "그렇네요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch5/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{少|すこ}し",
                      trans: "조금",
                    },
                    {
                      nihongo: "{難|むずか}しい}",
                      trans: "어렵",
                    },
                    {
                      nihongo: "ですが、",
                      trans: "지만,",
                    },
                    {
                      nihongo: "おもしろいです。",
                      trans: "재미있습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv5;
