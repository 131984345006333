const synth = window.speechSynthesis;

const speak = (txt: string) => {
  const utter = new SpeechSynthesisUtterance(txt);
  utter.lang = "ja-JP";
  synth.speak(utter);
};

const cancelAllSpeak = () => {
  synth.cancel();
};

/**
 * @description internal use only
 */
const tts = {
  speak,
  cancelAllSpeak,
};

export default tts;
