import { useLifecycles } from "react-use";
import BaseVoiceController from "utils/voices/BaseVoiceCtroller";

const useAutoPlayOnMount = (voiceCtrl?: BaseVoiceController) => {
  useLifecycles(
    () => {
      voiceCtrl?.play();
    },
    () => {
      voiceCtrl?.stop();
    }
  );
};

export default useAutoPlayOnMount;
