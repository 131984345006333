import { genDefaultChapterProps } from "../utils";
import MainConv14 from "./steps/MainConv";

const ch14 = genDefaultChapterProps(14);

ch14.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv14 />,
});

export default ch14;
