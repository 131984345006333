import BasicQuizzes from "comps/_quizzes/BasicQuizzes";
import DictationQuiz, { DQuiz } from "comps/_quizzes/DictationQuiz";
import StepProps from "types/StepProps";
import getSkuskuQuizId from "utils/getSkuskuQuizId";
import voice1 from "assets/learn/ch1/dictation-2/1.mp3";
import voice2 from "assets/learn/ch1/dictation-2/2.mp3";
import voice3 from "assets/learn/ch1/dictation-2/3.mp3";
import voice4 from "assets/learn/ch1/dictation-2/4.mp3";

const genQuizId = (extra: string) => {
  return getSkuskuQuizId({
    chapter: 1,
    isWorkBook: true,
    pageNumber: 45,
    extra,
  });
};

const quizzes: DQuiz[] = [
  {
    id: genQuizId("dquiz-1"),
    voice: voice1,
    answer: "おはようございます。",
    answers: [],
  },
  {
    id: genQuizId("dquiz-2"),
    voice: voice2,
    answer: "いってきます。",
    answers: [],
  },
  {
    id: genQuizId("dquiz-3"),
    voice: voice3,
    answer: "こんにちは。",
    answers: [],
  },
  {
    id: genQuizId("dquiz-4"),
    voice: voice4,
    answer: "ごちそうさまでした。",
    answers: [],
  },
];

const DictationQuizzes2: React.FC<StepProps> = ({ onEnd }) => {
  return (
    <BasicQuizzes quizzes={quizzes} QuizComp={DictationQuiz} onEnd={onEnd} />
  );
};

export default DictationQuizzes2;
