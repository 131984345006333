import { genDefaultChapterProps } from "../utils";
import MainConv16 from "./steps/MainConv";

const ch16 = genDefaultChapterProps(16);

ch16.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv16 />,
});

export default ch16;
