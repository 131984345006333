import Quizzes, { QuizzesProps } from "comps/Quizzes";
import NoQuiz from "comps/Quizzes/_comps/NoQuiz";
import QuizAllDone from "comps/Quizzes/_comps/QuizAllDone";
import withQuizHits from "hocs/withQuizHits";
import withQuizInputFromQuery from "hocs/withQuizInputFromQuery";
import withQuizzes from "hocs/withQuizzes";
import withRemountByLocation from "hocs/withRemountByLocation";
import { useState } from "react";
import { Container } from "react-bootstrap";

interface Props extends Omit<QuizzesProps, "onEnd"> {}

const StartQuiz: React.FC<Props> = (props) => {
  const [isEnd, setIsEnd] = useState(false);

  if (props.quizzes.length === 0) {
    return (
      <Container>
        <NoQuiz />
      </Container>
    );
  }

  if (isEnd) {
    return (
      <Container>
        <QuizAllDone />
      </Container>
    );
  }

  return (
    <Quizzes
      onEnd={() => {
        setIsEnd(true);
      }}
      {...props}
    />
  );
};

export default withRemountByLocation(
  withQuizInputFromQuery(withQuizzes(withQuizHits(StartQuiz)))
);
