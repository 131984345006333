import { useEffect } from "react";

interface Options {
  onPrev: VoidFunction;
  onNext: VoidFunction;
}

const useGlobalKeyNav = ({ onNext, onPrev }: Options) => {
  useEffect(() => {
    const handler: EventListenerOrEventListenerObject = (_event) => {
      const e = _event as KeyboardEvent;

      if (e.code === "ArrowLeft") {
        onPrev();
      } else if (
        e.code === "ArrowRight" ||
        e.code === "Space" ||
        e.code === "Enter"
      ) {
        onNext();
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [onPrev, onNext]);
};

export default useGlobalKeyNav;
