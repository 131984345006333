import { useQuery } from "@apollo/client";
import Words from "comps/Words";
import { TWord } from "comps/Words/Word";
import { GET_QUIZZES } from "gqls";
import StepProps from "types/StepProps";

interface Props extends StepProps {}

const BasicWords: React.FC<Props> = ({ onEnd }) => {
  const { loading, error, data } = useQuery(GET_QUIZZES, {
    variables: {
      input: {
        tags: ["스쿠스쿠일본어", "ch1", "기본단어"],
      },
    },
  });

  if (loading || error || !data) return null; // TODO: 나중에 각 상황에 맞는 적절한 동작 수행할 것

  const {
    quizzes: { quizzes },
  } = data;

  const words: TWord[] = quizzes.map((quiz) => ({
    nihongo: quiz.question,
    trans: quiz.answer,
  }));

  return <Words words={words} onEnd={onEnd} />;
};

export default BasicWords;
