import styled from "@emotion/styled";

const ChapterHeader = styled.header`
  display: flex;
  align-items: center;
  margin: 0.3rem 0;

  > * {
    flex-grow: 1;
    flex-shrink: 1;
  }

  > .chapter-heading {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export default ChapterHeader;
