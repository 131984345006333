import styled from "@emotion/styled";
import { PropsWithChildren } from "react";
import { Accordion } from "react-bootstrap";

const MyAccordion = styled(Accordion)`
  .accordion-header {
    .accordion-button {
      padding: 0.5rem 1rem;
    }
  }
  .accordion-body {
    padding: 0;
  }
`;

interface Props {
  menu: string;
}

const ExpandableMenu: React.FC<PropsWithChildren<Props>> = ({
  children,
  menu,
}) => {
  return (
    <MyAccordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{menu}</Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </MyAccordion>
  );
};

export default ExpandableMenu;
