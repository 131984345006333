import MyProgress from "comps/MyProgress";
import useListIdxMethods from "hooks/useListIdxMethods";
import useSortQuizByHits from "hooks/useSortQuizByHits";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { GetQuizHitsQuery, GetQuizzesQuery } from "__generated__/graphql";
import Quiz from "./Quiz";
import HitRecord from "./_comps/HitRecord";
import NoQuiz from "./_comps/NoQuiz";

export interface QuizzesProps {
  onEnd?: VoidFunction;
  quizzes: GetQuizzesQuery["quizzes"]["quizzes"];
  quizHits?: GetQuizHitsQuery["quizHits"];
}

const Quizzes: React.FC<QuizzesProps> = ({
  quizzes: unsortedQuizzes,
  quizHits,
  onEnd,
}) => {
  const [reverse, setReverse] = useState(false);
  const quizzes = useSortQuizByHits({
    unsortedQuizzes,
    quizHits,
  });
  const total = quizzes.length;

  const { index, increaseIndex } = useListIdxMethods({ total, onEnd });
  const curQuiz = quizzes[index];

  if (total === 0) {
    return <NoQuiz />;
  }

  return (
    <Container>
      <Row>
        <Form>
          <Form.Check
            type="switch"
            id="reverse-switch"
            label="거꾸로 맞추기"
            onChange={(e) => {
              setReverse(e.currentTarget.checked);
            }}
          />
        </Form>
      </Row>
      <Row>
        <Col>
          <MyProgress total={total} done={index} />
        </Col>
        <Col>
          <HitRecord quiz_id={curQuiz.id} />
        </Col>
      </Row>
      <Quiz
        quiz={quizzes[index]}
        onAnswer={() => {
          increaseIndex();
        }}
        reverse={reverse}
      />
    </Container>
  );
};

export default Quizzes;
