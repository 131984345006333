import { useCallback, useState } from "react";

interface Options {
  total: number;
  onEnd?: VoidFunction;
}

const useListIdxMethods = ({ total, onEnd }: Options) => {
  const [index, setIndex] = useState(0);

  const decreaseIndex = useCallback(() => {
    setIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1;
      } else {
        return prevIndex;
      }
    });
  }, []);

  const increaseIndex = useCallback(() => {
    if (index === total - 1) {
      if (onEnd) {
        onEnd();
      }
    } else {
      setIndex((prevIndex) => {
        return prevIndex + 1;
      });
    }
  }, [index, onEnd, total]);

  return {
    index,
    decreaseIndex,
    increaseIndex,
  };
};

export default useListIdxMethods;
