import BasicQuizzes from "comps/_quizzes/BasicQuizzes";
import ToKoreanQuiz, { TKQuiz } from "comps/_quizzes/ToKoreanQuiz";
import StepProps from "types/StepProps";
import getSkuskuQuizId from "utils/getSkuskuQuizId";

const genQuizId = (extra: string) => {
  return getSkuskuQuizId({
    chapter: 1,
    isWorkBook: true,
    pageNumber: 45,
    extra,
  });
};

const quizzes: TKQuiz[] = [
  {
    id: genQuizId("tkquiz-1"),
    nihongo: "いいえ、どういたしまして。",
    answer: "아니오, 천만에요.",
    answers: ["아니요, 천만에요."],
  },
  {
    id: genQuizId("tkquiz-2"),
    nihongo: "はじめまして。どうぞよろしくお{願|ねが}いします。",
    answer: "처음 뵙겠습니다, 아무쪼록 잘 부탁합니다.",
    answers: [],
  },
  {
    id: genQuizId("tkquiz-3"),
    nihongo: "さよ(う)なら。",
    answer: "안녕히 가세요",
    answers: ["안녕히 계세요."],
  },
  {
    id: genQuizId("tkquiz-4"),
    nihongo: "お{元|げん}{気|き}ですか。",
    answer: "잘지내세요?",
    answers: [],
  },
];

const ToKoreanQuizzes: React.FC<StepProps> = ({ onEnd }) => {
  return (
    <BasicQuizzes quizzes={quizzes} QuizComp={ToKoreanQuiz} onEnd={onEnd} />
  );
};

export default ToKoreanQuizzes;
