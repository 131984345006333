import styled from "@emotion/styled";
import MainSpeechButton from "./comps/MainSpeechButton";
import P from "./comps/P";
import Phrase from "./comps/Phrase";
import Row from "./comps/Row";
import Sentence from "./comps/Sentence";
import SentenceSpeechButton from "./comps/SentenceSpeechButton";
import Speech from "./comps/Speech";
import Speecher from "./comps/Speecher";
import Trans from "./comps/Trans";

const Dialogue = styled.div`
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0.1em;
`;

export default Object.assign(Dialogue, {
  Row,
  Speech,
  Speecher,
  Sentence,
  Phrase,
  Trans,
  P,
  MainSpeechButton,
  SentenceSpeechButton,
});
