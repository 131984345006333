import { FormEventHandler, useId } from "react";
import { Form, FormControlProps } from "react-bootstrap";

interface Props {
  onSubmit?: (val: any) => void;
  inputProps?: FormControlProps;
}

const SingleInputForm: React.FC<Props> = ({ onSubmit, inputProps }) => {
  const id = useId();
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const val = formData.get("val");
    e.currentTarget.reset();
    if (onSubmit) {
      onSubmit(val);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId={id}>
        <Form.Control
          type="text"
          size="lg"
          autoFocus
          name="val"
          autoComplete="off"
          {...inputProps}
        />
      </Form.Group>
    </Form>
  );
};

export default SingleInputForm;
