import React from "react";
import { useNavigate } from "react-router-dom";
import StepProps from "types/StepProps";

const toStepComp =
  <T,>(Comp: React.ComponentType<T>) =>
  (props: T & StepProps) => {
    const navigate = useNavigate();
    const navProcess = () => {
      if (props.onEndTo) {
        navigate(props.onEndTo);
      }
    };
    const onEnd = () => {
      if (props.onEnd) {
        props.onEnd();
      } else {
        navProcess();
      }
    };
    return <Comp onEnd={onEnd} {...(props as T)} />;
  };

export default toStepComp;
