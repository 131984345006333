import { genDefaultChapterProps } from "../utils";
import Ch12MainConv from "./steps/MainConv";

const ch12 = genDefaultChapterProps(12);

ch12.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch12MainConv />,
});

export default ch12;
