import Dialogue from "comps/Dialogue";
import useAutoPlayOnMount from "hooks/useAutoPlayOnMount";
import useVoice from "hooks/useVoice";
import { Container, Row } from "react-bootstrap";
import MainImg from "./MainImg";
import Speech, { TSpeech } from "./Speech";

export interface MainConvProps {
  fullVoice?: string;
  speeches: TSpeech[];
  bg?: string;
}

const MainConv: React.FC<MainConvProps> = ({ fullVoice, speeches, bg }) => {
  const voiceCtrl = useVoice(fullVoice);

  useAutoPlayOnMount(voiceCtrl);

  return (
    <Container>
      <Row>
        <Dialogue.MainSpeechButton
          onClick={() => {
            voiceCtrl?.play();
          }}
        />
      </Row>
      <Dialogue>
        {speeches.map((s, i) => {
          return <Speech {...s} key={i} />;
        })}
      </Dialogue>
      {bg && (
        <Row>
          <MainImg url={bg} />
        </Row>
      )}
    </Container>
  );
};

export default MainConv;
