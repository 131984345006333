import { TDOXQuiz } from "comps/_quizzes/DialogueOXQuizzes/DOXQuiz";
import conv5OXVoice from "assets/learn/ch1/OXQuiz/conv5-OX.mp3";
import conv5BG from "assets/learn/ch1/learn/conv5/conv5.jpeg";
import conv6OXVoice from "assets/learn/ch1/OXQuiz/conv6-OX.mp3";
import conv6BG from "assets/learn/ch1/learn/conv6/conv6.jpeg";
import conv8OXVoice from "assets/learn/ch1/OXQuiz/conv8-OX.mp3";
import conv8BG from "assets/learn/ch1/learn/conv8/conv8.jpeg";
import conv9OXVoice from "assets/learn/ch1/OXQuiz/conv9-OX.mp3";
import conv9BG from "assets/learn/ch1/learn/conv9/conv9.jpeg";
import conv10OXVoice from "assets/learn/ch1/OXQuiz/conv10-OX.mp3";
import conv10BG from "assets/learn/ch1/learn/conv10/conv10.jpeg";
import getSkuskuQuizId from "utils/getSkuskuQuizId";
import injectQuizzes from "hocs/injectQuizzes";
import withQuizHits from "hocs/withQuizHits";
import DialogueOXQuizzes from "comps/_quizzes/DialogueOXQuizzes";

const genQuizId = (extra: string) => {
  return getSkuskuQuizId({
    chapter: 1,
    isWorkBook: false,
    pageNumber: 25,
    extra,
  });
};

const quizzes: TDOXQuiz[] = [
  {
    id: genQuizId("OX-conv5"),
    fullVoice: conv5OXVoice,
    imgUrl: conv5BG,
    answer: false,
    answers: [],
    reason:
      "A의 どうも、ありがとうございます。(대단히 감사합니다.) " +
      "에 대한 적절한 응답으로 いいえ、どういたしまして。(아니오, 천만에요.) " +
      "라고 답해야 하는데 いいえ、だいじょうぶです。(아니요, 괜찮습니다.)로 " +
      "답하여 잘못",
  },
  {
    id: genQuizId("OX-conv6"),
    fullVoice: conv6OXVoice,
    imgUrl: conv6BG,
    answer: false,
    answers: [],
    reason:
      "A의 すみません。(죄송합니다.) " +
      "에 대한 적절한 응답으로 いいえ、だいじょうぶです。(아니오, 괜찮습니다.) " +
      "라고 답해야 하는데 いいえ、どういたしまして。(아니오, 천만에요.)로 " +
      "답하여 잘못",
  },
  {
    id: genQuizId("OX-conv8"),
    fullVoice: conv8OXVoice,
    imgUrl: conv8BG,
    answer: true,
    answers: [],
    reason: "옳은표현",
  },
  {
    id: genQuizId("OX-conv9"),
    fullVoice: conv9OXVoice,
    imgUrl: conv9BG,
    answer: true,
    answers: [],
    reason: "옳은표현",
  },
  {
    id: genQuizId("OX-conv10"),
    fullVoice: conv10OXVoice,
    imgUrl: conv10BG,
    answer: true,
    answers: [],
    reason: "옳은표현",
  },
];

export default injectQuizzes(quizzes)(withQuizHits(DialogueOXQuizzes));
