import { genDefaultChapterProps } from "../utils";
import Ch8MainConv from "./steps/MainConv";

const ch8 = genDefaultChapterProps(8);

ch8.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch8MainConv />,
});

export default ch8;
