import { Container } from "react-bootstrap";
import ChapterHeader from "./ChapterHeader";
import ChapterHeading from "./ChapterHeading";

const ChapterBase = Container;

export default Object.assign(ChapterBase, {
  Header: ChapterHeader,
  Heading: ChapterHeading,
});
