import styled from "@emotion/styled";
import Textfit from "@namhong2001/react-textfit";
import useAuth from "hooks/useAuth";
import useQuizHitMethod from "hooks/useQuizHitMethod";
import { FormEventHandler, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { rubyTextToHiragana } from "utils";
import QuizManager from "utils/QuizManager";
import tts from "utils/voices/tts";
import { GetQuizzesQuery } from "__generated__/graphql";

type TQuiz = GetQuizzesQuery["quizzes"]["quizzes"][0];

const Problem = styled.div`
  padding: 1.2em 0;
  font-weight: bold;
  line-height: 1.2;
`;

interface Props {
  quiz: TQuiz;
  onAnswer?: Function;
  reverse?: boolean;
}

const Quiz: React.FC<Props> = ({ quiz, onAnswer, reverse }) => {
  const { isSignedIn } = useAuth();
  const { quizHitCorrect, quizHitWrong } = useQuizHitMethod();
  const quizMan = new QuizManager(quiz, reverse);

  useEffect(() => {
    if (!reverse) {
      tts.speak(rubyTextToHiragana(quiz.question));
    }
    return () => {
      tts.cancelAllSpeak();
    };
  }, [quiz.question, reverse]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const quiz_answer = formData.get("quiz_answer");

    if (quizMan.isAnswerCorrect(quiz_answer)) {
      if (isSignedIn) {
        quizHitCorrect(quiz.id);
      }
    } else {
      alert(quizMan.correctAnswer());
      if (isSignedIn) {
        quizHitWrong(quiz.id);
      }
    }

    e.currentTarget.reset();
    if (onAnswer) onAnswer(e);
  };

  return (
    <Container className="text-center">
      <Problem>
        <Textfit mode="single">{quizMan.question()}</Textfit>
      </Problem>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Form.Group controlId="quiz_answer">
              <Form.Control
                type="text"
                size="lg"
                autoFocus
                name="quiz_answer"
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Quiz;
