import BaseQuiz from "types/quiz/BaseQuiz";

const injectQuizzes =
  <Q extends BaseQuiz>(quizzes: Q[]) =>
  <T,>(Comp: React.ComponentType<T>) =>
  (props: Omit<T, "quizzes">) => {
    return <Comp quizzes={quizzes} {...(props as T)} />;
  };

export default injectQuizzes;
