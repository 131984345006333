import conv1Bg from "assets/learn/ch1/learn/conv1.jpeg";
import conv10Bg from "assets/learn/ch1/learn/conv10/conv10.jpeg";
import conv10Voice from "assets/learn/ch1/learn/conv10/tadaima.mp3";
import conv10VoiceA from "assets/learn/ch1/learn/conv10/tadaima-A.mp3";
import conv10VoiceB from "assets/learn/ch1/learn/conv10/tadaima-B.mp3";
import conv11Bg from "assets/learn/ch1/learn/conv11.jpeg";
import conv12Bg from "assets/learn/ch1/learn/conv12.jpeg";
import conv2Bg from "assets/learn/ch1/learn/conv2/conv2.jpeg";
import conv2Voice from "assets/learn/ch1/learn/conv2/konnichiha.mp3";
import conv2VoiceA from "assets/learn/ch1/learn/conv2/konnichiha-A.mp3";
import conv2VoiceB from "assets/learn/ch1/learn/conv2/konnichiha-B.mp3";
import conv3Bg from "assets/learn/ch1/learn/conv3.jpeg";
import conv4Bg from "assets/learn/ch1/learn/conv4.jpeg";
import conv5Bg from "assets/learn/ch1/learn/conv5/conv5.jpeg";
import conv5Voice from "assets/learn/ch1/learn/conv5/arigatou.mp3";
import conv5VoiceA from "assets/learn/ch1/learn/conv5/arigatou-A.mp3";
import conv5VoiceB from "assets/learn/ch1/learn/conv5/arigatou-B.mp3";
import conv6Bg from "assets/learn/ch1/learn/conv6/conv6.jpeg";
import conv6Voice from "assets/learn/ch1/learn/conv6/sumimasen.mp3";
import conv6VoiceA from "assets/learn/ch1/learn/conv6/sumimasen-A.mp3";
import conv6VoiceB from "assets/learn/ch1/learn/conv6/sumimasen-B.mp3";
import conv7Bg from "assets/learn/ch1/learn/conv7.jpeg";
import conv8Bg from "assets/learn/ch1/learn/conv8/conv8.jpeg";
import conv8Voice from "assets/learn/ch1/learn/conv8/ogenkidesuka.mp3";
import conv8VoiceA from "assets/learn/ch1/learn/conv8/ogenkidesuka-A.mp3";
import conv8VoiceB from "assets/learn/ch1/learn/conv8/ogenkidesuka-B.mp3";
import conv9Bg from "assets/learn/ch1/learn/conv9/conv9.jpeg";
import conv9Voice from "assets/learn/ch1/learn/conv9/ittekimasu.mp3";
import conv9VoiceA from "assets/learn/ch1/learn/conv9/ittekimasu-A.mp3";
import conv9VoiceB from "assets/learn/ch1/learn/conv9/ittekimasu-B.mp3";
import Conv, { TConv } from "comps/SimpleConv";
import useGlobalKeyNav from "hooks/useGlobalKeyNav";
import useListIdxMethods from "hooks/useListIdxMethods";
import StepProps from "types/StepProps";

const convs: TConv[] = [
  {
    head: "아침인사",
    imgUrl: conv1Bg,
    sentences: [
      {
        speaker: "A :",
        nihongo: "おはようございます。",
        trans: "안녕하세요.",
      },
      {
        speaker: "B :",
        nihongo: "おはようございます。",
        trans: "안녕하세요.",
      },
    ],
  },
  {
    head: "점심인사",
    imgUrl: conv2Bg,
    fullVoiceUrl: conv2Voice,
    sentences: [
      {
        speaker: "A :",
        nihongo: "こんにちは。",
        trans: "안녕하세요.",
        voiceUrl: conv2VoiceA,
      },
      {
        speaker: "B :",
        nihongo: "こんにちは。",
        trans: "안녕하세요.",
        voiceUrl: conv2VoiceB,
      },
    ],
  },
  {
    head: "저녁인사",
    imgUrl: conv3Bg,
    sentences: [
      {
        speaker: "A :",
        nihongo: "こんばんは。",
        trans: "안녕하세요.",
      },
      {
        speaker: "B :",
        nihongo: "こんばんは。",
        trans: "안녕하세요.",
      },
    ],
  },
  {
    head: "헤어질때인사",
    imgUrl: conv4Bg,
    sentences: [
      {
        speaker: "A :",
        nihongo: "さよ(う)なら。",
        trans: "안녕히 가세요./안녕히 계세요.",
      },
      {
        speaker: "B :",
        nihongo: "さよ(う)なら。",
        trans: "안녕히 가세요./안녕히 계세요.",
      },
    ],
  },
  {
    head: "감사",
    imgUrl: conv5Bg,
    fullVoiceUrl: conv5Voice,
    sentences: [
      {
        speaker: "A :",
        nihongo: "どうも、ありがとうございます。",
        trans: "대단히 감사합니다.",
        voiceUrl: conv5VoiceA,
      },
      {
        speaker: "B :",
        nihongo: "いいえ、どういたしまして。",
        trans: "아니오, 천만에요.",
        voiceUrl: conv5VoiceB,
      },
    ],
  },
  {
    head: "사과",
    imgUrl: conv6Bg,
    fullVoiceUrl: conv6Voice,
    sentences: [
      {
        speaker: "A :",
        nihongo: "すみません。",
        trans: "죄송합니다.",
        voiceUrl: conv6VoiceA,
      },
      {
        speaker: "B :",
        nihongo: "いいえ、だいじょうぶです。",
        trans: "아니오, 괜찮습니다.",
        voiceUrl: conv6VoiceB,
      },
    ],
  },
  {
    head: "소개",
    imgUrl: conv7Bg,
    sentences: [
      {
        speaker: "A :",
        nihongo: "はじめまして。どうぞよろしくお{願|ねが}いします。",
        trans: "처음 뵙겠습니다. 아무쪼록 잘 부탁합니다.",
      },
      {
        speaker: "B :",
        nihongo: "こちらこそ、どうぞよろしくお{願|ねが}いします。",
        trans: "저야말로 잘 부탁합니다.",
      },
    ],
  },
  {
    head: "안부",
    imgUrl: conv8Bg,
    fullVoiceUrl: conv8Voice,
    sentences: [
      {
        speaker: "A :",
        nihongo: "お{元|げん}{気|き}ですか。",
        trans: "잘 지내세요?",
        voiceUrl: conv8VoiceA,
      },
      {
        speaker: "B :",
        nihongo: "はい、おかげさまで。",
        trans: "예, 덕분에요.",
        voiceUrl: conv8VoiceB,
      },
    ],
  },
  {
    head: "외출",
    imgUrl: conv9Bg,
    fullVoiceUrl: conv9Voice,
    sentences: [
      {
        speaker: "A :",
        nihongo: "いってきます。",
        trans: "다녀오겠습니다.",
        voiceUrl: conv9VoiceA,
      },
      {
        speaker: "B :",
        nihongo: "いってらっしゃい。",
        trans: "잘 다녀오세요.",
        voiceUrl: conv9VoiceB,
      },
    ],
  },
  {
    head: "귀가",
    imgUrl: conv10Bg,
    fullVoiceUrl: conv10Voice,
    sentences: [
      {
        speaker: "A :",
        nihongo: "ただいま。",
        trans: "다녀왔습니다.",
        voiceUrl: conv10VoiceA,
      },
      {
        speaker: "B :",
        nihongo: "お{帰|かえ}りなさい。",
        trans: "어서 돌아오세요.",
        voiceUrl: conv10VoiceB,
      },
    ],
  },
  {
    head: "권유",
    imgUrl: conv11Bg,
    sentences: [
      {
        speaker: "A :",
        nihongo: "どうぞ。",
        trans: "어서 들어가세요.",
      },
      {
        speaker: "B :",
        nihongo: "ありがとうございます。",
        trans: "감사합니다.",
      },
    ],
  },
  {
    head: "식사",
    imgUrl: conv12Bg,
    sentences: [
      {
        speaker: "A :",
        nihongo: "いただきます。",
        trans: "잘 먹겠습니다.",
      },
      {
        speaker: "B :",
        nihongo: "ごちそうさまでした。",
        trans: "잘 먹었습니다.",
      },
    ],
  },
];

interface Props extends StepProps {}

const Learn: React.FC<Props> = ({ onEnd }) => {
  const total = convs.length;
  const { index, decreaseIndex, increaseIndex } = useListIdxMethods({
    total,
    onEnd,
  });

  useGlobalKeyNav({
    onPrev: decreaseIndex,
    onNext: increaseIndex,
  });

  return <Conv {...convs[index]} total={total} order={index + 1} />;
};

export default Learn;
