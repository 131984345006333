import useAuth from "hooks/useAuth";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SignUp: React.FC = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  return (
    <Container>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const login_id = formData.get("login_id");
          const login_password = formData.get("login_password");

          if (!login_id || !login_password) return;

          try {
            await signUp(login_id as string, login_password as string);
            navigate("/");
          } catch (err) {
            if (err instanceof Error) {
              alert(err.message);
            }
          }
        }}
      >
        <Form.Group className="mb-3" controlId="login_id">
          <Form.Label>Id</Form.Label>
          <Form.Control type="text" placeholder="ID" name="login_id" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="login_password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="login_password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          가입하기
        </Button>
      </Form>
    </Container>
  );
};

export default SignUp;
