import DefaultLayout from "layouts/DefaultLayout";
import SignIn from "pages/auths/SignIn";
import SignUp from "pages/auths/SignUp";
import Community from "pages/Community";
import Learn from "pages/Learn";
import Quizzes from "pages/Quizzes";
import StartQuiz from "pages/Quizzes/StartQuiz";
import PrivacyPolicy from "pages/Terms/PrivacyPolicy";
import TermsOfService from "pages/Terms/TermsOfService";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<DefaultLayout />}>
      <Route path="learn/*" element={<Learn />} />
      <Route path="quizzes" element={<Quizzes />}>
        <Route path="start-quiz" element={<StartQuiz />} />
      </Route>
      <Route path="community" element={<Community />} />
      <Route path="terms">
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
      </Route>
      <Route path="auth">
        <Route path="signup" element={<SignUp />} />
        <Route path="signin" element={<SignIn />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
