import { genDefaultChapterProps } from "../utils";
import MainConv17 from "./steps/MainConv";

const ch17 = genDefaultChapterProps(17);

ch17.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv17 />,
});

export default ch17;
