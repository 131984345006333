import styled from "@emotion/styled";
import Sidebar from "comps/sidebar";
import { Outlet } from "react-router-dom";
import SideBarMenus from "./SideBarMenus";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  .sidebar {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;

const Quizzes: React.FC = () => {
  return (
    <Wrapper>
      <Sidebar heading="Quizzes">
        <SideBarMenus />
      </Sidebar>
      <Body>
        <Outlet />
      </Body>
    </Wrapper>
  );
};

export default Quizzes;
