import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Wrapper = styled.div`
  --layout-header-height: 45px;
  min-height: 100vh;

  .layout-header {
    height: var(--layout-header-height);
  }
`;

const Main = styled.main`
  height: calc(100vh - var(--layout-header-height));
`;

const DefaultLayout: React.FC = () => {
  return (
    <Wrapper>
      <Header className="layout-header" />
      <Main>
        <Outlet />
      </Main>
    </Wrapper>
  );
};

export default DefaultLayout;
