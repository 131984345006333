import { gql } from "__generated__";

export const GET_QUIZZES = gql(`
  query GetQuizzes($input: QuizzesQueryInput, $offset: Int, $limit: Int) {
    quizzes(input: $input, offset: $offset, limit: $limit) {
      totalCount,
      quizzes {
        id
        tags
        level
        question
        hiragana
        examples
        answer
        answers
        imgUrl
      }
    }
  }
`);

export const GET_QUIZHITS = gql(`
  query GetQuizHits($quiz_ids: [String!]!) {
    quizHits(quiz_ids: $quiz_ids) {
      quiz_id
      total
      correct
      wrong
    }
  }
`);
