import useQuizHits from "hooks/useQuizHits";
import BaseQuiz from "types/quiz/BaseQuiz";
import QuizHitsProps from "types/quiz/QuizHitsProps";

const withQuizHits =
  <
    Q extends BaseQuiz,
    T extends {
      quizzes: Q[];
    }
  >(
    Comp: React.ComponentType<T>
  ) =>
  (props: Omit<T, keyof QuizHitsProps>) => {
    const { quizzes } = props;

    const { loading, error, quizHits } = useQuizHits(quizzes);

    if (loading) return null;

    if (error) {
      return <Comp {...(props as T)} />;
    }

    return <Comp quizHits={quizHits} {...(props as T)} />;
  };

export default withQuizHits;
