import { useMemo } from "react";
import DialogueVoiceController, {
  DialogueVoiceControllerConstructorOptions,
} from "utils/voices/DialogueVoiceController";

const useDialogueVoiceController = ({
  full,
  sentences,
}: DialogueVoiceControllerConstructorOptions) => {
  return useMemo(() => {
    return new DialogueVoiceController({
      full,
      sentences,
    });
  }, [full, sentences]);
};

export default useDialogueVoiceController;
