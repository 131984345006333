import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv18: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch18/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch18/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさん。",
                      trans: "김 씨.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{今|こん}{週|しゅう}の",
                      trans: "이번 주",
                    },
                    {
                      nihongo: "{土|ど}{曜|よう}{日|び}、",
                      trans: "토요일,",
                    },
                    {
                      nihongo: "{時|じ}{間|かん}が",
                      trans: "시간",
                    },
                    {
                      nihongo: "ありますか。",
                      trans: "있나요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{暇|ひま}ですよ。",
                      trans: "한가합니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "どうしてですか。",
                      trans: "왜 그러신가요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{土|ど}{曜|よう}{日|び}の",
                      trans: "토요일",
                    },
                    {
                      nihongo: "３{時|じ}から、",
                      trans: "3시부터",
                    },
                    {
                      nihongo: "{私|わたし}の",
                      trans: "저희",
                    },
                    {
                      nihongo: "うちで",
                      trans: "집에서",
                    },
                    {
                      nihongo: "{誕|たん}{生|じょう}{日|び}パーティーを",
                      trans: "생일 파티를",
                    },
                    {
                      nihongo: "します。",
                      trans: "합니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "キムさんも",
                      trans: "김 씨도",
                    },
                    {
                      nihongo: "{遊|あそ}びに",
                      trans: "놀러",
                    },
                    {
                      nihongo: "{来|き}てください。",
                      trans: "오세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ありがとうございます。",
                      trans: "감사합니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんの",
                      trans: "야마다 씨의",
                    },
                    {
                      nihongo: "うちに(は)",
                      trans: "집은",
                    },
                    {
                      nihongo: "どうやって",
                      trans: "어떻게",
                    },
                    {
                      nihongo: "{行|い}きますか。",
                      trans: "갑니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{大|だい}{学|がく}の",
                      trans: "대학교",
                    },
                    {
                      nihongo: "そばに",
                      trans: "옆에",
                    },
                    {
                      nihongo: "あります。",
                      trans: "있어요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "２{号|ごう}{線|せん}の",
                      trans: "2호선",
                    },
                    {
                      nihongo: "{地|ち}{下|か}{鉄|てつ}に",
                      trans: "지하철을",
                    },
                    {
                      nihongo: "{乗|の}って、",
                      trans: "타고,",
                    },
                    {
                      nihongo: "３つ{目|め}の",
                      trans: "3번째",
                    },
                    {
                      nihongo: "{駅|えき}で",
                      trans: "역에서",
                    },
                    {
                      nihongo: "{降|お}りてくださいね。",
                      trans: "내리세요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "それから、",
                      trans: "그리고 나서,",
                    },
                    {
                      nihongo: "{駅|えき}で",
                      trans: "역에서",
                    },
                    {
                      nihongo: "{電|でん}{話|わ}",
                      trans: "전화",
                    },
                    {
                      nihongo: "して",
                      trans: "해",
                    },
                    {
                      nihongo: "ください。",
                      trans: "주세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch18/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "わかりました。",
                      trans: "알겠습니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{楽|たの}しみですね。",
                      trans: "기대되네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv18;
