import { isRubyTxt, isStringArray, strToRuby } from "utils";
import Dialogue from "..";

interface Props {
  trans?: string;
  children?: string | string[];
}

const Phrase: React.FC<Props> = ({ children = "", trans = "" }) => {
  if (isStringArray(children)) {
    children = children.join("");
  }
  const isRubyText = isRubyTxt(children);

  if (!isRubyText) {
    children = `{${children}|}`;
  }

  return (
    <div>
      <div>{strToRuby(children)}</div>
      <Dialogue.Trans>{trans || <>&nbsp;</>}</Dialogue.Trans>
    </div>
  );
};

export default Phrase;
