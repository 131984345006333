import { useMutation } from "@apollo/client";
import { gql } from "__generated__";

const UPDATE_QUIZHIT_CORRECT = gql(`
  mutation quizHitCorrect($quiz_id: String!) {
    quizHitCorrect(quiz_id: $quiz_id) {
      id
    }
  }
`);

const UPDATE_QUIZHIT_WRONG = gql(`
  mutation quizHitWrong($quiz_id: String!) {
    quizHitWrong(quiz_id: $quiz_id) {
      id
    }
  }
`);

const useQuizHitMethod = () => {
  const [c] = useMutation(UPDATE_QUIZHIT_CORRECT);
  const [w] = useMutation(UPDATE_QUIZHIT_WRONG);

  const quizHitCorrect = (quiz_id: string) => {
    c({
      variables: {
        quiz_id,
      },
    });
  };

  const quizHitWrong = (quiz_id: string) => {
    w({
      variables: {
        quiz_id,
      },
    });
  };

  return {
    quizHitCorrect,
    quizHitWrong,
  };
};

export default useQuizHitMethod;
