import useListIdxMethods from "hooks/useListIdxMethods";
import useQuizHits from "hooks/useQuizHits";
import useSortQuizByHits from "hooks/useSortQuizByHits";
import { Container } from "react-bootstrap";
import BaseQuiz from "types/quiz/BaseQuiz";
import QuizComp from "types/quiz/QuizComp";
import { GetQuizHitsQuery } from "__generated__/graphql";
import BasicQuizzesProgress from "./BasicQuizzesProgress";

interface Props<Q extends BaseQuiz> {
  onEnd?: VoidFunction;
  quizzes: Q[];
  quizHits?: GetQuizHitsQuery["quizHits"];
  QuizComp: QuizComp<Q>;
}

const BasicQuizzesRaw = <Q extends BaseQuiz>({
  onEnd,
  quizzes: unsortedQuizzes,
  quizHits,
  QuizComp,
}: Props<Q>) => {
  const quizzes = useSortQuizByHits({
    unsortedQuizzes,
    quizHits,
  });

  const total = quizzes.length;
  const { index, increaseIndex } = useListIdxMethods({ total, onEnd });

  const curQuiz = quizzes[index];

  return (
    <Container>
      <BasicQuizzesProgress total={total} done={index} quiz_id={curQuiz.id} />
      <QuizComp quiz={curQuiz} onDone={increaseIndex} />
    </Container>
  );
};

const BasicQuizzes = <Q extends BaseQuiz>(
  props: Omit<Props<Q>, "quizHits">
) => {
  const { quizzes } = props;

  const { loading, error, quizHits } = useQuizHits(quizzes);

  if (loading) return null;

  if (error) {
    return <BasicQuizzesRaw {...props} />;
  }
  return <BasicQuizzesRaw quizHits={quizHits} {...props} />;
};

export default BasicQuizzes;
