import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv16: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch16/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch16/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "{来|らい}{週|しゅう}の",
                      trans: "다음 주",
                    },
                    {
                      nihongo: "{月|げつ}{曜|よう}{日|び}は",
                      trans: "월요일은",
                    },
                    {
                      nihongo: "{田|た}{中|なか}さんの",
                      trans: "다나까 씨의",
                    },
                    {
                      nihongo: "{誕|たん}{生|じょう}{日|び}ですね。",
                      trans: "생일이네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですね。",
                      trans: "그렇네요.",
                    },
                    {
                      nihongo: "プレゼントは",
                      trans: "선물은",
                    },
                    {
                      nihongo: "{買|か}いましたか。",
                      trans: "샀습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "まだです。",
                      trans: "아직입니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "これから",
                      trans: "이제부터",
                    },
                    {
                      nihongo: "{買|か}いに{行|い}きます。",
                      trans: "사러 갈겁니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}も",
                      trans: "저도",
                    },
                    {
                      nihongo: "まだですから、",
                      trans: "아직이니까,",
                    },
                    {
                      nihongo: "{一|いっ}{緒|しょ}に",
                      trans: "함께",
                    },
                    {
                      nihongo: "{買|か}い{物|もの}に",
                      trans: "쇼핑하러",
                    },
                    {
                      nihongo: "{行|い}きませんか。",
                      trans: "가지 않겠습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ええ、",
                      trans: "예,",
                    },
                    {
                      nihongo: "どこで、",
                      trans: "어디에서,",
                    },
                    {
                      nihongo: "{何|なん}{時|じ}に",
                      trans: "몇 시에",
                    },
                    {
                      nihongo: "{会|あ}いましょうか。",
                      trans: "만날까요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{学|がっ}{校|こう}の",
                      trans: "학교",
                    },
                    {
                      nihongo: "{前|まえ}で",
                      trans: "앞에서",
                    },
                    {
                      nihongo: "４{時|じ}は",
                      trans: "4시는",
                    },
                    {
                      nihongo: "どうですか。",
                      trans: "어때요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ええ、",
                      trans: "예,",
                    },
                    {
                      nihongo: "そうしましょう。",
                      trans: "그렇게 합시다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "{買|か}いましょうか。",
                      trans: "살까요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{田|た}{中|なか}さんは",
                      trans: "다나까 씨는",
                    },
                    {
                      nihongo: "{韓|かん}{国|こく}の",
                      trans: "한국",
                    },
                    {
                      nihongo: "ドラマが",
                      trans: "드라마를",
                    },
                    {
                      nihongo: "{好|す}きだから、",
                      trans: "좋아하니까,",
                    },
                    {
                      nihongo: "ドラマの",
                      trans: "드라마",
                    },
                    {
                      nihongo: "DVDは",
                      trans: "DVD는",
                    },
                    {
                      nihongo: "どうですか。",
                      trans: "어떨까요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch16/main-conv/10.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいですね。",
                      trans: "좋네요.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "そうしましょう。",
                      trans: "그렇게 합시다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv16;
