import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv11: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch11/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch11/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "すみません。",
                      trans: "실례합니다.",
                    },
                    {
                      nihongo: "この",
                      trans: "이",
                    },
                    {
                      nihongo: "{近|ちか}くに",
                      trans: "근처에",
                    },
                    {
                      nihongo: "{銀|ぎん}{行|こう}が",
                      trans: "은행이",
                    },
                    {
                      nihongo: "ありますか。",
                      trans: "있습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{通|つう}{行|こう}{人|にん}", trans: "통행인" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{銀|ぎん}{行|こう}ですか。",
                      trans: "은행이요?",
                    },
                    {
                      nihongo: "あ、",
                      trans: "아,",
                    },
                    {
                      nihongo: "{本|ほん}{屋|や}の",
                      trans: "서점",
                    },
                    {
                      nihongo: "２{階|かい}に",
                      trans: "2층에",
                    },
                    {
                      nihongo: "ありますよ。",
                      trans: "있습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{本|ほん}{屋|や}の",
                      trans: "서점",
                    },
                    {
                      nihongo: "２{階|かい}",
                      trans: "2층",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "이요?",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "すみませんが、",
                      trans: "죄송하지만,",
                    },
                    {
                      nihongo: "{本|ほん}{屋|や}は",
                      trans: "서점은",
                    },
                    {
                      nihongo: "どこですか。",
                      trans: "어디예요??",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{通|つう}{行|こう}{人|にん}", trans: "통행인" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "あそこに",
                      trans: "저기에",
                    },
                    {
                      nihongo: "デパートが",
                      trans: "백화점이",
                    },
                    {
                      nihongo: "ありますね。",
                      trans: "있지요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "その",
                      trans: "그",
                    },
                    {
                      nihongo: "{隣|となり}が",
                      trans: "옆이",
                    },
                    {
                      nihongo: "{本|ほん}{屋|や}です。",
                      trans: "서점입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "デパートの",
                      trans: "백화점",
                    },
                    {
                      nihongo: "{隣|となり}が",
                      trans: "옆이",
                    },
                    {
                      nihongo: "{本|ほん}{屋|や}ですね。",
                      trans: "서점이군요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{通|つう}{行|こう}{人|にん}", trans: "통행인" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "その",
                      trans: "그",
                    },
                    {
                      nihongo: "{上|うえ}に",
                      trans: "위에",
                    },
                    {
                      nihongo: "{銀|ぎん}{行|こう}が",
                      trans: "은행이",
                    },
                    {
                      nihongo: "あります。",
                      trans: "있습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch11/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                    {
                      nihongo: "ありがとうございます。",
                      trans: "감사합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv11;
