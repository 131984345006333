import { useMemo } from "react";
import VoiceController, {
  VoiceControllerConstructorOptions,
} from "utils/voices/VoiceController";

const useVoiceController = (op: VoiceControllerConstructorOptions) => {
  return useMemo(() => {
    return new VoiceController(op);
  }, [op]);
};

export default useVoiceController;
