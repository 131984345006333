import Dialogue from "comps/Dialogue";
import useVoice from "hooks/useVoice";
import Sentence, { TSentence } from "./Sentence";

export interface TUtter {
  voice?: string;
  sentences: TSentence[];
}

const Utter: React.FC<TUtter> = ({ voice, sentences }) => {
  const voiceCtrl = useVoice(voice);

  return (
    <Dialogue.Row>
      <Dialogue.SentenceSpeechButton
        onClick={() => {
          voiceCtrl?.play();
        }}
      />
      {sentences.map((s, i) => {
        return <Sentence {...s} key={i} />;
      })}
    </Dialogue.Row>
  );
};

export default Utter;
