import styled from "@emotion/styled";
import cn from "classnames/dedupe";
import {
  HTMLAttributes,
  PropsWithChildren,
  useDeferredValue,
  useState,
} from "react";
import CloseIconButton from "./CloseIconButton";
import HambergerIconButton from "./HambergerIconButton";

const Aside = styled.aside`
  width: 150px;
  border-right: 1px solid lightgray;

  text-align: center;
  overflow: auto;

  &.collapsed {
    width: 50px;
  }

  .header {
    display: flex;
    padding: 0.3rem 0.6rem;
    align-items: center;
    justify-content: space-between;
    .title {
      text-align: start;
      font-weight: bold;
      margin: 0;
    }
  }
`;

interface Props extends HTMLAttributes<HTMLElement> {
  heading?: string;
}

const Sidebar: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  heading,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const deferredCollapsed = useDeferredValue(collapsed);

  return (
    <Aside
      className={cn("sidebar", className, {
        collapsed: deferredCollapsed,
      })}
      {...props}
    >
      {deferredCollapsed ? (
        <HambergerIconButton onClick={() => setCollapsed(false)} />
      ) : (
        <>
          <header className="header">
            <h5 className="title">{heading}</h5>
            <CloseIconButton onClick={() => setCollapsed(true)} />
          </header>
          {children}
        </>
      )}
    </Aside>
  );
};

export default Sidebar;
