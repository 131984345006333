import QuizInputProps from "types/quiz/QuizInputProps";
import { QuizzesQueryInput } from "__generated__/graphql";

const injectQuizInput =
  (quizInput: QuizzesQueryInput) =>
  <T,>(Comp: React.ComponentType<T>) =>
  (props: Omit<T, keyof QuizInputProps>) => {
    return <Comp quizInput={quizInput} {...(props as T)} />;
  };

export default injectQuizInput;
