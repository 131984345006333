import { genDefaultChapterProps } from "../utils";
import MainConv21 from "./steps/MainConv";

const ch21 = genDefaultChapterProps(21);

ch21.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv21 />,
});

export default ch21;
