import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import { gql } from "__generated__";
import useProfile from "./useProfile";

const SIGN_UP = gql(`
  mutation signUp($id: String!, $password: String!) {
    signUp(id: $id, password: $password) {
      token
    }
  }
`);

const SIGN_IN = gql(`
  mutation signIn($id: String!, $password: String!) {
    signIn(id: $id, password: $password) {
      token
    }
  }
`);

const SIGN_IN_GOOGLE = gql(`
  mutation signInGoogle($googleToken: String!) {
    signInGoogle(googleToken: $googleToken) {
      token
    }
  }
`);

const useAuth = () => {
  const client = useApolloClient();
  const profile = useProfile();
  const isSignedIn = Boolean(profile);

  const signUp = async (id: string, pw: string) => {
    const { data, errors } = await client.mutate({
      mutation: SIGN_UP,
      fetchPolicy: "no-cache",
      variables: {
        id,
        password: pw,
      },
    });

    if (errors) {
      console.warn(errors);
      return;
    }

    if (!data) {
      console.warn("no data");
      return;
    }

    const token = data.signUp.token;
    localStorage.setItem("token", token);
    await client.resetStore().catch(_.noop);
  };

  const signIn = async (id: string, pw: string) => {
    const { data, errors } = await client.mutate({
      mutation: SIGN_IN,
      fetchPolicy: "no-cache",
      variables: {
        id,
        password: pw,
      },
    });

    if (errors) {
      console.warn(errors);
      return;
    }

    if (!data) {
      console.warn("no data");
      return;
    }

    const token = data.signIn.token;
    localStorage.setItem("token", token);
    await client.resetStore().catch(_.noop);
  };

  const signInGoogle = async (googleToken: string) => {
    const { data, errors } = await client.mutate({
      mutation: SIGN_IN_GOOGLE,
      fetchPolicy: "no-cache",
      variables: {
        googleToken,
      },
    });

    if (errors) {
      console.warn(errors);
      return;
    }

    if (!data) {
      console.warn("no data");
      return;
    }

    const token = data.signInGoogle.token;
    localStorage.setItem("token", token);
    await client.resetStore().catch(_.noop);
  };

  const signOut = async () => {
    localStorage.removeItem("token");
    await client.resetStore().catch(_.noop);
  };

  return {
    isSignedIn,
    signUp,
    signIn,
    signInGoogle,
    signOut,
  };
};

export default useAuth;
