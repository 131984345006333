import { TWord } from "comps/Words/Word";
import { GetQuizzesQuery } from "__generated__/graphql";

interface QuizzesProps {
  quizzes: GetQuizzesQuery["quizzes"]["quizzes"];
}

const quizzesToWords =
  <T,>(Comp: React.ComponentType<T>) =>
  (props: Omit<T, "words"> & QuizzesProps) => {
    const words: TWord[] = props.quizzes.map((quiz) => ({
      nihongo: quiz.question,
      trans: quiz.answer,
      imgUrl: quiz.imgUrl,
    }));

    return <Comp {...(props as T)} words={words} />;
  };

export default quizzesToWords;
