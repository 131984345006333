import { genDefaultChapterProps } from "../utils";
import Ch5MainConv from "./steps/MainConv";

const ch5 = genDefaultChapterProps(5);

ch5.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch5MainConv />,
});

export default ch5;
