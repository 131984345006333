import { PropsWithChildren } from "react";
import { BreadcrumbItem, BreadcrumbItemProps } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

interface Props extends BreadcrumbItemProps {
  to?: string;
}

const MyBreadcrumbItem: React.FC<PropsWithChildren<Props>> = ({
  to,
  children,
  ...props
}) => {
  const location = useLocation();
  return (
    <BreadcrumbItem
      linkAs={Link}
      linkProps={{
        to,
      }}
      active={to ? location.pathname.endsWith(to) : false}
      {...props}
    >
      {children}
    </BreadcrumbItem>
  );
};

export default MyBreadcrumbItem;
