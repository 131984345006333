import _ from "lodash";

export const isStringArray = (target: any): target is string[] => {
  if (!_.isArray(target)) return false;
  for (const item of target) {
    if (!_.isString(item)) return false;
  }
  return true;
};

export const isRubyTxt = (txt: string) => {
  const tokens = txt
    .split(/[{}]/)
    .filter(Boolean)
    .map((t) => {
      if (t.includes("|")) {
        return t.split("|");
      } else return t;
    });

  return tokens.filter(_.isArray).length > 0;
};

export const strToRuby = (txt: string) => {
  const tokens = txt
    .split(/[{}]/)
    .filter(Boolean)
    .map((t) => {
      if (t.includes("|")) {
        return t.split("|");
      } else return t;
    });

  return (
    <>
      {tokens.map((t, idx) => {
        if (typeof t === "string") {
          return t;
        } else {
          return (
            <ruby key={idx}>
              {t[0]}
              <rp>(</rp>
              <rt>{t[1] || <>&nbsp;</>}</rt>
              <rp>)</rp>
            </ruby>
          );
        }
      })}
    </>
  );
};

export const rubyTextToHiragana = (txt: string): string => {
  return txt
    .split(/[{}]/)
    .filter(Boolean)
    .map((t) => {
      if (t.includes("|")) {
        return t.split("|")[1];
      } else {
        return t;
      }
    })
    .join("");
};

export const rubyTextToNihongo = (txt: string): string => {
  return txt
    .split(/[{}]/)
    .filter(Boolean)
    .map((t) => {
      if (t.includes("|")) {
        return t.split("|")[0];
      } else {
        return t;
      }
    })
    .join("");
};

export const removePunctuations = (str: string) => {
  return str.replace(/[。、., ~〜]/g, "");
};

export const removeSpaces = (str: string) => {
  return str.replace(/\s+/g, "");
};
