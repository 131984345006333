import { genDefaultChapterProps } from "../utils";
import Ch6MainConv from "./steps/MainConv";

const ch6 = genDefaultChapterProps(6);

ch6.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch6MainConv />,
});

export default ch6;
