import { genDefaultChapterProps } from "../utils";
import MainConv24 from "./steps/MainConv";

const ch24 = genDefaultChapterProps(24);

ch24.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv24 />,
});

export default ch24;
