import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv7: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch7/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch7/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch7/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ここは",
                      trans: "여기는",
                    },
                    {
                      nihongo: "とても",
                      trans: "매우",
                    },
                    {
                      nihongo: "{賑|にぎ}やか",
                      trans: "번화",
                    },
                    {
                      nihongo: "ですね。",
                      trans: "하네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch7/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですね。",
                      trans: "그렇네요.",
                    },
                    {
                      nihongo: "{人|ひと}が",
                      trans: "사람이",
                    },
                    {
                      nihongo: "{多|おお}くて",
                      trans: "많고",
                    },
                    {
                      nihongo: "おもしろい",
                      trans: "재미있는",
                    },
                    {
                      nihongo: "{街|まち}",
                      trans: "거리",
                    },
                    {
                      nihongo: "ですね。",
                      trans: "군요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch7/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "カラオケも",
                      trans: "노래방도",
                    },
                    {
                      nihongo: "{多|おお}いですね。",
                      trans: "많네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch7/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんは",
                      trans: "야마다 씨는",
                    },
                    {
                      nihongo: "カラオケが",
                      trans: "노래방을",
                    },
                    {
                      nihongo: "{好|す}きですか。",
                      trans: "좋아하세요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch7/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{好|す}きですが、",
                      trans: "좋아합니다만,",
                    },
                    {
                      nihongo: "{歌|うた}は",
                      trans: "노래는",
                    },
                    {
                      nihongo: "あまり",
                      trans: "그다지",
                    },
                    {
                      nihongo: "{上|じょう}{手|ず}じゃ",
                      trans: "잘하지",
                    },
                    {
                      nihongo: "ありません。",
                      trans: "않습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch7/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                    {
                      nihongo: "{私|わたし}も",
                      trans: "저도",
                    },
                    {
                      nihongo: "{歌|うた}が",
                      trans: "노래를",
                    },
                    {
                      nihongo: "{下|へ}{手|た}です。",
                      trans: "못합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv7;
