import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv8: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch8/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch8/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "きれいな",
                      trans: "깨끗한",
                    },
                    {
                      nihongo: "レストラン",
                      trans: "레스토랑",
                    },

                    {
                      nihongo: "ですね。",
                      trans: "이네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "ここは",
                      trans: "여기는",
                    },
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{有|ゆう}{名|めい}",
                      trans: "유명",
                    },
                    {
                      nihongo: "ですよ。",
                      trans: "합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "どんな",
                      trans: "어떤",
                    },
                    {
                      nihongo: "{料|りょう}{理|り}が",
                      trans: "요리가",
                    },

                    {
                      nihongo: "おいしいですか。",
                      trans: "맛있습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "すしが",
                      trans: "초밥이",
                    },
                    {
                      nihongo: "{新|しん}{鮮|せん}で、",
                      trans: "신선하고,",
                    },
                    {
                      nihongo: "おいしいです。",
                      trans: "맛있습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんは",
                      trans: "김 씨는",
                    },
                    {
                      nihongo: "すしが",
                      trans: "초밥을",
                    },
                    {
                      nihongo: "{好|す}きですか。",
                      trans: "좋아합니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{大|だい}{好|す}きです。",
                      trans: "아주 좋아합니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "でも、",
                      trans: "하지만,",
                    },
                    {
                      nihongo: "わさびは",
                      trans: "고추냉이는",
                    },
                    {
                      nihongo: "あまり",
                      trans: "별로",
                    },
                    {
                      nihongo: "{好|す}きじゃ",
                      trans: "좋아하지",
                    },
                    {
                      nihongo: "ありません。",
                      trans: "않습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "どうしてですか。",
                      trans: "어째서요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch8/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{辛|から}い",
                      trans: "맵기",
                    },
                    {
                      nihongo: "からです。",
                      trans: "때문입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv8;
