import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "react-bootstrap";

const MainSpeechButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button variant="light" {...props}>
      <FontAwesomeIcon icon={faVolumeHigh} size="xl" />
    </Button>
  );
};

export default MainSpeechButton;
