import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv4: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch4/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch4/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch4/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさん、",
                      trans: "김 씨,",
                    },
                    {
                      nihongo: "{今|いま}",
                      trans: "지금",
                    },

                    {
                      nihongo: "{何|なん}{時|じ}",
                      trans: "몇 시",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch4/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "4{時|じ}{半|はん}",
                      trans: "4시반",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch4/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{図|と}{書|しょ}{館|かん}は",
                      trans: "도서관은",
                    },
                    {
                      nihongo: "{何|なん}{時|じ}から",
                      trans: "몇 시부터",
                    },

                    {
                      nihongo: "{何|なん}{時|じ}まで",
                      trans: "몇 시까지",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch4/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{午|ご}{前|ぜん}",
                      trans: "오전",
                    },
                    {
                      nihongo: "9{時|じ}から",
                      trans: "9시 부터",
                    },
                    {
                      nihongo: "{午|ご}{後|ご}",
                      trans: "오후",
                    },
                    {
                      nihongo: "10{時|じ}まで",
                      trans: "10시까지",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch4/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ここから",
                      trans: "여기에서",
                    },
                    {
                      nihongo: "{図|と}{書|しょ}{館|かん}まで",
                      trans: "도서관까지",
                    },

                    {
                      nihongo: "{何|なん}{分|ぷん}",
                      trans: "몇 분",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch4/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "15{分|ふん}です。",
                      trans: "15분입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv4;
