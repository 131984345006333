import useAuth from "./useAuth";
import useQuizHitMethod from "./useQuizHitMethod";

interface Options {
  onCorrect?: VoidFunction;
  onWrong?: VoidFunction;
  onDone?: VoidFunction;
  answerPredicate?: (answer: string) => boolean;
  quiz_id?: string;
}

const useBasicQuizHitProcess = (initialOp?: Options) => {
  const { isSignedIn } = useAuth();
  const { quizHitCorrect, quizHitWrong } = useQuizHitMethod();

  const processHit = (answer: string, op?: Options) => {
    const { answerPredicate, onCorrect, onDone, onWrong, quiz_id } =
      Object.assign({}, initialOp, op);

    if (!answerPredicate || !quiz_id) {
      throw new Error("answer predicate and quiz_id must provided");
    }

    if (answerPredicate(answer)) {
      if (isSignedIn) {
        quizHitCorrect(quiz_id);
      }
      if (onCorrect) {
        onCorrect();
      }
    } else {
      if (isSignedIn) {
        quizHitWrong(quiz_id);
      }
      if (onWrong) {
        onWrong();
      }
    }
    if (onDone) {
      onDone();
    }
  };

  return processHit;
};

export default useBasicQuizHitProcess;
