import { genDefaultChapterProps } from "../utils";
import Ch10MainConv from "./steps/MainConv";

const ch10 = genDefaultChapterProps(10);

ch10.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch10MainConv />,
});

export default ch10;
