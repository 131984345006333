import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv1: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch1/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch1/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch1/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はじめまして、",
                      trans: "처음 뵙겠습니다,",
                    },
                    {
                      nihongo: "キムです。",
                      trans: "김 입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch1/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はじめまして、",
                      trans: "처음 뵙겠습니다,",
                    },
                    {
                      nihongo: "{山|やま}{田|だ}です。",
                      trans: "야마다 입니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch1/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "どうぞ",
                      trans: "모쪼록",
                    },
                    {
                      nihongo: "よろしく",
                      trans: "잘",
                    },
                    {
                      nihongo: "お{願|ねが}いします",
                      trans: "부탁드립니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch1/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "こちら",
                      trans: "이쪽",
                    },
                    {
                      nihongo: "こそ、",
                      trans: "이야말로,",
                    },
                    {
                      nihongo: "どうぞ",
                      trans: "모쪼록",
                    },
                    {
                      nihongo: "よろしく",
                      trans: "잘",
                    },
                    {
                      nihongo: "お{願|ねが}いします",
                      trans: "부탁드립니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv1;
