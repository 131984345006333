import QuizzesByQuizInput from "comps/_quizzes/QuizzesByQuizInput";
import WordsByQuizInput from "comps/Words/WordsByQuizInput";
import { ChapterProps } from "./Chapter";

const getBookNumber = (chapter: number) => {
  return Math.floor((chapter - 1) / 12) + 1;
};

export const genDefaultChapterProps = (chapter: number): ChapterProps => {
  return {
    index: chapter,
    steps: [
      {
        slug: "word",
        breadCrumb: "단어",
        elem: (
          <WordsByQuizInput
            onEndTo="../word-quiz"
            quizInput={{
              tags: [`skusku-${getBookNumber(chapter)}-${chapter}`],
            }}
          />
        ),
      },
      {
        slug: "word-quiz",
        breadCrumb: "단어퀴즈",
        elem: (
          <QuizzesByQuizInput
            onEndTo={`../../${chapter + 1}`}
            quizInput={{
              tags: [`skusku-${getBookNumber(chapter)}-${chapter}`],
            }}
          />
        ),
      },
    ],
  };
};
