import MyProgress from "comps/MyProgress";
import HitRecord from "comps/Quizzes/_comps/HitRecord";
import useAuth from "hooks/useAuth";
import useListIdxMethods from "hooks/useListIdxMethods";
import useQuizHitMethod from "hooks/useQuizHitMethod";
import useSortQuizByHits from "hooks/useSortQuizByHits";
import { Col, Container, Row } from "react-bootstrap";
import {
  removePunctuations,
  rubyTextToHiragana,
  rubyTextToNihongo,
} from "utils";
import { GetQuizHitsQuery } from "__generated__/graphql";
import DDQuiz, { TDDQuiz } from "./DDQuiz";

interface Props {
  onEnd?: VoidFunction;
  quizzes: TDDQuiz[];
  quizHits?: GetQuizHitsQuery["quizHits"];
}

const DialogueDictationQuizzes: React.FC<Props> = ({
  onEnd,
  quizzes: unsortedQuizzes,
  quizHits,
}) => {
  const quizzes = useSortQuizByHits({
    unsortedQuizzes,
    quizHits,
  });

  const total = quizzes.length;
  const { index, increaseIndex } = useListIdxMethods({ total, onEnd });

  const curQuiz = quizzes[index];

  const { isSignedIn } = useAuth();
  const { quizHitCorrect, quizHitWrong } = useQuizHitMethod();

  const isCorrectAnswer = (answer: any) => {
    answer = removePunctuations(answer);

    const answers = [
      rubyTextToNihongo(curQuiz.sentenceB.nihongo),
      rubyTextToHiragana(curQuiz.sentenceB.nihongo),
    ].map((a) => removePunctuations(a));

    return answers.includes(answer);
  };

  const handleQuizAnswer = (answer: any) => {
    if (isCorrectAnswer(answer)) {
      if (isSignedIn) {
        quizHitCorrect(curQuiz.id);
      }
    } else {
      alert(rubyTextToNihongo(curQuiz.sentenceB.nihongo));
      if (isSignedIn) {
        quizHitWrong(curQuiz.id);
      }
    }
    increaseIndex();
  };

  return (
    <Container>
      <Row>
        <Col>
          <MyProgress total={total} done={index} />
        </Col>
        <Col>
          <HitRecord quiz_id={curQuiz.id} />
        </Col>
      </Row>
      <DDQuiz {...curQuiz} onAnswer={handleQuizAnswer} />
    </Container>
  );
};

export default DialogueDictationQuizzes;
