import styled from "@emotion/styled";
import _ from "lodash";

const Wrapper = styled.header`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
const Head = styled.h2`
  font-weight: bold;
`;
const Index = styled.span`
  color: #7e57c2;

  margin-right: 1rem;
`;
const T = styled.span`
  font-size: 0.875em;
`;
const Description = styled.h5``;

interface Props {
  index: number;
  title: string;
  description?: string;
}

const Title: React.FC<Props> = ({ index, title, description }) => {
  return (
    <Wrapper>
      <Head>
        <Index>{_.padStart(index.toString(), 2, "0")}</Index>
        <T>{title}</T>
      </Head>
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default Title;
