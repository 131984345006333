import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv23: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch23/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch23/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{先|せん}{生|せい}", trans: "선생님" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{今日|きょう}は",
                      trans: "오늘은",
                    },
                    {
                      nihongo: "{韓|かん}{国|こく}{語|ご}で",
                      trans: "한국어로",
                    },
                    {
                      nihongo: "{作|さく}{文|ぶん}を",
                      trans: "작문을",
                    },
                    {
                      nihongo: "{書|か}きましょう。",
                      trans: "써 봅시다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "テーマは",
                      trans: "테마는",
                    },
                    {
                      nihongo:
                        "「{韓|かん}{国|こく}での{生|せい}{活|かつ}」です。",
                      trans: "[한국에서의 생활]입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{短|みじか}い",
                      trans: "짧은",
                    },
                    {
                      nihongo: "{作|さく}{文|ぶん}は",
                      trans: "작문은",
                    },
                    {
                      nihongo: "だめですか。",
                      trans: "안 됩니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{先|せん}{生|せい}", trans: "선생님" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "いろいろな",
                      trans: "여러 가지",
                    },
                    {
                      nihongo: "{単|たん}{語|ご}を",
                      trans: "단어를",
                    },
                    {
                      nihongo: "{使|つか}って、",
                      trans: "사용해서,",
                    },
                    {
                      nihongo: "{長|なが}く",
                      trans: "길게",
                    },
                    {
                      nihongo: "{書|か}いて",
                      trans: "써",
                    },
                    {
                      nihongo: "ください。",
                      trans: "주세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{字|じ}が",
                      trans: "글씨가",
                    },
                    {
                      nihongo: "{汚|きたな}いですから、",
                      trans: "예쁘지 않기 때문에,",
                    },
                    {
                      nihongo: "パソコンを",
                      trans: "컴퓨터를",
                    },
                    {
                      nihongo: "{使|つか}っても",
                      trans: "사용해도",
                    },
                    {
                      nihongo: "いいですか。",
                      trans: "됩니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{先|せん}{生|せい}", trans: "선생님" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "パソコンは",
                      trans: "컴퓨터는",
                    },
                    {
                      nihongo: "{使|つか}わないで",
                      trans: "사용하지",
                    },
                    {
                      nihongo: "ください。",
                      trans: "마세요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ペンで",
                      trans: "펜으로",
                    },
                    {
                      nihongo: "{書|か}いてください。",
                      trans: "쓰세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{辞|じ}{書|しょ}を",
                      trans: "사전을",
                    },
                    {
                      nihongo: "{見|み}ても",
                      trans: "봐도",
                    },
                    {
                      nihongo: "いいですか。",
                      trans: "됩니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{先|せん}{生|せい}", trans: "선생님" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{辞|じ}{書|しょ}は",
                      trans: "사전은",
                    },
                    {
                      nihongo: "{見|み}ても",
                      trans: "봐도",
                    },
                    {
                      nihongo: "いいです。",
                      trans: "됩니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch23/main-conv/10.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ゆっくり",
                      trans: "천천히",
                    },
                    {
                      nihongo: "{書|か}いても",
                      trans: "써도",
                    },
                    {
                      nihongo: "いいですから、",
                      trans: "좋으니까,",
                    },
                    {
                      nihongo: "きれいに",
                      trans: "깔끔하게",
                    },
                    {
                      nihongo: "{書|か}いて",
                      trans: "써",
                    },
                    {
                      nihongo: "ください。",
                      trans: "주세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv23;
