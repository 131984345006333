import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv15: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch15/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch15/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "{昨日|きのう}は",
                      trans: "어제는",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "しましたか。",
                      trans: "했습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{友|とも}だちと",
                      trans: "친구와",
                    },
                    {
                      nihongo: "デパートで",
                      trans: "백화점에서",
                    },
                    {
                      nihongo: "{買|か}い{物|もの}を",
                      trans: "쇼핑을",
                    },
                    {
                      nihongo: "しました。",
                      trans: "했습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいですね。",
                      trans: "좋네요.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "{買|か}いましたか。",
                      trans: "샀습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "くつと",
                      trans: "구두와",
                    },
                    {
                      nihongo: "{財|さい}{布|ふ}を",
                      trans: "지갑을",
                    },
                    {
                      nihongo: "{買|か}いました。",
                      trans: "샀습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "それから、",
                      trans: "그리고 나서,",
                    },
                    {
                      nihongo: "{友|とも}だちと",
                      trans: "친구와",
                    },
                    {
                      nihongo: "{一|いっ}{緒|しょ}に",
                      trans: "함께",
                    },
                    {
                      nihongo: "ごはんを",
                      trans: "밥을",
                    },
                    {
                      nihongo: "{食|た}べました。",
                      trans: "먹었습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "{食|た}べましたか。",
                      trans: "먹었습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムチチゲを",
                      trans: "김치찌개를",
                    },
                    {
                      nihongo: "{食|た}べました。",
                      trans: "먹었습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch15/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{少|すこ}し",
                      trans: "조금",
                    },
                    {
                      nihongo: "{辛|から}かったですが、",
                      trans: "매웠지만,",
                    },
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "おいしくて",
                      trans: "맛있고",
                    },
                    {
                      nihongo: "よかったです。",
                      trans: "좋았습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv15;
