import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv21: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch21/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch21/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{来|らい}{週|しゅう}の",
                      trans: "다음주",
                    },
                    {
                      nihongo: "{月|げつ}{曜|よう}{日|び}から",
                      trans: "월요일부터",
                    },
                    {
                      nihongo: "{仕|し}{事|ごと}で",
                      trans: "일때문에",
                    },
                    {
                      nihongo: "{北|ほっ}{海|かい}{道|どう}に",
                      trans: "홋카이도(북해도)에",
                    },
                    {
                      nihongo: "{行|い}きます。",
                      trans: "갑니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんは",
                      trans: "야마다 씨는",
                    },
                    {
                      nihongo: "{北|ほっ}{海|かい}{道|どう}に",
                      trans: "홋카이도(북해도)에",
                    },
                    {
                      nihongo: "{行|い}ったことが",
                      trans: "간 적이",
                    },
                    {
                      nihongo: "ありますか。",
                      trans: "있습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "まだ",
                      trans: "아직",
                    },
                    {
                      nihongo: "{行|い}ったことが",
                      trans: "간 적이",
                    },
                    {
                      nihongo: "ありません。",
                      trans: "없습니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{北|ほっ}{海|かい}{道|どう}は",
                      trans: "홋카이도는",
                    },
                    {
                      nihongo: "おいしいものが",
                      trans: "맛있는 것이",
                    },
                    {
                      nihongo: "たくさん",
                      trans: "많기",
                    },
                    {
                      nihongo: "ありますから、",
                      trans: "때문에",
                    },
                    {
                      nihongo: "行いきたいです。",
                      trans: "가고 싶습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{北|ほっ}{海|かい}{道|どう}は",
                      trans: "홋카이도는",
                    },
                    {
                      nihongo: "{何|なに}が",
                      trans: "무엇이",
                    },
                    {
                      nihongo: "{有|ゆう}{名|めい}ですか。",
                      trans: "유명합니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{北|ほっ}{海|かい}{道|どう}は",
                      trans: "홋카이도는",
                    },
                    {
                      nihongo: "ビールと",
                      trans: "맥주와",
                    },
                    {
                      nihongo: "カニが",
                      trans: "게가",
                    },
                    {
                      nihongo: "{有|ゆう}{名|めい}です。",
                      trans: "유명합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "お{土産|みやげ}は",
                      trans: "선물은",
                    },
                    {
                      nihongo: "{何|なに}が",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "ほしいですか。",
                      trans: "갖고 싶습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{北|ほっ}{海|かい}{道|どう}は",
                      trans: "홋카이도는",
                    },
                    {
                      nihongo: "チョコレートが",
                      trans: "초콜릿이",
                    },
                    {
                      nihongo: "おいしいから、",
                      trans: "맛있기 때문에,",
                    },
                    {
                      nihongo: "{頼|たの}んでも",
                      trans: "부탁해도",
                    },
                    {
                      nihongo: "いいですか。",
                      trans: "되겠습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch21/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "じゃあ、",
                      trans: "그러면,",
                    },
                    {
                      nihongo: "お{土産|みやげ}は",
                      trans: "선물은",
                    },
                    {
                      nihongo: "チョコレートですね。",
                      trans: "초콜릿이네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv21;
