import { useQuery } from "@apollo/client";
import { GET_QUIZZES } from "gqls";
import QuizInputProps from "types/quiz/QuizInputProps";

const withQuizzes =
  <T,>(Comp: React.ComponentType<T>) =>
  (props: Omit<T, "quizzes"> & QuizInputProps) => {
    const { loading, error, data } = useQuery(GET_QUIZZES, {
      variables: {
        input: props.quizInput,
        limit: 200,
      },
    });

    if (loading || error || !data) return null;

    const {
      quizzes: { quizzes },
    } = data;

    return <Comp quizzes={quizzes} {...(props as T)} />;
  };

export default withQuizzes;
