import { genDefaultChapterProps } from "../utils";
import MainConv23 from "./steps/MainConv";

const ch23 = genDefaultChapterProps(23);

ch23.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv23 />,
});

export default ch23;
