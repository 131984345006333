import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv19: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch19/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch19/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "もしもし、",
                      trans: "여보세요.",
                    },
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "{今|いま}",
                      trans: "지금",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "무엇을",
                    },
                    {
                      nihongo: "していますか。",
                      trans: "하고 있습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "うちで",
                      trans: "집에서",
                    },
                    {
                      nihongo: "{休|やす}んで",
                      trans: "쉬고",
                    },
                    {
                      nihongo: "いますよ。",
                      trans: "있습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{今|いま}から",
                      trans: "지금부터",
                    },
                    {
                      nihongo: "{一|いっ}{緒|しょ}に",
                      trans: "함께",
                    },
                    {
                      nihongo: "お{酒|さけ}を",
                      trans: "술",
                    },
                    {
                      nihongo: "{飲|の}みに",
                      trans: "마시러",
                    },
                    {
                      nihongo: "{行|い}きませんか。",
                      trans: "가지 않을래요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "お{酒|さけ}ですか。",
                      trans: "술이요?",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{何|なに}か",
                      trans: "뭔가",
                    },
                    {
                      nihongo: "ありましたか。",
                      trans: "있었습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{実|じつ}は",
                      trans: "실은",
                    },
                    {
                      nihongo: "{彼|かの}{女|じょ}と",
                      trans: "여자 친구와",
                    },
                    {
                      nihongo: "けんかを",
                      trans: "싸움을",
                    },
                    {
                      nihongo: "して",
                      trans: "하고",
                    },
                    {
                      nihongo: "しまいました。",
                      trans: "말았어요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "え、",
                      trans: "에,",
                    },
                    {
                      nihongo: "どうしてですか。",
                      trans: "왜요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{忙|いそが}しくて、",
                      trans: "바빠서,",
                    },
                    {
                      nihongo: "{彼|かの}{女|じょ}の",
                      trans: "여자 친구",
                    },
                    {
                      nihongo: "{誕|たん}{生|じょう}{日|び}を",
                      trans: "생일을",
                    },
                    {
                      nihongo: "{忘|わす}れて",
                      trans: "잊어버리고",
                    },
                    {
                      nihongo: "しまいましたから。",
                      trans: "말았기 때문에요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ひどいですね。",
                      trans: "심하네요.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "それは",
                      trans: "그것은",
                    },
                    {
                      nihongo: "キムさんが",
                      trans: "김 씨가",
                    },
                    {
                      nihongo: "{悪|わる}いですよ。",
                      trans: "나쁘네요.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch19/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{早|はや}く",
                      trans: "빨리",
                    },
                    {
                      nihongo: "{彼|かの}{女|じょ}に",
                      trans: "여자 친구에게",
                    },
                    {
                      nihongo: "あやまって",
                      trans: "사과",
                    },
                    {
                      nihongo: "ください。",
                      trans: "하세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv19;
