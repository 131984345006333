import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "comps/buttons/IconButton";
import { ComponentProps } from "react";

const HambergerIconButton: React.FC<ComponentProps<typeof IconButton>> = (
  props
) => {
  return (
    <IconButton {...props}>
      <FontAwesomeIcon icon={faBars} />
    </IconButton>
  );
};

export default HambergerIconButton;
