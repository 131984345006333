import styled from "@emotion/styled";
import { NotStyledNavLink } from "comps/links";
import ProfileMenu from "layouts/ProfileMenu";
import { HTMLAttributes } from "react";
import HeaderLogo from "./HeaderLogo";

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  padding: 0 1em;
`;

const Navs = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const MyNavLink = styled(NotStyledNavLink)`
  margin-left: 1em;
  color: gray;

  &.active {
    color: var(--bs-body-color);
    font-weight: bold;
  }
`;

const Header: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Wrapper {...props}>
      <HeaderLogo />
      <Navs>
        <MyNavLink to="/learn">learn</MyNavLink>
        <MyNavLink to="/quizzes">quizzes</MyNavLink>
        <MyNavLink to="/community">community</MyNavLink>
      </Navs>
      <ProfileMenu />
    </Wrapper>
  );
};

export default Header;
