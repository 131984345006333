import styled from "@emotion/styled";
import { Breadcrumb } from "react-bootstrap";
import MyBreadcrumbItem from "./MyBreadcrumbItem";

const MyBreadcrumb = styled(Breadcrumb)`
  --bs-breadcrumb-divider: ">";

  a {
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  .breadcrumb {
    margin: 0;
  }
`;

export default Object.assign(MyBreadcrumb, {
  Item: MyBreadcrumbItem,
});
