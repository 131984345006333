import MyBreadcrumb from "comps/MyBreadcrumb";
import useNavFnFactory from "hooks/useNavFnFactory";
import { Navigate, Route, Routes } from "react-router-dom";
import ChapterBase from "../ChapterBase";
import BasicWords from "./steps/BasicWords";
import BasicWordsQuiz from "./steps/BasicWordsQuiz";
import DDQuizzes from "./steps/DDQuizzes";
import DictationQuizzes2 from "./steps/DictationQuizzes2";
import Learn from "./steps/Learn";
import MainConv1 from "./steps/MainConv";
import OXQuizzes from "./steps/OXQuizzes";
import PDQuizzes from "./steps/PDQuizzes";
import ToKoreanQuizzes from "./steps/ToKoreanQuizzes";
import ToNihongoQuizzes from "./steps/ToNihongoQuizzes";

const Chapter1: React.FC = () => {
  const navFnFactory = useNavFnFactory();
  return (
    <ChapterBase>
      <ChapterBase.Header>
        <ChapterBase.Heading>CH 1.</ChapterBase.Heading>
        <MyBreadcrumb>
          <MyBreadcrumb.Item to="basic-words">기본단어</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="basic-words-quiz">
            기본단어 퀴즈
          </MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="main-conversation">
            메인 대화
          </MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="learn">익히기</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="fill-blank">빈칸 채우기</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="ox-quiz">OX 퀴즈</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="dictation-quiz">문장 완성</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="to-korean-quiz">한글쓰기</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="to-nihongo-quiz">일본어쓰기</MyBreadcrumb.Item>
          <MyBreadcrumb.Item to="dictation-quiz-2">듣고쓰기</MyBreadcrumb.Item>
        </MyBreadcrumb>
      </ChapterBase.Header>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="basic-words" replace={true} />}
        />
        <Route
          path="basic-words"
          element={<BasicWords onEnd={navFnFactory("basic-words-quiz")} />}
        />
        <Route
          path="basic-words-quiz"
          element={<BasicWordsQuiz onEnd={navFnFactory("main-conversation")} />}
        />
        <Route path="main-conversation" element={<MainConv1 />} />
        <Route
          path="learn"
          element={<Learn onEnd={navFnFactory("fill-blank")} />}
        />
        <Route
          path="fill-blank"
          element={<DDQuizzes onEnd={navFnFactory("ox-quiz")} />}
        />
        <Route
          path="ox-quiz"
          element={<OXQuizzes onEnd={navFnFactory("dictation-quiz")} />}
        />
        <Route
          path="dictation-quiz"
          element={<PDQuizzes onEnd={navFnFactory("to-korean-quiz")} />}
        />
        <Route
          path="to-korean-quiz"
          element={<ToKoreanQuizzes onEnd={navFnFactory("to-nihongo-quiz")} />}
        />
        <Route
          path="to-nihongo-quiz"
          element={
            <ToNihongoQuizzes onEnd={navFnFactory("dictation-quiz-2")} />
          }
        />
        <Route
          path="dictation-quiz-2"
          element={<DictationQuizzes2 onEnd={navFnFactory("../chapter-2")} />}
        />
      </Routes>
    </ChapterBase>
  );
};

export default Chapter1;
