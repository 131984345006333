import ExpandableMenu from "comps/sidebar/ExpandableMenu";
import _ from "lodash";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

const SideMenus: React.FC = () => {
  return (
    <Stack>
      <ExpandableMenu menu="입문">
        <Stack>
          {_.range(1, 13).map((c) => {
            const ch = c.toString();
            return (
              <Link to={ch} className="btn btn-link" key={ch}>
                chapter {ch}
              </Link>
            );
          })}
        </Stack>
      </ExpandableMenu>
      <ExpandableMenu menu="초급">
        <Stack>
          {_.range(13, 25).map((c) => {
            const ch = c.toString();
            return (
              <Link to={ch} className="btn btn-link" key={ch}>
                chapter {ch}
              </Link>
            );
          })}
        </Stack>
      </ExpandableMenu>
    </Stack>
  );
};

export default SideMenus;
