import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv24: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch24/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch24/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さん、",
                      trans: "야마다 씨,",
                    },
                    {
                      nihongo: "ごはんを",
                      trans: "밥을",
                    },
                    {
                      nihongo: "{食|た}べましたか。",
                      trans: "먹었습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "まだです。",
                      trans: "아직입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{映|えい}{画|が}を",
                      trans: "영화를",
                    },
                    {
                      nihongo: "{見|み}る",
                      trans: "보기",
                    },
                    {
                      nihongo: "{前|まえ}に",
                      trans: "전에",
                    },
                    {
                      nihongo: "パンでも",
                      trans: "빵이라도",
                    },
                    {
                      nihongo: "{食|た}べましょうか。",
                      trans: "먹을까요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "でも、",
                      trans: "하지만,",
                    },
                    {
                      nihongo: "もうすぐ",
                      trans: "이제 곧",
                    },
                    {
                      nihongo: "{始|はじ}まるから、",
                      trans: "시작되니까,",
                    },
                    {
                      nihongo: "{映|えい}{画|が}を",
                      trans: "영화를",
                    },
                    {
                      nihongo: "{見|み}た",
                      trans: "본",
                    },
                    {
                      nihongo: "{後|あと}で",
                      trans: "후에",
                    },
                    {
                      nihongo: "{食|た}べても",
                      trans: "먹어도",
                    },
                    {
                      nihongo: "いいですよ。",
                      trans: "됩니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんは",
                      trans: "김 씨는",
                    },
                    {
                      nihongo: "{食|た}べましたか。",
                      trans: "먹었습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "{実|じつ}は",
                      trans: "실은",
                    },
                    {
                      nihongo: "{私|わたし}も",
                      trans: "저도",
                    },
                    {
                      nihongo: "まだです。",
                      trans: "아직입니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}は",
                      trans: "저는",
                    },
                    {
                      nihongo: "{朝|あさ}",
                      trans: "아침에",
                    },
                    {
                      nihongo: "{早|はや}く",
                      trans: "일찍",
                    },
                    {
                      nihongo: "{起|お}きることが",
                      trans: "일어날 수",
                    },
                    {
                      nihongo: "できませんから・・・。",
                      trans: "없기때문에...",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんは、",
                      trans: "야마다 씨는,",
                    },
                    {
                      nihongo: "{朝|あさ}",
                      trans: "아침에",
                    },
                    {
                      nihongo: "{早|はや}く",
                      trans: "일찍",
                    },
                    {
                      nihongo: "{起|お}きることが",
                      trans: "일어날 수",
                    },
                    {
                      nihongo: "できますか。",
                      trans: "있습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/10.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ええ、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{早|はや}く",
                      trans: "일찍",
                    },
                    {
                      nihongo: "{起|お}きることは",
                      trans: "일어날 수는",
                    },
                    {
                      nihongo: "できますが、",
                      trans: "있습니다만,",
                    },
                    {
                      nihongo: "{夜|よる}は",
                      trans: "밤에",
                    },
                    {
                      nihongo: "{弱|よわ}いですから、",
                      trans: "약하기 때문에,",
                    },
                    {
                      nihongo: "１０{時|じ}ぐらいに",
                      trans: "10시 정도에",
                    },
                    {
                      nihongo: "{寝|ね}ます。",
                      trans: "잡니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch24/main-conv/11.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "じゃあ、",
                      trans: "그러면,",
                    },
                    {
                      nihongo: "{夜|よる}は",
                      trans: "밤에는",
                    },
                    {
                      nihongo: "{遊|あそ}ぶことが",
                      trans: "놀 수",
                    },
                    {
                      nihongo: "できませんね。",
                      trans: "없겠네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv24;
