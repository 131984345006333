import BorderBluredImg from "comps/imgs/BorderBlurredImg";

interface Props {
  url: string;
}

const MainImg: React.FC<Props> = ({ url }) => {
  return (
    <BorderBluredImg
      style={{
        height: `300px`,
        backgroundImage: `url(${url})`,
      }}
    />
  );
};

export default MainImg;
