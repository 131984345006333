import styled from "@emotion/styled";
import Dialogue from "comps/Dialogue";
import SingleInputForm from "comps/forms/SingleInputForm";
import useAutoPlayOnMount from "hooks/useAutoPlayOnMount";
import useBasicQuizHitProcess from "hooks/useBasicQuizHitProcess";
import useBasicQuizMatchMethods from "hooks/useBasicQuizMatchMethods";
import useVoiceController from "hooks/useVoiceController";
import { Container } from "react-bootstrap";
import BasePhrase from "types/quiz/BasePhrase";
import BaseQuiz from "types/quiz/BaseQuiz";
import QuizComp from "types/quiz/QuizComp";
import WithIsQuestion from "types/quiz/WithIsQuestion";
import WithSentence from "types/quiz/WithSentence";
import WithVoice from "types/quiz/WithVoice";
import { rubyTextToNihongo } from "utils";

export interface PDQuiz
  extends WithSentence<WithVoice<BaseQuiz>, WithIsQuestion<BasePhrase>[]> {}

const Wrapper = styled(Container)`
  margin: 4rem 0;
  font-size: 2rem;
`;

const PartialDictationQuiz: QuizComp<PDQuiz> = ({
  quiz: { id, sentence, voice },
  onDone,
}) => {
  const voiceCtrl = useVoiceController(voice);
  useAutoPlayOnMount(voiceCtrl);

  const { isMatch } = useBasicQuizMatchMethods();
  const processHit = useBasicQuizHitProcess();

  return (
    <Wrapper>
      <Dialogue.Row>
        {sentence.map((p, i) => {
          if (p.isQuestion) {
            return (
              <SingleInputForm
                key={i}
                onSubmit={(val) => {
                  processHit(val, {
                    onDone,
                    quiz_id: id,
                    answerPredicate: (t) => isMatch(t, p.nihongo),
                    onWrong: () => {
                      alert(rubyTextToNihongo(p.nihongo));
                    },
                  });
                }}
              />
            );
          } else {
            return <Dialogue.Phrase key={i}>{p.nihongo}</Dialogue.Phrase>;
          }
        })}
      </Dialogue.Row>
    </Wrapper>
  );
};

export default PartialDictationQuiz;
