import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv10: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch10/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch10/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{店|てん}{員|いん}", trans: "점원" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いらっしゃいませ。",
                      trans: "어서 오십시오.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "すみません。",
                      trans: "실례합니다.",
                    },
                    {
                      nihongo: "この",
                      trans: "이",
                    },
                    {
                      nihongo: "かばんは",
                      trans: "가방은",
                    },
                    {
                      nihongo: "いくら",
                      trans: "얼마.",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "인가요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{店|てん}{員|いん}", trans: "점원" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "５６００{円|えん}です。",
                      trans: "5600엔 입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                    {
                      nihongo: "{少|すこ}し",
                      trans: "조금",
                    },
                    {
                      nihongo: "{高|たか}い",
                      trans: "비싸",
                    },
                    {
                      nihongo: "ですね。",
                      trans: "네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{店|てん}{員|いん}", trans: "점원" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "じゃあ、",
                      trans: "그러면,",
                    },
                    {
                      nihongo: "あれは",
                      trans: "저것은",
                    },
                    {
                      nihongo: "どうですか。",
                      trans: "어떻습니까?",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "この",
                      trans: "이",
                    },
                    {
                      nihongo: "かばんより",
                      trans: "가방보다",
                    },
                    {
                      nihongo: "{安|やす}くて",
                      trans: "싸고",
                    },
                    {
                      nihongo: "いいですよ。",
                      trans: "좋습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "あれは",
                      trans: "저것은",
                    },
                    {
                      nihongo: "いくら",
                      trans: "얼마",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{店|てん}{員|いん}", trans: "점원" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "３８００{円|えん}です。",
                      trans: "3800엔 입니다.",
                    },
                    {
                      nihongo: "{大|おお}きくて、",
                      trans: "크고,",
                    },
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{便|べん}{利|り}",
                      trans: "편리",
                    },
                    {
                      nihongo: "ですよ。",
                      trans: "합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/9.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "じゃあ、",
                      trans: "그러면,",
                    },
                    {
                      nihongo: "あれ",
                      trans: "저것",
                    },
                    {
                      nihongo: "１つ",
                      trans: "하나",
                    },
                    {
                      nihongo: "ください。",
                      trans: "주세요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{店|てん}{員|いん}", trans: "점원" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch10/main-conv/10.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "ありがとうございます。",
                      trans: "감사합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv10;
