import Quizzes from "comps/Quizzes";
import injectQuizInput from "hocs/injectQuizInput";
import withQuizHits from "hocs/withQuizHits";
import withQuizzes from "hocs/withQuizzes";

const BasicWordsQuiz = injectQuizInput({
  tags: ["스쿠스쿠일본어", "ch1", "기본단어"],
})(withQuizzes(withQuizHits(Quizzes)));

export default BasicWordsQuiz;
