interface Options {
  chapter: number;
  isWorkBook: boolean;
  pageNumber: number;
  extra: string;
}

const getSkuskuQuizId = ({
  chapter,
  isWorkBook,
  pageNumber,
  extra,
}: Options) => {
  return [
    "skusku",
    isWorkBook ? "workbook" : "mainbook",
    chapter,
    pageNumber,
    extra,
  ].join("-");
};

export default getSkuskuQuizId;
