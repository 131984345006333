import styled from "@emotion/styled";
import Sidebar from "comps/sidebar";
import { Route, Routes } from "react-router-dom";
import SideMenus from "./SideMenus";
import Chapter1 from "./chapters/1";
import ch13 from "./chapters/13";
import ch14 from "./chapters/14";
import ch15 from "./chapters/15";
import ch16 from "./chapters/16";
import ch17 from "./chapters/17";
import ch18 from "./chapters/18";
import ch19 from "./chapters/19";
import Chapter2 from "./chapters/2";
import ch20 from "./chapters/20";
import ch21 from "./chapters/21";
import ch22 from "./chapters/22";
import ch23 from "./chapters/23";
import ch24 from "./chapters/24";
import Chapter, { ChapterProps } from "./chapters/Chapter";
import ch12 from "./chapters/12";
import ch11 from "./chapters/11";
import ch10 from "./chapters/10";
import ch9 from "./chapters/9";
import ch8 from "./chapters/8";
import ch7 from "./chapters/7";
import ch6 from "./chapters/6";
import ch5 from "./chapters/5";
import ch4 from "./chapters/4";
import ch3 from "./chapters/3";

const chapters: ChapterProps[] = [
  ch3,
  ch4,
  ch5,
  ch6,
  ch7,
  ch8,
  ch9,
  ch10,
  ch11,
  ch12,
  ch13,
  ch14,
  ch15,
  ch16,
  ch17,
  ch18,
  ch19,
  ch20,
  ch21,
  ch22,
  ch23,
  ch24,
];

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  .sidebar {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;

const Learn: React.FC = () => {
  return (
    <Wrapper>
      <Sidebar heading="Learn">
        <SideMenus />
      </Sidebar>
      <Body>
        <Routes>
          <Route path="1/*" element={<Chapter1 />} />
          <Route path="2/*" element={<Chapter2 />} />
          {chapters.map((chapter) => {
            return (
              <Route
                path={`${chapter.index}/*`}
                element={<Chapter {...chapter} />}
                key={chapter.index}
              />
            );
          })}
        </Routes>
      </Body>
    </Wrapper>
  );
};

export default Learn;
