import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv9: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch9/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch9/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{韓|かん}{国|こく}の",
                      trans: "한국의",
                    },
                    {
                      nihongo: "{冬|ふゆ}は",
                      trans: "겨울은",
                    },
                    {
                      nihongo: "とても",
                      trans: "매우",
                    },
                    {
                      nihongo: "{寒|さむ}いですね。",
                      trans: "춥네요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "そうですか。",
                      trans: "그렇습니까?",
                    },
                    {
                      nihongo: "{東|とう}{京|きょう}と",
                      trans: "동경과",
                    },
                    {
                      nihongo: "ソウルと",
                      trans: "서울과",
                    },
                    {
                      nihongo: "どちらが",
                      trans: "어느 쪽이",
                    },
                    {
                      nihongo: "{寒|さむ}いですか。",
                      trans: "춥습니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{東|とう}{京|きょう}より",
                      trans: "동경보다",
                    },
                    {
                      nihongo: "ソウルの",
                      trans: "서울",
                    },
                    {
                      nihongo: "{方|ほう}が",
                      trans: "쪽이",
                    },
                    {
                      nihongo: "{寒|さむ}いです。",
                      trans: "춥습니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんは",
                      trans: "야마다 씨는",
                    },
                    {
                      nihongo: "{季|き}{節|せつ}の",
                      trans: "계절",
                    },
                    {
                      nihongo: "{中|なか}で",
                      trans: "중에서",
                    },
                    {
                      nihongo: "いつが",
                      trans: "언제를",
                    },
                    {
                      nihongo: "{一|いち}{番|ばん}",
                      trans: "가장",
                    },
                    {
                      nihongo: "{好|す}きですか。",
                      trans: "좋아합니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}は",
                      trans: "저는",
                    },
                    {
                      nihongo: "{春|はる}が",
                      trans: "봄을",
                    },
                    {
                      nihongo: "{一|いち}{番|ばん}",
                      trans: "제일",
                    },
                    {
                      nihongo: "{好|す}きです。",
                      trans: "좋아합니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんは",
                      trans: "김 씨는",
                    },
                    {
                      nihongo: "いつが",
                      trans: "언제를",
                    },
                    {
                      nihongo: "{一|いち}{番|ばん}",
                      trans: "가장",
                    },
                    {
                      nihongo: "{好|す}きですか。",
                      trans: "좋아합니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch9/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{水|すい}{泳|えい}が",
                      trans: "수영을",
                    },
                    {
                      nihongo: "{好|す}きだから、",
                      trans: "좋아하기 때문에,",
                    },
                    {
                      nihongo: "{夏|なつ}が",
                      trans: "여름을",
                    },
                    {
                      nihongo: "{一|いち}{番|ばん}",
                      trans: "가장",
                    },
                    {
                      nihongo: "{好|す}きです。",
                      trans: "좋아합니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv9;
