import styled from "@emotion/styled";

const BorderBluredImg = styled.div`
  /* height: 300px;
  background-image: url(${null}); */
  background-size: cover;
  background-position: center;

  box-shadow: 0 0 8px 8px white inset;
`;

export default BorderBluredImg;
