import { genDefaultChapterProps } from "../utils";
import Ch7MainConv from "./steps/MainConv";

const ch7 = genDefaultChapterProps(7);

ch7.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch7MainConv />,
});

export default ch7;
