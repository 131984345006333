import styled from "@emotion/styled";
import SingleInputForm from "comps/forms/SingleInputForm";
import useBasicQuizHitProcess from "hooks/useBasicQuizHitProcess";
import useBasicQuizMatchMethods from "hooks/useBasicQuizMatchMethods";
import { Container } from "react-bootstrap";
import BaseQuiz from "types/quiz/BaseQuiz";
import QuizComp from "types/quiz/QuizComp";
import WithAnswer from "types/quiz/WithAnswer";
import WithKorean from "types/quiz/WithKorean";
import { rubyTextToNihongo } from "utils";

export interface TNQuiz extends WithAnswer<WithKorean<BaseQuiz>> {}

const Wrapper = styled(Container)`
  margin: 4rem 0;
  font-size: 3rem;
  text-align: center;
`;

const ToNihongoQuiz: QuizComp<TNQuiz> = ({
  quiz: { id, answer, answers, korean },
  onDone,
}) => {
  const { isMatch } = useBasicQuizMatchMethods();
  const processHit = useBasicQuizHitProcess({
    onDone,
    quiz_id: id,
    answerPredicate: (t) => {
      return [answer, ...answers].reduce((prev, a) => {
        return prev || isMatch(t, a);
      }, false);
    },
    onWrong: () => {
      alert(rubyTextToNihongo(answer));
    },
  });

  return (
    <Wrapper>
      <div>{korean}</div>
      <SingleInputForm onSubmit={processHit} />
    </Wrapper>
  );
};

export default ToNihongoQuiz;
