import styled from "@emotion/styled";
import { NotStyledLink } from "comps/links";

const MyLink = styled(NotStyledLink)`
  font-size: 30px;
  font-weight: bold;
  filter: drop-shadow(0.05em 0.05em 0px red);
`;

const HeaderLogo: React.FC = () => {
  return <MyLink to="/">GN</MyLink>;
};

export default HeaderLogo;
