import useAuth from "hooks/useAuth";
import useQuizHit from "hooks/useQuizHit";
import { Container, ProgressBar } from "react-bootstrap";
import { HoCInjector } from "types/hoc";

const withAuth: HoCInjector = (Comp) => (props) => {
  const { isSignedIn } = useAuth();

  if (!isSignedIn) return null;

  return <Comp {...props} />;
};

interface Props {
  quiz_id: string;
}

const HitRecord: React.FC<Props> = ({ quiz_id }) => {
  const quizHit = useQuizHit(quiz_id);

  const total = quizHit?.total || 0;
  const correct = quizHit?.correct || 0;
  const wrong = quizHit?.wrong || 0;

  const hitRatio = total === 0 ? 0 : Math.round((correct / total) * 100);

  return (
    <Container>
      <div>
        <strong>총 도전 :</strong>
        <span className="fs-1">{total}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>맞춘 수 :</strong> <span className="fs-1">{correct}</span>
      </div>
      <ProgressBar key={quiz_id}>
        <ProgressBar
          max={total || 1}
          now={correct}
          label={`${hitRatio}%`}
          variant="success"
          key={1}
        />
        <ProgressBar max={total || 1} now={wrong} key={2} variant="danger" />
      </ProgressBar>
    </Container>
  );
};

export default withAuth(HitRecord);
