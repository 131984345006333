import styled from "@emotion/styled";

const Wrapper = styled.div``;

const TermsOfService: React.FC = () => {
  return (
    <Wrapper>
      <p>
        아래 약관은 과학기술정보통신부고시 제2022-66호 디지털콘텐츠
        표준약관(https://www.law.go.kr/행정규칙/디지털콘텐츠표준약관/(2022-66,20221122))과
        동일합니다.
      </p>
      <h1>제1장 총칙</h1>
      <article>
        <h5>제1조(목적)</h5>
        <p>
          이 약관은 사이버몰 이용계약, 디지털콘텐츠 및 온라인 서비스
          이용계약에서 사업자와 이용자의 권리 및 의무 등에 관한 사항을 규정함을
          목적으로 합니다.
        </p>
      </article>
      <article>
        <h5>제2조(정의)</h5>
        <p>이 약관에서 사용하는 용어의 뜻은 다음과 같습니다.</p>
        <ol>
          <li>
            "사이버몰"이라 함은 디지털콘텐츠 또는 온라인 서비스(이하
            "디지털콘텐츠등"이라 합니다)를 거래할 수 있는 가상의 영업장을
            말합니다.
          </li>
          <li>
            "이용자"라 함은 사업자가 제공하는 디지털콘텐츠등을 이용하는 자를
            말합니다.
          </li>
          <li>
            "회원"이라 함은 사업자와 사이버몰 이용계약을 체결하고, 회원
            아이디(ID)를 부여받은 이용자를 말합니다.
          </li>
          <li>"비회원"이라 함은 회원이 아닌 이용자를 말합니다.</li>
          <li>
            "소비자"라 함은 「전자상거래 등에서의 소비자보호에 관한
            법률」제2조제5호에서 정한 자를 말합니다.
          </li>
          <li>"이용사업자"라 함은 소비자가 아닌 이용자를 말합니다.</li>
          <li>
            "디지털콘텐츠"라 함은 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및
            영상 등(이들의 복합체를 포함합니다)의 자료 또는 정보로서 그 보존 및
            이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것을
            말합니다.
          </li>
          <li>
            "온라인 서비스"라 함은 사업자가 사이버몰에서 이용자에게 제공하는
            서비스를 말합니다.
          </li>
          <li>
            "아이디(ID)"라 함은 사이버몰에서 회원을 식별하기 위한 문자 또는 숫자
            등의 조합을 말합니다.
          </li>
          <li>
            "비밀번호(PASSWORD)"라 함은 회원임을 확인하기 위한 것으로써 아이디와
            합치되는 문자 또는 숫자 등의 조합 또는 생체인식정보 등을 말합니다.
          </li>
          <li>
            "포인트"라 함은 이용자가 디지털콘텐츠등의 대금으로 지급할 수 있는
            전자결제수단으로써 사업자가 발행한 것을 말합니다.
          </li>
          <li>
            "계속거래"라 함은 1개월 이상에 걸쳐 계속적으로 또는 부정기적으로
            디지털콘텐츠등을 제공하는 계약으로 중도에 해지할 경우 대금 환급의
            제한 또는 위약금에 관한 약정이 있는 거래를 말합니다.
          </li>
        </ol>
        <article>
          <h5>제3조(약관의 변경 등)</h5>
          <ol>
            <li>
              사업자는 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에
              관한 법률」, 「약관의 규제에 관한 법률」 등 관련법령을 위반하지
              않는 범위에서 이 약관을 변경할 수 있습니다.
            </li>
            <li>
              제1항에 따라 약관을 변경할 경우, 사업자는 시행일 및 변경사유를
              명시하여 개정 약관(현행 약관과의 비교표를 포함합니다)을 사이버몰
              초기화면에서 다음 각 호에서 정하는 기간 및 시행일로부터 상당한
              기간 동안 공지합니다. 다만, 정당한 사유가 있는 경우, 개정약관 등을
              확인할 수 있는 링크 등을 제공할 수 있습니다.
              <ol>
                <li>
                  변경하고자 하는 약관의 내용이 중대하거나 회원에게 불리한 경우
                  : 시행일로부터 30일 전부터 시행일까지
                </li>
                <li>
                  제1호에 해당하지 않는 경우 : 시행일로부터 7일 전부터
                  시행일까지
                </li>
              </ol>
            </li>
            <li>
              제1항에 따라 약관을 변경할 경우에 사업자는 회원에게 다음 각 호에서
              정하는 기한까지 변경 약관(현행 약관과의 비교표를 포함합니다) 및
              변경사유를 전자우편주소로 전송하고, 동의를 받습니다.
              <ol>
                <li>
                  변경하고자 하는 약관의 내용이 중대하거나 회원에게 불리한 경우
                  : 시행일로부터 30일 전까지
                </li>
                <li>제1호에 해당하지 않는 경우 : 시행일로부터 7일 전까지</li>
              </ol>
            </li>
            <li>
              변경 약관은 제3항에 따라 동의한 회원에게 적용됩니다. 다만,
              사업자가 제3항에 따라 회원에게 고지하면서 『이 기한 내에 변경
              약관의 적용에 대해 거절의 의사를 표시하지 않으면 동의한 것으로
              간주합니다』라는 사실을 명확히 표시한 경우에 그 기한까지 거절의
              의사를 표시하지 않은 회원에게도 적용됩니다.
            </li>
            <li>
              사업자는 변경 약관의 적용을 거절한 회원에게 변경전 약관을
              적용합니다. 다만, 변경전 약관을 적용할 수 없는 정당한 사유 또는
              불가피한 사유가 있는 경우에는 그러하지 않습니다.
            </li>
            <li>
              제5항단서의 경우에 회원은 사이버몰 이용계약 또는 디지털콘텐츠등의
              이용계약을 해지할 수 있습니다. 이 경우에 사업자는 회원에게 위약금
              등을 청구하지 않습니다.
            </li>
            <li>
              제6항의 경우, 사업자는 회원이 입은 손해 또는 손실을 배상 또는
              보상합니다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는
              그러하지 않습니다.
              <ol>
                <li>
                  사업자에게 책임이 없는 경우. 다만, 손실보상은 제외합니다.
                </li>
                <li>관련 법령의 제ㆍ개정에 따른 약관변경의 경우</li>
              </ol>
            </li>
          </ol>
        </article>
      </article>
      <article>
        <h5>제4조(약관의 해석 등)</h5>
        <ol>
          <li>
            사업자는 신의성실의 원칙에 따라 공정하게 이 약관을 해석하며,
            이용자에 따라 다르게 해석하지 않습니다.
          </li>
          <li>
            사업자는 이 약관의 뜻이 명백하지 아니한 경우에 이용자에게 유리하게
            해석합니다.
          </li>
          <li>
            이 약관에서 정하지 아니한 사항에 대해서는 「콘텐츠산업진흥법」,
            「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한
            법률」「개인정보 보호법」 등 관련법 또는 사업자와 이용자간 합의에서
            정한 바에 따릅니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제5조(개별 약정의 우선 등)</h5>
        <ol>
          <li>
            사업자와 이용자가 약관의 내용과 다르게 합의한 사항이 있을 때에는 그
            합의 사항은 이 약관보다 우선하여 적용됩니다.
          </li>
          <li>
            제1항에 따른 개별 약정이 「콘텐츠산업진흥법」, 「전자상거래 등에서의
            소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련법령의
            강행규정을 위반하여 무효인 경우에 이 약관에서 정한 바에 따릅니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제6조(신의성실의 원칙)</h5>
        <p>
          사업자와 이용자는 상대방의 신의에 좇아 성실하게 사이버몰 및
          디지털콘텐츠등 이용계약에 따른 권리를 행사하고, 의무를 이행합니다.
        </p>
      </article>
      <h1>제2장 사이버몰 이용계약 및 개인정보보호</h1>
      <h3>제1절 사이버몰 이용계약</h3>
      <article>
        <h5>제7조(사업자의 관리의무)</h5>
        <p>
          사업자는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
          「개인정보 보호법」 등 관련 법률에 따라 사이버몰의 안정성 유지 및
          이용자의 개인정보보호 등을 위한 보안시스템을 구축하고, 운영합니다.
        </p>
      </article>
      <article>
        <h5>제8조(허위정보등의 제공 등 및 이용계약의 성립)</h5>
        <ol>
          <li>
            이용자가 사실과 다르게 개인정보를 제공하거나 타인의 정보를 제공하여
            사이버몰 이용을 위해 청약한 경우 또는 청소년이 이용할 수 없는
            사이버몰 이용을 위해 청약한 경우, 사업자는 승낙하지 않거나 승낙을
            유보할 수 있습니다.
          </li>
          <li>
            사업자의 승낙이 수신확인통지형태로 이용자에게 도달한 시점에 사이버몰
            이용계약이 성립한 것으로 봅니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제9조(미성년자 등의 회원가입과 법정대리인 등의 취소 등)</h5>
        <ol>
          <li>
            만 19세 미만의 이용자가 법정대리인의 동의를 받지 아니하고,
            사이버몰의 회원으로 가입을 한 경우에 이용자ㆍ법정대리인 또는
            승계인은 사이버몰 이용계약을 취소할 수 있습니다. 다만, 「민법」 등에
            따라 미성년자가 취소할 수 없는 경우에는 그러하지 않습니다.
          </li>
          <li>
            제1항본문의 경우에 사업자는 이용자의 법정대리인이 추인하기 전까지
            사이버몰 이용계약을 철회할 수 있습니다. 다만, 사업자가 회원이
            미성년자라는 사실을 알았을 경우에는 철회할 수 없습니다.
          </li>
          <li>
            제1항 또는 제2항에 따라 사이버몰 이용계약이 취소 또는 철회된 경우에
            사업자는 회원으로부터 받은 대금을 환급하며, 위약금 등을 청구하지
            않습니다. 다만, 사업자가 악의인 경우에 대금을 받은 날로부터 환급할
            때까지의 기간에 대한 이자를 더하여 환급하며, 회원에게 손해가 있는
            경우에 그 손해에 대해 배상하여야 합니다.
          </li>
          <li>
            제1항 또는 제2항에 따라 사이버몰 이용계약이 취소 또는 철회된 경우에
            사업자는 그 회원의 정보를 지체없이 삭제합니다. 다만, 「전자상거래
            등에서의 소비자보호에 관한 법률」 등에 따라 법정기간 동안 정보를
            보존하여야 하는 경우에는 그러하지 않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제10조(아이디 및 비밀번호 관리의무 등)</h5>
        <ol>
          <li>
            회원은 아이디와 비밀번호를 선량한 관리자의 주의의무로 관리하며,
            제3자에게 이용허락할 수 없습니다. 다만, 사업자가 동의한 경우에는
            그러하지 않습니다.
          </li>
          <li>
            제1항본문을 위반한 경우에 사업자는 회원에게 발생한 손해에 대해
            책임을 지지 않습니다. 다만, 회원의 손해발생에 대해 사업자에게 책임이
            있는 경우에 사업자는 그 범위내에서 책임을 부담합니다.
          </li>
          <li>
            회원은 아이디 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을
            알게 된 경우에 즉시 사업자에게 그 사실을 통지하고, 사업자의 안내에
            따라야 합니다.
          </li>
          <li>
            제3항의 경우, 회원이 사업자에게 그 사실을 통지하지 않거나 통지한
            경우에도 사업자의 안내에 따르지 않아 발생한 회원의 손해에 대해
            사업자는 책임을 지지 않습니다. 다만, 사업자에게 책임있는 경우에는 그
            범위내에서 책임을 부담합니다.
          </li>
          <li>
            회원은 도용 등을 방지하기 위해 주기적으로 비밀번호를 변경하며,
            사업자는 회원에게 비밀번호의 변경을 권고할 수 있습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제11조(회원에 대한 통지)</h5>
        <ol>
          <li>
            개별 회원에 대한 통지를 하는 경우, 사업자는 회원이 지정한
            전자우편주소로 그 내용을 전송합니다.
          </li>
          <li>
            전체 회원에게 통지할 경우, 사업자는 회원이 쉽게 알 수 있도록
            사이버몰의 게시판 등에 7일 이상 그 내용을 게시합니다. 다만, 회원의
            사이버몰 이용계약과 관련하여 영향을 미치는 사항에 대하여는 제1항에
            따른 통지를 합니다.
          </li>
          <li>
            제1항 및 제2항단서에도 불구하고, 회원이 지정한 전자우편주소가 없는
            경우 또는 회원이 지정한 전자우편주소로 통지할 수 없는 경우에
            사업자는 제2항본문에서 정한 방법으로 통지할 수 있습니다. 이 경우,
            사업자가 회원의 전화번호를 알고 있는 때에는 그 내용을 확인할 수 있는
            방법을 전화 또는 문자로 안내합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제12조(사이버몰의 이용시간 및 일시 이용 중지 등)</h5>
        <ol>
          <li>
            사업자는 이용자가 연중 및 24시간 사이버몰을 이용할 수 있도록
            사이버몰을 운영합니다.
          </li>
          <li>
            사업자는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
            또는 운영상 상당한 이유가 있는 경우 사이버몰 이용을 일시적으로
            중지할 수 있습니다.
          </li>
          <li>
            제2항의 경우, 사업자는 제11조에 따라 회원에게 통지합니다. 다만,
            사업자가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에
            통지할 수 있습니다.
          </li>
          <li>
            사업자는 사이버몰 이용이 일시적으로 중단됨으로 인하여 이용자에게
            발생한 손해에 대해 배상합니다. 다만, 사업자가 고의 또는 과실이
            없음을 증명한 경우에는 그러하지 않습니다.
          </li>
          <li>
            사업자는 원활한 사이버몰 운영을 위해 필요한 경우에 정기점검을 실시할
            수 있으며, 정기점검일로부터 최소 1주일 이전에 정기점검시간을
            사이버몰 초기화면에 게시합니다.
          </li>
          <li>
            사업종목의 전환ㆍ폐업ㆍ파산 등의 이유로 사업자가 사이버몰을 운영할
            수 없는 경우에 사업자는 제11조에 따라 이용자에게 통지하고,
            보상기준에 따라 이용자에게 보상합니다. 다만, 사업자가 보상기준을
            고지하지 아니하거나 고지한 보상기준에 대해 이용자가 거절한 경우에
            이용자와 합의하여 보상합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제13조(포인트)</h5>
        <ol>
          <li>
            사업자는 포인트를 이용자에게 판매하거나 무상으로 이용자에게 포인트를
            제공할 수 있습니다.
          </li>
          <li>
            이용자는 유효기간 내에 포인트를 사용하며, 유효기간이 경과한 포인트는
            소멸합니다.
          </li>
          <li>
            포인트의 유효기간은 다음 각 호의 구분에 따릅니다.
            <ol>
              <li>유상포인트 : 구입일로 5년</li>
              <li>무상포인트 : 사업자가 표시한 기간</li>
            </ol>
          </li>
          <li>
            이용자가 디지털콘텐츠등의 대금을 포인트로 지급할 경우에 사업자는
            무상포인트를 먼저 차감하며, 잔여 금액에 대해 유상포인트를
            차감합니다.
          </li>
          <li>
            사업자가 포인트정책을 변경하거나 폐지한 경우에 다음 각 호에서 정한
            바에 따라 잔여 포인트에 대해 환금 또는 보상합니다. 다만,
            무상포인트의 경우에 사업자가 미사용한 포인트에 대해 보상하지 않음을
            명확히 공지한 경우에는 그러하지 않습니다.
            <ol>
              <li>
                유상포인트 : 구입가격에 잔여 포인트의 비율을 곱하여 산정한 금액
              </li>
              <li>
                무상포인트 : 사업자가 공지한 보상기준. 다만, 사업자가 보상기준을
                공지하지 않은 경우에 유상포인트의 판매가격을 무상포인트에
                적용하여 제1호에 따라 보상합니다.
              </li>
            </ol>
          </li>
        </ol>
      </article>
      <article>
        <h5>제14조(정보의 제공 및 광고정보의 전송 등)</h5>
        <ol>
          <li>
            사업자는 이용자가 사이버몰 또는 디지털콘텐츠등의 이용에 필요한
            정보를 사이버몰의 게시판 등에 게시하거나 비상업용 정보에 한해
            이용자의 전자우편주소로 전송 등의 방법으로 이용자에게 제공할 수
            있습니다. 이 경우에 이용자는 언제든지 수신 거절할 수 있으며,
            사업자는 수신을 거절한 이용자에게 전화ㆍ전자우편 등으로 정보를
            제공하지 않습니다.
          </li>
          <li>
            사업자는 수신동의한 이용자에 한해 전자우편 또는 휴대전화 문자 서비스
            등을 이용하여 영리목적의 광고성 정보를 전송할 수 있습니다. 다만,
            디지털콘텐츠등의 거래관계를 통하여 이용자로부터 직접 연락처를 수집한
            사업자가 그 거래가 종료한 날로부터 6개월 이내에 자신이 처리하고
            이용자와 거래한 것과 같은 종류의 디지털콘텐츠등에 대한 영리목적의
            광고성 정보를 전송하는 경우에는 수신동의하지 않은 이용자에게 그
            정보를 전송할 수 있습니다.
          </li>
          <li>
            제2항에도 불구하고, 오후 9시부터 그 다음 날 8시까지의 시간에
            영리목적의 광고성 정보를 전송하려는 사업자는 이용자로부터 별도의
            사전동의를 받습니다. 다만, 전자우편으로 정보를 전송하는 경우에는
            그러하지 않습니다.
          </li>
          <li>
            제2항의 경우에 이용자는 그 동의를 언제든지 철회할 수 있습니다.
          </li>
          <li>
            사업자는 제1항 또는 제4항에 따라 수신거절 또는 동의철회를 하고자
            하는 이용자가 전화요금 등의 비용을 부담하지 않고 수신을 거절하거나
            동의를 철회할 수 있도록 필요한 조치를 취합니다.
          </li>
          <li>
            사업자는 제1항 또는 제4항에 따라 수신을 거절하거나 동의를 철회한
            이용자에게 그 처리결과를 통지합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제15조(저작권등의 귀속 등)</h5>
        <ol>
          <li>
            사업자가 창작한 저작물에 대한 저작권 기타 지식재산권(이하
            "저작권등"이라 합니다)은 사업자가 갖습니다.
          </li>
          <li>
            이용자는 사업자가 제공하는 디지털콘텐츠등을 이용함으로써 얻은 정보에
            대해 사업자의 사전승낙 없이 복제, 전송, 출판, 배포 등을 하거나
            제3자에게 이를 하도록 하지 않습니다. 다만, 「저작권법」등에 따라
            허용되는 경우에는 그러하지 않습니다.
          </li>
          <li>
            사업자가 이용자의 저작물을 사용하는 경우에 이용자의 사전허락을
            받습니다. 다만, 「저작권법」등에 따라 허용되는 경우에는 그러하지
            않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제16조(불법정보등의 게시금지 및 삭제)</h5>
        <ol>
          <li>
            이용자는 사이버몰의 게시판 등을 이용하여 다음 각 호의 어느 하나에
            해당하는 불법정보를 게시하거나 다른 이용자에게 제공하지 않습니다.
            <ol>
              <li>
                음란한 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 배포ㆍ판매ㆍ임대하거나
                공공연하게 전시하는 내용의 정보
              </li>
              <li>
                사람을 비방할 목적으로 공공연하게 사실이나 거짓의 사실을
                드러내어 타인의 명예를 훼손하는 내용의 정보
              </li>
              <li>
                공포심이나 불안감을 유발하는 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을
                반복적으로 상대방에게 도달하도록 하는 내용의 정보
              </li>
              <li>
                정당한 사유 없이 정보통신시스템, 데이터 또는 프로그램 등을
                훼손ㆍ멸실ㆍ변경ㆍ위조하거나 그 운용을 방해하는 내용의 정보
              </li>
              <li>
                「청소년 보호법」에 따른 청소년유해매체물로서 상대방의 연령
                확인, 표시의무 등 법령에 따른 의무를 이행하지 아니하고 영리를
                목적으로 제공하는 내용의 정보
              </li>
              <li>법령에 따라 금지되는 사행행위에 해당하는 내용의 정보</li>
              <li>
                개인정보 보호에 관한 법령을 위반하여 개인정보를 거래하는 내용의
                정보
              </li>
              <li>
                총포ㆍ화약류(생명ㆍ신체에 위해를 끼칠 수 있는 폭발력을 가진
                물건을 포함합니다)를 제조할 수 있는 방법이나 설계도 등의 정보
              </li>
              <li>
                법령에 따라 분류된 비밀 등 국가기밀을 누설하는 내용의 정보
              </li>
              <li>「국가보안법」에서 금지하는 행위를 수행하는 내용의 정보</li>
              <li>법률에서 금지하는 재화 또는 서비스에 대한 광고성 정보</li>
              <li>
                그 밖에 범죄를 목적으로 하거나 교사(敎唆) 또는 방조하는 내용의
                정보
              </li>
            </ol>
          </li>
          <li>
            이용자는 사이버몰의 게시판 등을 이용하여
            「청소년보호법」제2조제3호에 따른 청소년유해매체물 또는 이에
            접속하거나 이용할 수 있는 정보를 게시하지 않습니다. 다만,
            「청소년보호법」에 따라 청소년이 아닌 이용자만이 이용할 수 있는
            게시판 등에의 게시등은 그러하지 않습니다.
          </li>
          <li>
            이용자는 영리목적의 광고성 정보를 사이버몰의 게시판 등에 게시하려면
            사업자의 사전 동의를 받아야 합니다. 다만, 별도의 권한 없이 누구든지
            쉽게 접근하여 글을 게시할 수 있는 게시판의 경우에는 사전 동의를 받지
            않고 게시할 수 있습니다.
          </li>
          <li>
            제3항에도 불구하고, 사업자가 명시적으로 게시 거부의사를 표시하거나
            사전 동의를 철회한 경우에 이용자는 영리목적의 광고성 정보를 게시하지
            않습니다.
          </li>
          <li>
            제1항부터 제4항까지를 위반한 정보에 대해 사업자는 그 정보를 게시한
            자에게 통지하지 않고, 그 정보를 삭제할 수 있습니다. 이 경우에
            사업자는 그 정보의 삭제에 대해 책임을 지지 않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제17조(임의의 임시조치)</h5>
        <ol>
          <li>
            사업자는 사이버몰의 게시판 등에 게시된 정보가 사생활 침해 또는
            명예훼손 등 타인의 권리를 침해한다고 인정되면 임의로 해당 정보에
            대한 접근을 임시적으로 차단하는 조치(이하 "임시조치"라 합니다)를
            취할 수 있습니다.
          </li>
          <li>
            제1항에 따른 임시조치를 취한 사업자는 임시조치의 사실을 그 정보가
            게시된 게시판 등에 공시하는 등의 방법으로 이용자가 알 수 있게
            합니다.
          </li>
          <li>제1항에 따른 임시조치의 기간은 30일 이내로 합니다.</li>
        </ol>
      </article>
      <article>
        <h5>제18조(정보의 삭제요청 등)</h5>
        <ol>
          <li>
            사이버몰을 통하여 일반에게 공개를 목적으로 제공된 정보로 사생활
            침해나 명예훼손 등 타인의 권리가 침해된 경우, 그 침해를 받은 자는
            해당 정보를 처리한 사업자에게 침해사실을 소명하여 그 정보의 삭제
            또는 반박내용의 게재(이하 "삭제등"이라 합니다)를 요청할 수 있습니다.
          </li>
          <li>
            사업자는 제1항에 따른 해당 정보의 삭제등을 요청받으면 지체 없이
            삭제ㆍ임시조치 등의 필요한 조치를 하고, 즉시 신청인 및
            정보게재자에게 알립니다. 이 경우, 사업자는 필요한 조치를 한 사실을
            해당 게시판에 공시하는 등의 방법으로 이용자가 알 수 있도록 합니다.
          </li>
          <li>
            사업자는 사이버몰에 「정보통신망 이용촉진 및 정보보호 등에 관한
            법률」제42조에 따른 표시방법을 지키지 아니하는 청소년유해매체물이
            게시되어 있거나 같은 법 제42조의2에 따른 청소년 접근을 제한하는 조치
            없이 청소년유해매체물을 광고하는 내용이 전시되어 있는 경우에는 지체
            없이 그 내용을 삭제합니다.
          </li>
          <li>
            제1항에 따른 정보의 삭제요청에도 불구하고, 사업자는 권리의 침해
            여부를 판단하기 어렵거나 이해당사자 간에 다툼이 예상되는 경우에
            제17조에 따른 임시조치를 취할 수 있습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제19조(회원탈퇴 및 자격 상실 등)</h5>
        <ol>
          <li>
            회원은 사업자에게 언제든지 탈퇴를 요청할 수 있으며, 사업자는 즉시
            회원탈퇴를 처리합니다. 다만, 정당한 사유가 있는 경우에 탈퇴처리를
            유보할 수 있습니다.
          </li>
          <li>
            회원이 사이버몰을 이용하면서 다음 각 호의 어느 하나에 해당하는
            행위를 한 경우, 사업자는 회원자격 또는 디지털콘텐츠등의 이용을
            일시적으로 제한하거나 정지시킬 수 있습니다.
            <ol>
              <li>
                다른 이용자의 사이버몰 이용을 방해하거나 정보를 도용하는 등
                사이버몰 운영 질서를 침해하는 행위
              </li>
              <li>계약 및 약관에서 금지하는 행위</li>
              <li>법령ㆍ선량한 풍속 기타 사회질서에 반하는 행위</li>
            </ol>
          </li>
          <li>
            회원자격이 제한 또는 정지된 적이 있는 회원이 제2항에서 정한 행위를
            2회 이상 하거나 30일 이내에 회원자격 제한 또는 정지사유가 시정되지
            아니한 경우에 사업자는 그 회원의 자격을 상실시킬 수 있습니다.
          </li>
          <li>
            사업자가 회원자격을 상실시킨 경우에 회원등록을 말소합니다. 다만,
            정당한 사유가 있는 경우에 회원등록의 말소를 유보할 수 있습니다.
          </li>
          <li>
            사업자는 제2항 또는 제3항에 따라 회원자격의 제한 또는 정지, 자격상실
            등의 조치를 취한 경우에 제11조에 따라 그 회원에게 그 사실 및 사유를
            지체없이 통지하고, 다음 각 호에 따른 기간 동안 소명기회를
            부여합니다.
            <ol>
              <li>회원자격의 제한 또는 정지 등 : 1주일 이상</li>
              <li>자격상실 : 30일 이상</li>
            </ol>
          </li>
          <li>
            사업자는 제2항 또는 제3항에 따른 회원자격의 제한 또는 정지, 자격상실
            등과 별도로 그 회원의 행위로 인해 손해를 입은 경우에 배상을 청구할
            수 있습니다. 다만, 회원이 고의 또는 과실없음을 증명한 경우에는
            그러하지 않습니다.
          </li>
        </ol>
      </article>
      <h3>제2절 개인정보보호</h3>
      <article>
        <h5>제20조(개인정보보호)</h5>
        <ol>
          <li>
            사업자는 개인정보의 수집ㆍ이용, 제공 등 개인정보의 처리 및 보호와
            관련하여 「개인정보 보호법」을 준수합니다.
          </li>
          <li>
            개인정보의 열람, 정정, 처리정지 등 이용자의 권리는 「개인정보
            보호법」에 따라 보호받습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제21조(접근권한에 대한 동의)</h5>
        <ol>
          <li>
            사업자는 디지털콘텐츠등을 이용자에게 제공하기 위하여 이용자의
            이동통신단말장치 내에 저장되어 있는 정보 및 이동통신단말장치에
            설치된 기능에 대하여 접근할 수 있는 권한(이하 "접근권한"이라
            합니다)이 필요한 경우에 다음 각 호의 사항을 이용자가 명확하게 인지할
            수 있도록 알리고, 이용자의 동의를 받습니다.
            <ol>
              <li>
                디지털콘텐츠등을 제공하기 위하여 반드시 필요한 접근권한인 경우
                <ol>
                  <li>접근권한이 필요한 정보 및 기능의 항목</li>
                  <li>접근권한이 필요한 이유</li>
                </ol>
              </li>
              <li>
                디지털콘텐츠등을 제공하기 위하여 반드시 필요한 접근권한이 아닌
                경우
                <ol>
                  <li>접근권한이 필요한 정보 및 기능의 항목</li>
                  <li>접근권한이 필요한 이유</li>
                  <li>접근권한 허용에 대하여 동의하지 아니할 수 있다는 사실</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            사업자는 해당 디지털콘텐츠등을 제공하기 위하여 반드시 필요하지
            아니한 접근권한을 설정하는 데 이용자가 동의하지 아니한다는 이유로
            이용자에게 해당 디지털콘텐츠등의 제공을 거부하지 않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제22조(손해배상책임)</h5>
        <ol>
          <li>
            이용자는 사업자가 「개인정보 보호법」을 위반한 행위로 손해를 입으면
            사업자에게 손해배상을 청구할 수 있습니다. 이 경우, 사업자는 고의
            또는 과실이 없음을 증명한 경우에는 그러하지 않습니다.
          </li>
          <li>
            사업자의 고의 또는 중대한 과실로 인하여 개인정보가
            분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손된 경우로서 이용자에게 손해가
            발생한 때에는 이용자는 그 손해액의 3배를 넘지 아니하는 범위에서
            손해배상을 청구할 수 있습니다. 다만, 사업자가 고의 또는 중대한
            과실이 없음을 증명한 경우에는 그러하지 않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제23조(법정손해배상의 청구)</h5>
        <p>
          제22조제1항에도 불구하고, 이용자는 사업자의 고의 또는 과실로 인하여
          개인정보가 분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손된 경우에는 300만원
          이하의 범위에서 상당한 금액을 손해액으로 하여 배상을 청구할 수
          있습니다. 다만, 사업자가 고의 또는 과실 없음을 증명한 경우에는
          그러하지 않습니다.
        </p>
      </article>
      <h1>제3장 디지털콘텐츠 또는 온라인 서비스 이용계약</h1>
      <h3>제1절 디지털콘텐츠등의 제공 및 대금의 지급</h3>
      <article>
        <h5>제24조(계약서의 교부)</h5>
        <p>
          사업자는 디지털콘텐츠등의 이용계약이 체결된 경우에 다음 각 호의 사항이
          기재된 계약서(전자문서를 포함합니다. 이하 같습니다)를 디지털콘텐츠등을
          제공할 때까지 이용자에게 발급합니다. 다만, 계약을 체결한 이용자가
          동의한 경우 또는 사업자가 책임없는 사유로 이용자의 주소(전자우편주소를
          포함합니다)를 알 수 없어 이용자에게 계약서를 발급할 수 없는 경우에는
          디지털콘텐츠등을 제공받는 자에게 계약서를 발급할 수 있습니다.
        </p>
        <ol>
          <li>
            디지털콘텐츠등의 제작자ㆍ공급자 및 사업자의 상호, 대표자의
            성명ㆍ주소 및 전화번호 등. 다만, 제작자의 경우에는 성명(법인인
            경우에 상호 및 대표자의 성명을 말합니다)으로 한정합니다.
          </li>
          <li>디지털콘텐츠등의 명칭ㆍ종류 및 내용</li>
          <li>
            디지털콘텐츠등의 정보에 관한 사항. 이 경우, 디지털콘텐츠에 표시된
            기재로 계약서에의 기재를 대신할 수 있습니다.
          </li>
          <li>
            디지털콘텐츠등의 가격(가격이 결정되어 있지 아니한 경우에는 가격을
            결정하는 구체적인 방법)과 그 지급방법 및 지급시기
          </li>
          <li>디지털콘텐츠등의 제공방법 및 제공시기</li>
          <li>
            청약의 철회 및 계약의 해제(이하 "청약철회등"이라 합니다)의
            기한ㆍ행사방법 및 효과에 관한 사항(청약철회등의 권리를 행사하는 데에
            필요한 서식을 포함합니다)
          </li>
          <li>
            디지털콘텐츠등의 교환ㆍ반품ㆍ보증과 그 대금 환불 및 환불의 지연에
            따른 배상금 지급의 조건ㆍ절차
          </li>
          <li>디지털콘텐츠등의 전송ㆍ설치 등을 할 때 필요한 기술적 사항</li>
          <li>
            이용자피해보상의 처리, 디지털콘텐츠등에 대한 불만 처리 및 이용자와
            사업자 사이의 분쟁 처리에 관한 사항
          </li>
          <li>
            거래에 관한 약관(그 약관의 내용을 확인할 수 있는 방법을 포함합니다)
          </li>
          <li>
            디지털콘텐츠등의 가격 외에 교환ㆍ반품 비용 등 이용자가 추가로
            부담하여야 할 사항이 있는 경우 그 내용 및 금액
          </li>

          <li>
            판매일시, 판매지역, 판매수량, 인도지역 등 판매조건과 관련하여 제한이
            있는 경우 그 내용
          </li>
          <li>
            계속거래에 해당하는 경우, 계약 해지와 그 행사방법ㆍ효과에 관한 사항
            및 해지권의 행사에 필요한 서식
          </li>
        </ol>
      </article>
      <article>
        <h5>제25조(대금의 지급과 과오금의 환급 등)</h5>
        <ol>
          <li>
            이용자는 사업자가 대금결제화면에서 표시한 지급방법에 따라
            디지털콘텐츠등의 이용계약에 따른 대금을 지급합니다. 이 경우,
            사업자는 이용자가 선택한 대금지급방법에 대해 어떠한 명목의 수수료를
            추가로 징수하지 않습니다.
          </li>
          <li>
            사업자는 제1항에 따른 전자적 대금지급이 이루어지는 경우, 다음 각
            호의 사항에 대하여 명확히 고지하고, 고지한 사항에 대해 이용자가
            확인하여 동의 여부를 선택할 수 있도록 전자적 대금 결제창을
            제공합니다.
            <ol>
              <li>디지털콘텐츠등의 내용 및 종류</li>
              <li>디지털콘텐츠등의 가격</li>
              <li>온라인 서비스의 제공기간</li>
            </ol>
          </li>
          <li>
            사업자는 제1항에 따른 전자적 대금지급이 이루어진 경우에는 전자문서의
            송신 등의 방법으로 이용자에게 그 사실을 알리고, 언제든지 이용자가
            전자적 대금지급과 관련한 자료를 열람할 수 있게 합니다.
          </li>
          <li>
            이용자가 제1항에 따라 대금을 지급함에 있어서 과오금을 지급한 경우,
            사업자는 대금지급방법과 동일한 방법으로 과오금을 환급합니다. 다만,
            동일한 방법으로 과오금의 환급이 불가능할 때는 즉시 이를 알리고,
            이용자가 선택한 방법으로 환급합니다.
          </li>
          <li>
            사업자의 책임있는 사유로 과오금이 발생한 경우, 사업자는 과오금
            전액과 그 수령일로부터 환급할 때까지의 기간에 대해 연 6%(이 보다
            높은 이자율을 약정한 경우에는 그 약정이자율)를 곱하여 산정한 이자를
            더하여 환급합니다. 다만, 이용자의 책임있는 사유로 과오금이 발생한
            경우에 사업자는 과오금을 환급하는데 소요되는 비용을 합리적인 범위
            내에서 공제하고, 환급할 수 있습니다.
          </li>
          <li>
            사업자는 이용자의 과오금환급청구를 거절할 경우에 과오금이 없음을
            증명합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제26조(디지털콘텐츠등의 제공 등)</h5>
        <ol>
          <li>
            사업자는 디지털콘텐츠의 제공방식 또는 이용방식에 따라 이용자가
            디지털콘텐츠를 이용할 수 있도록 다음 각 호에 따른 조치를 취합니다.
            <ol>
              <li>
                전송방식 : 이용자가 지정한 전자우편주소 또는 사이버몰 내
                이용자의 계정으로 제공
              </li>
              <li>
                다운로드 방식 : 이용자가 디지털콘텐츠를 다운받을 수 있는 조치
              </li>
              <li>
                스트리밍 방식 : 이용자가 디지털콘텐츠를 이용할 수 있는 조치
              </li>
            </ol>
          </li>
          <li>
            사업자는 이용자가 온라인 서비스 이용계약에 따라 온라인 서비스를
            이용할 수 있도록 해당 서비스를 제공합니다.
          </li>
          <li>
            사업자는 소비자가 청약을 한 날부터 7일 이내에 제1항 또는 제2항에
            따른 조치를 하여야 하고, 소비자가 디지털콘텐츠등을 제공받기 전에
            미리 재화등의 대금을 전부 또는 일부 지급한 경우(이하 "선지급식
            거래"라 합니다)에는 소비자가 그 대금을 전부 또는 일부 지급한 날부터
            3영업일 이내에 제1항 또는 제2항에 따른 조치를 취합니다. 다만,
            소비자와 사업자 간에 디지털콘텐츠등의 제공시기에 관하여 따로 약정한
            것이 있는 경우에는 그러하지 않습니다.
          </li>
          <li>
            사업자는 청약을 받은 디지털콘텐츠등을 제공하기 곤란하다는 것을
            알았을 때에는 지체 없이 그 사유를 소비자에게 알리고, 선지급식 거래의
            경우에는 소비자가 그 대금의 전부 또는 일부를 지급한 날부터 3영업일
            이내에 환급하거나 환급에 필요한 조치를 취합니다.
          </li>
          <li>
            제4항에 따라 선지급식 거래에서 디지털콘텐츠등의 대금을 환급하거나
            환급에 필요한 조치를 취하여야 하는 경우에는 제35조제1항부터
            제4항까지를 준용합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제27조(디지털콘텐츠의 이용 및 업데이트)</h5>
        <ol>
          <li>
            이용자는 사업자가 제공한 디지털콘텐츠를 이 계약의 내용을 위반하여
            이용하지 않습니다.
          </li>
          <li>
            이용자는 사업자가 제공한 디지털콘텐츠에 대해 임의로 변경, 수정 등을
            하지 않습니다.
          </li>
          <li>
            이용자는 사업자의 동의없이 제3자에게 디지털콘텐츠를 제공하지
            않습니다.
          </li>
          <li>
            이용자는 자신의 이용을 위해 자기의 다른 정보처리기기에
            디지털콘텐츠를 복제할 수 있습니다.
          </li>
          <li>
            사업자는 이용자가 전송받거나 다운받은 디지털콘텐츠를 안정적으로 계속
            사용할 수 있도록 업데이트에 관한 다음 각 호의 정보를 제공합니다.
            다만, 디지털콘텐츠의 성질상 업데이트가 필요없는 경우에는 그러하지
            않습니다.
            <ol>
              <li>디지털콘텐츠의 업데이트 일자 및 버전</li>
              <li>업데이트의 내용</li>
              <li>업데이트에 필요한 정보처리기기의 용량</li>
              <li>
                디지털콘텐츠를 업데이트하지 않았을 경우에 발생하는 이용자의
                불이익. 다만, 불이익이 없는 경우에는 제외합니다.
              </li>
              <li>업데이트의 비용(유상인 경우로 한정합니다)</li>
              <li>그 밖에 업데이트에 관한 사항</li>
            </ol>
          </li>
          <li>
            제5항에 따른 디지털콘텐츠의 업데이트는 무료로 합니다. 다만, 정당한
            사유가 있는 경우에 사업자는 유료로 할 수 있습니다.
          </li>
          <li>
            디지털콘텐츠의 업데이트 여부는 이용자가 결정하며, 사업자는 이용자의
            동의없이 이용자의 정보처리기기에 저장된 디지털콘텐츠를 업데이트하지
            않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제28조(대금의 자동결제와 이용자에 대한 고지)</h5>
        <p>
          디지털콘텐츠등을 계속적으로 이용하는 계약에서 대금이 여러 번에 거쳐
          자동으로 결제되기로 약정한 경우, 사업자는 그 결제가 이루어지기 전에
          다음 각 호에서 정한 사항이 포함된 결제정보를 이용자에게 전자우편 또는
          문자메시지 등으로 알립니다.
        </p>
        <ol>
          <li>금액</li>
          <li>결제시기</li>
          <li>결제수단</li>
        </ol>
      </article>
      <article>
        <h5>제29조(디지털콘텐츠등의 이용계약의 자동갱신 등)</h5>
        <ol>
          <li>
            사업자는 디지털콘텐츠등의 무료이용기간이 경과하여 유료로 전환할
            경우, 유료전환 전에 이용자의 동의를 받습니다.
          </li>
          <li>
            사업자는 디지털콘텐츠등의 유료이용기간이 종료하여 이를 갱신하고자
            하는 경우, 사전에 이용자의 동의를 받습니다.
          </li>
          <li>
            사업자가 제1항 또는 제2항에 따라 이용자의 동의를 받지 못한 경우,
            디지털콘텐츠등의 이용계약은 무료이용기간의 경과 또는 계약기간의
            만료로 소멸됩니다.
          </li>
          <li>
            제1항 또는 제2항에 따른 이용자의 동의 여부에 관하여 다툼이 있는
            경우, 사업자가 이를 증명합니다.
          </li>
        </ol>
      </article>
      <h3>제2절 디지털콘텐츠등의 이용계약과 이용자의 청약철회등</h3>
      <article>
        <h5>제30조(조작실수방지절차의 마련과 이용자의 취소 등)</h5>
        <ol>
          <li>
            사업자는 이용자가 디지털콘텐츠등의 이용계약을 체결함에 있어 조작
            실수 등을 하지 않도록 필요한 절차를 마련하여 제공합니다.
          </li>
          <li>
            사업자가 제1항에 따른 절차를 제공하지 않아 이용자가 중요부분에 대한
            착오로 디지털콘텐츠등의 이용계약을 체결한 경우, 이용자는 그 계약을
            취소할 수 있습니다. 다만, 이용자에게 중대한 과실이 있는 경우에는
            그러하지 않습니다.
          </li>
          <li>
            이용자가 제2항에 따라 디지털콘텐츠등의 이용계약을 취소한 경우에
            사업자는 이용자가 지급한 대금을 환급하며, 이용자는 제공받은
            디지털콘텐츠를 삭제합니다. 다만, 제공받은 디지털콘텐츠를 삭제할 수
            없는 경우에 이용자는 이를 이용하지 않고, 제3자가 이용할 수 없게
            합니다.
          </li>
          <li>
            제3항에도 불구하고, 사업자가 정보통신망을 이용하여 직접
            디지털콘텐츠를 삭제할 수 있는 경우에는 사업자가 디지털콘텐츠를
            삭제할 수 있습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제31조(미성년자의 취소 등)</h5>
        <ol>
          <li>
            미성년자인 이용자가 법정대리인의 동의를 받지 않고, 디지털콘텐츠등의
            이용계약을 체결한 경우에 미성년자ㆍ법정대리인 또는 승계인(이하
            "미성년자등"이라 합니다)은 그 계약을 취소할 수 있습니다. 다만,
            「민법」 등에 따라 미성년자가 취소할 수 없는 경우에는 그러하지
            않습니다.
          </li>
          <li>
            사업자는 미성년자가 행위능력자가 된 후 그 자에게 1개월 이상의 기간을
            정하여 제1항본문의 계약에 대해 취소할 것인지 여부의 확답을 촉구할 수
            있습니다. 이 경우, 행위능력자로 된 자가 그 기간 내에 확답을 발송하지
            아니하면 그 계약을 취소할 수 없습니다.
          </li>
          <li>
            사업자는 미성년자가 행위능력자가 되지 못한 경우에 그의
            법정대리인에게 제2항의 촉구를 할 수 있습니다. 이 경우, 법정대리인이
            그 기간 내에 확답을 발송하지 않으면 그 계약을 취소할 수 없습니다.
          </li>
          <li>
            디지털콘텐츠등의 이용계약을 체결할 때에 이용자가 미성년자라는 사실을
            알지 못한 사업자는 추인이 있을 때까지 그 계약을 철회할 수 있습니다.
          </li>
          <li>
            디지털콘텐츠등의 이용계약이 제1항에 따라 취소되거나 제4항에 따라
            철회된 경우에 사업자는 미성년자가 지급한 대금을 환급하며, 미성년자는
            제공받은 디지털콘텐츠를 삭제합니다.
          </li>
          <li>
            제5항에도 불구하고, 사업자가 정보통신망을 이용하여 직접
            디지털콘텐츠를 삭제할 수 있는 경우에는 사업자가 디지털콘텐츠를
            삭제할 수 있습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제32조(소비자의 청약철회등)</h5>
        <ol>
          <li>
            사업자와 디지털콘텐츠등의 판매에 관한 계약을 체결한 소비자는 다음 각
            호의 기간(거래당사자가 다음 각 호의 기간보다 긴 기간으로 약정한
            경우에는 그 기간을 말합니다) 이내에 그 계약에 대해 청약철회등을 할
            수 있습니다.
            <ol>
              <li>
                제24조에 따른 계약서를 받은 날부터 7일. 다만, 계약서를 받은
                때보다 디지털콘텐츠등의 제공이 늦게 이루어진 경우에는
                디지털콘텐츠등을 제공받거나 디지털콘텐츠등의 제공이 시작된
                날부터 7일
              </li>
              <li>
                제24조에 따른 계약서를 받지 아니한 경우, 사업자의 주소 등이 적혀
                있지 아니한 계약서를 받은 경우 또는 사업자의 주소 변경 등의
                사유로 제1호의 기간에 청약철회등을 할 수 없는 경우에는 사업자의
                주소를 안 날 또는 알 수 있었던 날부터 7일
              </li>
              <li>
                거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여 소비자의
                청약철회등을 방해하는 행위 또는 청약철회등을 방해할 목적으로
                주소, 전화번호, 인터넷도메인 이름 등을 변경하거나 폐지하는
                행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일
              </li>
            </ol>
          </li>
          <li>
            소비자는 다음 각 호의 어느 하나에 해당하는 경우에는 사업자의 의사에
            반하여 제1항에 따른 청약철회등을 할 수 없습니다. 다만, 사업자가
            제6항에 따른 조치를 하지 아니하는 때에는 제2호부터 제5호까지에
            해당하는 경우에도 청약철회등을 할 수 있습니다.
            <ol>
              <li>
                소비자에게 책임이 있는 사유로 디지털콘텐츠가 멸실되거나 훼손된
                경우. 다만, 디지털콘텐츠의 내용을 확인하기 위하여 포장 등을
                훼손한 경우는 제외합니다.
              </li>
              <li>
                소비자의 사용 또는 일부 소비로 디지털콘텐츠의 가치가 현저히
                감소한 경우
              </li>
              <li>
                시간이 지나 다시 판매하기 곤란할 정도로 디지털콘텐츠의 가치가
                현저히 감소한 경우
              </li>
              <li>복제가 가능한 디지털콘텐츠의 포장을 훼손한 경우</li>
              <li>
                디지털콘텐츠등의 제공이 개시된 경우. 다만, 가분적
                디지털콘텐츠등의 경우에는 제공이 개시되지 아니한 부분에 대하여는
                그러하지 않습니다.
              </li>
              <li>
                소비자의 주문에 따라 개별적으로 생산되는 디지털콘텐츠등 또는
                이와 유사한 디지털콘텐츠등에 대하여 청약철회등을 인정하는 경우
                사업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에
                해당 거래에 대하여 별도로 그 사실을 고지하고 소비자의
                서면(전자문서를 포함합니다)에 의한 동의를 받은 경우
              </li>
            </ol>
          </li>
          <li>
            소비자는 제1항 및 제2항에도 불구하고 디지털콘텐츠등의 내용이
            표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 그
            디지털콘텐츠등을 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알
            수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다.
          </li>
          <li>
            제1항 또는 제3항에 따른 청약철회등을 서면으로 하는 경우에는 그
            의사표시가 적힌 서면을 발송한 날에 그 효력이 발생합니다.
          </li>
          <li>
            제1항부터 제3항까지를 적용할 때 디지털콘텐츠의 훼손에 대하여
            소비자의 책임이 있는지 여부, 디지털콘텐츠등의 구매에 관한 계약이
            체결된 사실 및 그 시기, 디지털콘텐츠등의 제공사실 및 그 시기 등에
            관하여 다툼이 있는 경우에는 사업자가 이를 증명합니다.
          </li>
          <li>
            사업자는 제2항제2호부터 제5호까지에 따라 청약철회등이 불가능한
            디지털콘텐츠등의 경우에는 그 사실을 디지털콘텐츠의 포장이나 그 밖에
            소비자가 쉽게 알 수 있는 곳에 명확하게 표시하거나 시험 사용 상품을
            제공하는 등의 방법으로 배제조치를 취합니다. 다만, 제2항제5호 중
            디지털콘텐츠에 대하여 소비자가 청약철회등을 할 수 없는 경우에는
            청약철회등이 불가능하다는 사실의 표시와 함께 다음 각 호의 구분에
            따른 방법 중 하나 이상의 조치를 취합니다.
            <ol>
              <li>
                일부 이용의 허용: 디지털콘텐츠의 일부를 미리보기, 미리듣기
                등으로 제공
              </li>
              <li>
                한시적 이용의 허용: 일정 사용기간을 설정하여 디지털콘텐츠 제공
              </li>
              <li>
                체험용 디지털콘텐츠 제공: 일부 제한된 기능만을 사용할 수 있는
                디지털콘텐츠 제공
              </li>
              <li>
                제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기
                곤란한 경우: 디지털콘텐츠에 관한 정보 제공
              </li>
            </ol>
          </li>
        </ol>
      </article>
      <article>
        <h5>
          제33조(스트리밍 방식의 디지털콘텐츠 이용계약과 소비자의 청약철회등)
        </h5>
        <ol>
          <li>
            사업자와 스트리밍 방식의 디지털콘텐츠 이용계약을 체결한 소비자 는
            다음 각 호의 기간(거래당사자가 다음 각 호의 기간보다 긴 기간으 로
            약정한 경우에는 그 기간을 말합니다) 이내에 그 계약에 대해 청약
            철회등을 할 수 있습니다.
            <ol>
              <li>
                제24조에 따른 계약서를 받은 날부터 7일. 다만, 계약서를 받은
                때보다 디지털콘텐츠의 제공이 늦게 이루어진 경우에는
                디지털콘텐츠를 제공받거나 디지털콘텐츠의 제공이 시작된 날부터
                7일
              </li>
              <li>
                제24조에 따른 계약서를 받지 아니한 경우, 사업자의 주소 등이 적혀
                있지 아니한 계약서를 받은 경우 또는 사업자의 주소 변경 등의
                사유로 제1호의 기간에 청약철회등을 할 수 없는 경우에는 사업자의
                주소를 안 날 또는 알 수 있었던 날부터 7일
              </li>
              <li>
                거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여 소비자의
                청약철회등을 방해하는 행위 또는 청약철회등을 방해할 목적으로
                주소, 전화번호, 인터넷도메인 이름 등을 변경하거나 폐지하는
                행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일
              </li>
            </ol>
          </li>
          <li>
            소비자는 다음 각 호의 어느 하나에 해당하는 경우에는 사업자의 의사에
            반하여 제1항에 따른 청약철회등을 할 수 없습니다. 다만, 사업자가
            제6항에 따른 조치를 하지 아니하는 경우에는 제1호에 해당하는 경우에도
            청약철회등을 할 수 있습니다.
            <ol>
              <li>
                디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 디지털콘텐츠의
                경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지
                않습니다.
              </li>
              <li>
                소비자의 주문에 따라 개별적으로 생산되는 디지털콘텐츠 또는 이와
                유사한 디지털콘텐츠에 대하여 청약철회등을 인정하는 경우
                사업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에
                해당 거래에 대하여 별도로 그 사실을 고지하고 소비자의
                서면(전자문서를 포함합니다)에 의한 동의를 받은 경우
              </li>
            </ol>
          </li>
          <li>
            소비자는 제1항 및 제2항에도 불구하고 디지털콘텐츠의 내용이
            표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 그
            디지털콘텐츠를 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알
            수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다.
          </li>
          <li>
            제1항 또는 제3항에 따른 청약철회등을 서면으로 하는 경우에는 그
            의사표시가 적힌 서면을 발송한 날에 그 효력이 발생합니다.
          </li>
          <li>
            제1항부터 제3항까지를 적용할 때 디지털콘텐츠에 관한 계약이 체결된
            사실 및 그 시기, 디지털콘텐츠의 제공사실 및 그 시기 등에 관하여
            다툼이 있는 경우에는 사업자가 이를 증명합니다.
          </li>
          <li>
            사업자는 제2항제1호에 따라 청약철회등이 불가능한 경우에는
            「콘텐츠산업진흥법」 제28조에 따른 「콘텐츠이용자보호지침」에 따라
            다음 각 호의 어느 하나에 해당하는 조치를 취합니다.
            <ol>
              <li>청약철회등이 불가능하다는 사실의 표시</li>
              <li>시용상품의 제공</li>
              <li>디지털콘텐츠의 한시적 또는 일부 이용 허용</li>
              <li>
                제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기
                곤란한 경우에 디지털콘텐츠에 관한 정보 제공
              </li>
            </ol>
          </li>
        </ol>
      </article>
      <article>
        <h5>제34조(이용사업자의 청약철회등)</h5>
        <ol>
          <li>
            사업자와 디지털콘텐츠에 관한 계약을 체결한 이용사업자는 다음 각 호의
            기간(거래당사자가 다음 각 호의 기간보다 긴 기간으로 약정한 경우에는
            그 기간을 말합니다) 이내에 그 계약에 대해 청약철회등을 할 수
            있습니다.
            <ol>
              <li>
                제24조에 따른 계약서를 받은 날부터 7일. 다만, 계약서를 받은
                때보다 디지털콘텐츠의 제공이 늦게 이루어진 경우에는
                디지털콘텐츠를 제공받거나 디지털콘텐츠의 제공이 시작된 날부터
                7일
              </li>

              <li>
                제24조에 따른 계약서를 받지 아니한 경우, 사업자의 주소 등이 적혀
                있지 아니한 계약서를 받은 경우 또는 사업자의 주소 변경 등의
                사유로 제1호의 기간에 청약철회등을 할 수 없는 경우에는 사업자의
                주소를 안 날 또는 알 수 있었던 날부터 7일
              </li>
              <li>
                거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여
                이용사업자의 청약철회등을 방해하는 행위 또는 청약철회등을 방해할
                목적으로 주소, 전화번호, 인터넷도메인 이름 등을 변경하거나
                폐지하는 행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일
              </li>
            </ol>
          </li>
          <li>
            이용사업자는 다음 각 호의 어느 하나에 해당하는 경우에는 사업자의
            의사에 반하여 제1항에 따른 청약철회등을 할 수 없습니다. 다만,
            사업자가 제6항에 따른 조치를 하지 아니하는 경우에는 제2호부터
            제5호까지에 해당하는 경우에도 청약철회등을 할 수 있습니다.
            <ol>
              <li>
                이용사업자에게 책임이 있는 사유로 디지털콘텐츠가 멸실되거나
                훼손된 경우. 다만, 디지털콘텐츠의 내용을 확인하기 위하여 포장
                등을 훼손한 경우는 제외합니다.
              </li>
              <li>
                이용사업자의 사용 또는 일부 소비로 디지털콘텐츠의 가치가 현저히
                감소한 경우
              </li>
              <li>
                시간이 지나 다시 판매하기 곤란할 정도로 디지털콘텐츠의 가치가
                현저히 감소한 경우
              </li>
              <li>복제가 가능한 디지털콘텐츠의 포장을 훼손한 경우</li>
              <li>
                디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 디지털콘텐츠의
                경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지
                않습니다.
              </li>
              <li>
                이용사업자의 주문에 따라 개별적으로 생산되는 디지털콘텐츠 또는
                이와 유사한 디지털콘텐츠에 대하여 청약철회등을 인정하는 경우
                사업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에
                해당 거래에 대하여 별도로 그 사실을 고지하고 이용사업자의
                서면(전자문서를 포함합니다)에 의한 동의를 받은 경우
              </li>
            </ol>
          </li>
          <li>
            이용사업자는 제1항 및 제2항에도 불구하고 디지털콘텐츠의 내용이
            표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 그
            디지털콘텐츠를 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알
            수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다.
          </li>
          <li>
            제1항 또는 제3항에 따른 청약철회등을 서면으로 하는 경우에는 그
            의사표시가 적힌 서면을 발송한 날에 그 효력이 발생합니다.
          </li>
          <li>
            제1항부터 제3항까지를 적용할 때 디지털콘텐츠의 훼손에 대하여
            이용사업자의 책임이 있는지 여부, 디지털콘텐츠에 관한 계약이 체결된
            사실 및 그 시기, 디지털콘텐츠의 제공사실 및 그 시기 등에 관하여
            다툼이 있는 경우에는 사업자가 이를 증명합니다.
          </li>
          <li>
            사업자는 제2항제2호부터 제5호까지에 따라 청약철회등이 불가능한
            경우에는 「콘텐츠산업진흥법」 제28조에 따른
            「콘텐츠이용자보호지침」에 따라 다음 각 호의 어느 하나에 해당하는
            조치를 취합니다.
            <ol>
              <li>청약철회등이 불가능하다는 사실의 표시</li>
              <li>시용상품의 제공</li>
              <li>디지털콘텐츠의 한시적 또는 일부 이용 허용</li>
              <li>
                제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기
                곤란한 경우에 디지털콘텐츠에 관한 정보 제공
              </li>
            </ol>
          </li>
        </ol>
      </article>
      <article>
        <h5>제35조(이용자의 청약철회등의 효과)</h5>
        <ol>
          <li>
            제32조부터 제34조까지의 청약철회등이 있는 경우에 이용자는 제공받은
            디지털콘텐츠등을 반환하지 않습니다.
          </li>
          <li>
            사업자는 이용자가 제32조부터 제34조까지에 따라 청약철회등을 한
            날부터 3영업일 이내에 이용자로부터 지급받은 대금을 환급합니다. 이
            경우, 사업자가 이용자에게 대금 환급을 지연한 때에는 그 지연기간에
            대하여 연 15%의 이율을 곱하여 산정한 지연이자(이하 "지연배상금"이라
            합니다)를 지급합니다.
          </li>
          <li>
            사업자는 제2항에 따라 대금을 환급할 때 이용자가 「여신전문금융업법」
            제2조제3호에 따른 신용카드 등으로 대금을 지급한 경우에는 지체 없이
            해당 결제수단을 제공한 사업자(이하 "결제업자"라 합니다)에게 대금
            청구를 정지하거나 취소하도록 요청합니다. 이 경우, 사업자가
            결제업자로부터 해당 대금을 이미 받은 때에는 지체 없이 그 대금을
            결제업자에게 환급하고, 그 사실을 이용자에게 알립니다.
          </li>
          <li>
            제3항 단서에 해당하는 경우, 사업자 중 환급을 지연하여 이용자가
            대금을 결제하게 한 때에 사업자는 그 지연기간에 대한 지연배상금을
            이용자에게 지급합니다.
          </li>
          <li>
            이용자는 사업자가 제3항 단서에도 불구하고 정당한 사유 없이
            결제업자에게 대금을 환급하지 아니하는 경우에는 결제업자에게 사업자에
            대한 다른 채무와 사업자로부터 환급받을 금액을 상계(相計)할 것을
            요청할 수 있습니다. 이 경우, 결제업자는 사업자에 대한 다른 채무와
            상계할 수 있습니다.
          </li>
          <li>
            이용자는 결제업자가 제5항에 따른 상계를 정당한 사유 없이 게을리한
            경우에는 결제업자에 대하여 대금의 결제를 거부할 수 있습니다. 이
            경우, 사업자와 결제업자는 그 결제 거부를 이유로 그 이용자를 약정한
            기일까지 채무를 변제하지 아니한 자로 처리하는 등 이용자에게 불이익을
            주는 행위를 하지 않습니다.
          </li>
          <li>
            제1항의 경우 사업자는 이미 디지털콘텐츠등이 일부 사용되거나 일부
            소비된 경우에는 그 디지털콘텐츠등의 일부 사용 또는 일부 소비에
            의하여 이용자가 얻은 이익 또는 그 디지털콘텐츠등의 제공에 든 비용에
            상당하는 금액으로서 다수의 동일한 가분물로 구성된 디지털콘텐츠등의
            경우에는 이용자의 일부 소비로 인하여 소비된 부분의 제공에 든 비용을
            이용자에게 청구할 수 있습니다.
          </li>
          <li>
            제32조부터 제34조까지에 따른 청약철회등의 경우, 사업자는 이용자에게
            청약철회등을 이유로 위약금이나 손해배상을 청구하지 않습니다.
          </li>
        </ol>
      </article>
      <h1>제4장 피해구제 및 계약의 해제ㆍ해지</h1>
      <article>
        <h5>제36조(디지털콘텐츠등의 하자와 담보책임)</h5>
        <ol>
          <li>
            사업자가 제공한 디지털콘텐츠등에 하자가 있는 경우로서 이용자가
            계약의 목적을 달성할 수 있는 경우, 이용자는 사업자에게 다음 각
            호에서 정한 권리를 행사할 수 있습니다. 다만, 이용자가
            디지털콘텐츠등에 하자가 있다는 사실을 알았거나 과실로 알지 못한
            경우에는 그러하지 않습니다.
            <ol>
              <li>하자없는 디지털콘텐츠등의 제공청구권</li>
              <li>하자보수청구권</li>
              <li>대금감액청구권</li>
            </ol>
          </li>
          <li>
            사업자가 제공한 디지털콘텐츠등에 하자가 있는 경우로서 이용자가
            계약의 목적을 달성할 수 없는 경우에 이용자는 사업자에게 다음 각
            호에서 정한 권리를 행사할 수 있습니다. 다만, 이용자가
            디지털콘텐츠등에 하자가 있다는 사실을 알았거나 과실로 알지 못한
            경우에는 그러하지 않습니다.
            <ol>
              <li>하자없는 디지털콘텐츠등의 제공청구권</li>
              <li>하자보수청구권</li>
              <li>대금감액청구권</li>
              <li>해제권 또는 해지권</li>
            </ol>
          </li>
          <li>
            이용자는 제1항 또는 제2항에 따른 권리 행사 외에 이로 인해 발생한
            손해에 대한 배상을 청구할 수 있습니다. 이 경우, 사업자는 고의 또는
            과실이 없음을 증명하더라도 그 책임을 부담합니다.
          </li>
          <li>
            제1항부터 제3항까지에서 정한 사항 외에 디지털콘텐츠의 하자담보책임에
            대해서는 「콘텐츠이용자보호지침」에서 정한 바에 따릅니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제37조(사업자의 채무불이행과 책임)</h5>
        <ol>
          <li>
            디지털콘텐츠의 계속적 이용계약의 경우에 사업자가 디지털콘텐츠등의
            이용중지 또는 장애에 대하여 사전에 알리지 않은 경우, 이용자의
            피해구제 등은 다음 각 호에서 정한 바에 따릅니다. 다만, 이용자의
            책임있는 사유로 인하여 디지털콘텐츠등의 이용이 중지되거나 장애가
            발생한 경우, 그 시간은 중지 또는 장애시간에 포함되지 않습니다.
            <ol>
              <li>
                1개월 동안의 중지 또는 장애발생 누적시간이 72시간을 초과한 경우
                : 이용자는 계약을 해제 또는 해지할 수 있으며, 사업자는
                미이용기간을 포함한 잔여기간에 대한 이용료의 환급 및 손해배상을
                합니다. 다만, 사업자가 고의 또는 과실 없음을 증명한 경우에는
                손해배상책임을 부담하지 않습니다.
              </li>
              <li>
                사업자의 책임있는 사유로 인해 중지 또는 장애가 발생하고, 그 중지
                또는 장애시간이 1개월 동안 72시간 이하인 경우 : 사업자는 중지
                또는 장애시간의 3배를 무료로 연장합니다.
              </li>
              <li>
                불가항력 또는 제3자의 불법행위 등으로 인해 중지 또는 장애가
                발생하고, 그 중지 또는 장애시간이 1개월 동안 72시간 이하인 경우
                : 사업자는 중지 또는 장애시간만큼 무료로 이용기간을 연장합니다.
              </li>
            </ol>
          </li>
          <li>
            사업자가 디지털콘텐츠등의 이용중지 또는 장애에 대하여 사전에 알린
            경우, 이용자의 피해구제 등은 다음 각 호에서 정한 바에 따릅니다.
            다만, 서비스 개선을 목적으로 한 설비 점검 및 보수의 경우에 1개월을
            기준으로 최대 24시간은 중지 또는 장애시간에 포함되지 않습니다.
            <ol>
              <li>
                1개월을 기준으로 중지 또는 장애시간이 10시간을 초과하는 경우 :
                사업자는 10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을
                무료로 연장합니다.
              </li>
              <li>
                1개월을 기준으로 중지 또는 장애시간이 10시간 이하인 경우 :
                사업자는 중지 또는 장애시간만큼 무료로 연장합니다.
              </li>
            </ol>
          </li>
          <li>
            제1항 및 제2항을 적용함에 있어서 사전고지는 중지 또는 장애시점을
            기준으로 24시간 이전에 알린 것으로 한정합니다. 다만, 불가항력적인
            사유로 인해 사전에 고지할 수 없는 경우에는 그러하지 않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제38조(사업자의 채무불이행과 해제)</h5>
        <ol>
          <li>
            사업자가 디지털콘텐츠등의 이용계약에서 정한 시기까지
            디지털콘텐츠등을 제공하지 않은 경우에 이용자는 상당한 기간을 정하여
            디지털콘텐츠등의 제공을 요구할 수 있습니다.
          </li>
          <li>
            사업자가 제1항에 따른 기간 내에 디지털콘텐츠등을 제공하지 않은
            경우에 이용자는 디지털콘텐츠등의 이용계약을 해제 또는 해지할 수
            있습니다. 다만, 다음 각 호의 어느 하나에 해당하는 경우, 이용자는
            제1항에 따른 재요구를 하지 않고 디지털콘텐츠등의 이용계약을 해제
            또는 해지할 수 있습니다.
            <ol>
              <li>사업자가 디지털콘텐츠등의 제공을 거절한 경우</li>
              <li>
                디지털콘텐츠등이 계약에서 정한 시기에 제공되어야 만이 계약의
                목적을 달성할 수 있는 경우
              </li>
            </ol>
          </li>
          <li>
            디지털콘텐츠등의 제공시기와 관계없이 사업자의 책임있는 사유로
            디지털콘텐츠등의 제공이 불가능하게 된 경우에 이용자는
            디지털콘텐츠등의 이용계약을 해제할 수 있습니다.
          </li>
          <li>
            사업자가 디지털콘텐츠등을 제공하였지만, 계약내용에 적합하지 않게
            디지털콘텐츠등을 제공한 경우에 이용자는 다음 각 호에서 정한 바에
            따라 권리를 행사할 수 있습니다.
            <ol>
              <li>
                계약의 목적을 달성할 수 있는 경우
                <ol>
                  <li>
                    디지털콘텐츠등의 보수 등이 가능한 경우 : 제36조제1항 각
                    호에서 정한 권리
                  </li>
                  <li>
                    디지털콘텐츠등의 보수 등이 불가능한 경우 : 제36조제1항제1호
                    또는 제3호에서 정한 권리
                  </li>
                </ol>
              </li>
              <li>
                계약의 목적을 달성할 수 없는 경우
                <ol>
                  <li>
                    디지털콘텐츠등의 보수 등이 가능한 경우 : 제36조제2항 각
                    호에서 정한 권리
                  </li>
                  <li>
                    디지털콘텐츠등의 보수 등이 불가능한 경우 : 제36조제2항제1호
                    또는 제4호에서 정한 권리
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            이용자는 제1항부터 제4항까지에 따른 권리를 행사하는 것 외에
            사업자에게 이로 인해 발생한 손해에 대한 배상을 청구할 수 있습니다.
            다만, 사업자가 고의 또는 과실 없음을 증명한 경우에 그러하지
            않습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제39조(이용자의 대금미지급과 책임등)</h5>
        <ol>
          <li>
            이용자가 이 계약에서 정한 기한까지 디지털콘텐츠등의 대금을 지급하지
            않았을 경우에 사업자는 상당한 기간을 정하여 대금지급을 청구할 수
            있습니다.
          </li>
          <li>
            이용자는 제1항에 따른 기간 내에 디지털콘텐츠등의 대금과 이에 대한
            지연이자를 사업자에게 지급합니다. 이 경우, 불가항력적인 사유가 있는
            경우를 제외하고, 이용자는 대금미지급에 대해 고의 또는 과실없음을
            증명하여 지연이자의 지급을 거절할 수 없습니다.
          </li>
          <li>
            이용자가 제1항에 따른 기간 내에 디지털콘텐츠등의 대금 및 지연이자를
            지급하지 않았을 경우에 사업자는 디지털콘텐츠등의 이용계약을 해제
            또는 해지할 수 있습니다. 다만, 이용자가 사전에 대금지급을 거절한
            경우에 사업자는 제1항에 따른 재요구를 하지 않고 디지털콘텐츠등의
            이용계약을 해제 또는 해지할 수 있습니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제40조(계속거래와 소비자의 해지 및 효과)</h5>
        <ol>
          <li>
            법률에서 다르게 정한 경우를 제외하고, 사업자와 계속거래를 체결한
            소비자는 계약기간 중 언제든지 그 계약을 해지할 수 있습니다.
          </li>
          <li>
            사업자는 자신의 책임이 없는 사유로 계속거래가 해지된 경우에
            소비자에게 해지로 발생하는 손실을 현저하게 초과하는 위약금을
            청구하지 않으며, 가입비나 그 밖에 명칭에 상관없이 실제 제공된
            디지털콘텐츠등의 대가를 초과하여 수령한 대금의 환급을 부당하게
            거부하지 않습니다.
          </li>
          <li>
            제1항에 따라 계속거래가 해지된 경우에 소비자는 반환할 수 있는
            디지털콘텐츠를 사업자에게 반환할 수 있으며, 사업자는 대금 환급 또는
            위약금 경감 등의 조치를 취합니다.
          </li>
          <li>
            사업자는 자신의 책임이 없는 사유로 계약이 제1항에 따라 해지된 경우에
            소비자로부터 받은 디지털콘텐츠등의 대금(디지털콘텐츠가 반환된 경우
            환급하여야 할 금액을 포함합니다)이 이미 제공한 디지털콘텐츠등의
            대금에 위약금을 더한 금액보다 많으면 그 차액을 소비자에게
            환급합니다. 이 경우, 환급이 3영업일 이상 지연되는 경우에는 그
            지연기간에 대한 지연배상금을 더하여 환급합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제41조(해제 또는 해지의 효과)</h5>
        <ol>
          <li>
            제32조부터 제34조까지를 제외하고, 디지털콘텐츠등의 이용계약이 해제된
            경우에 사업자 또는 이용자는 다음 각 호에서 정한 바에 따라
            원상회복의무를 부담합니다.
            <ol>
              <li>
                사업자는 소비자로부터 받은 대금을 환급합니다. 이 경우, 그 대금을
                받은 날로부터 환급할 때까지 연 6%(이 보다 높은 이자율을 약정한
                경우에는 그 약정이자율)를 곱하여 산정한 이자를 지급합니다.
              </li>
              <li>
                이용자는 사업자로부터 제공받은 디지털콘텐츠를 삭제하고, 자신
                또는 제3자가 이용할 수 없게 조치합니다. 다만, 사업자가
                디지털콘텐츠를 삭제하거나 이용할 수 없게 조치한 경우에는
                그러하지 않습니다.
              </li>
              <li>
                이용자는 사업자로부터 제공받은 디지털콘텐츠등을 이용한 경우에
                그에 따라 얻은 이익을 사업자에게 반환합니다.
              </li>
            </ol>
          </li>
          <li>
            제40조를 제외하고, 디지털콘텐츠등의 이용계약이 해지된 경우에 사업자
            또는 이용자는 다음 각 호에서 정한 바에 따른 의무를 부담합니다.
            <ol>
              <li>사업자는 잔여기간에 대한 대금을 이용자에게 환급합니다.</li>
              <li>
                이용자는 사업자가 제공한 디지털콘텐츠를 삭제하고, 자신 또는
                제3자가 이용할 수 없게 조치합니다. 다만, 사업자가 디지털콘텐츠를
                삭제하거나 이용할 수 없게 조치한 경우에는 그러하지 않습니다.
              </li>
            </ol>
          </li>
          <li>
            다른 합의가 있는 경우를 제외하고, 제1항 또는 제2항에 따른 의무는
            동시에 이행합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제42조(손해배상책임)</h5>
        <ol>
          <li>
            이용자 또는 사업자가 사이버몰 또는 디지털콘텐츠등 이용계약을
            위반하여 상대방에게 손해를 끼친 경우에 배상합니다. 다만,
            손해배상책임을 지는 자가 고의 또는 과실 없음을 증명한 경우에는
            그러하지 않습니다.
          </li>
          <li>
            제1항에도 불구하고, 이 약관 또는 법령에서 다르게 정한 경우에는 그에
            따릅니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제43조(소비자의 손해배상책임 제한)</h5>
        <p>
          소비자에게 책임이 있는 사유로 디지털콘텐츠등의 판매에 관한 계약이
          해제된 경우, 사업자가 소비자에게 청구하는 손해배상액은 다음 각 호의
          구분에 따라 정한 금액에 대금미납에 따른 지연배상금을 더한 금액을
          초과할 수 없습니다.
        </p>
        <ol>
          <li>
            제공된 디지털콘텐츠의 삭제 등에 따라 이용자가 더 이상 이용할 수 없는
            경우: 삭제된 디지털콘텐츠의 통상 사용료 또는 그 사용으로 통상 얻을
            수 있는 이익에 해당하는 금액
          </li>
          <li>
            제공된 디지털콘텐츠등의 삭제 등이 이루어지지 않은 경우: 그
            디지털콘텐츠등의 판매가액에 해당하는 금액
          </li>
        </ol>
      </article>
      <article>
        <h5>제44조(대금환급등의 방법)</h5>
        <ol>
          <li>
            사업자가 이용자에게 대금의 전부 또는 일부를 환급할 경우에 이용자가
            대금을 지급한 방법으로 환급합니다.
          </li>
          <li>
            제1항에서 정한 방법으로 대금의 전부 또는 일부를 환급할 수 없는 경우,
            사업자는 이용자가 선택하는 방법으로 환급합니다.
          </li>
          <li>
            사업자는 이용자가 지급한 대금 중 일부를 손해배상금 또는 이용금액
            등으로 공제할 경우에 복수의 지급수단 중 이용자가 선택한 지급수단에서
            공제하며, 이용자의 선택이 없는 경우에 이용자에게 유리한 방법으로
            공제합니다.
          </li>
        </ol>
      </article>
      <h1>제5장 분쟁해결 및 재판관할</h1>
      <article>
        <h5>제45조(분쟁해결)</h5>
        <ol>
          <li>
            사업자와 이용자간 사이버몰 또는 디지털콘텐츠등의 이용계약과 관련하여
            분쟁이 발생한 경우에 상호 협의하여 분쟁을 해결합니다.
          </li>
          <li>
            제1항에 따라 분쟁이 해결되지 않은 경우에 사업자 또는 이용자는
            「저작권법」에 따른 한국저작권위원회, 「콘텐츠산업진흥법」에 따른
            콘텐츠분쟁조정위원회, 「전자문서 및 전자거래기본법」에 따른
            전자문서ㆍ전자거래분쟁조정위원회, 「소비자기본법」에 따른
            소비자분쟁조정위원회 등에 조정을 신청할 수 있습니다.
          </li>
          <li>
            제1항에 따라 분쟁이 해결되지 않은 경우에 사업자 또는 이용자는
            「중재법」에 따른 중재를 통해 분쟁을 해결할 수 있습니다. 이 경우,
            사업자 또는 이용자가 중재를 신청하기 위해 상대방과 서면으로
            중재합의를 합니다.
          </li>
        </ol>
      </article>
      <article>
        <h5>제46조(재판관할)</h5>
        <ol>
          <li>
            사업자와 소비자간 사이버몰 또는 디지털콘텐츠등의 이용계약에 관한
            소는 소 제기 당시 소비자의 주소를 관할하는 지방법원의 전속관할로
            하고, 주소가 없는 경우에는 거소(居所)를 관할하는 지방법원의
            전속관할로 합니다. 다만, 소 제기 당시 소비자의 주소 또는 거소가
            분명하지 아니한 경우에 관할법원은「민사소송법」에 따라 결정합니다.
          </li>
          <li>
            사업자와 이용사업자간 사이버몰 또는 디지털콘텐츠등의 이용계약에 관한
            소는 사업자 또는 이용사업자의 주소를 관할하는 지방법원에 제기하며,
            주소가 없는 경우에는 거소(居所)를 관할하는 지방법원에 제기합니다.
            다만, 소 제기 당시 사업자 및 이용사업자의 주소 또는 거소가 분명하지
            아니한 경우에 관할법원은「민사소송법」에 따라 결정합니다.
          </li>
        </ol>
      </article>
    </Wrapper>
  );
};

export default TermsOfService;
