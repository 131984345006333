import BasicQuizzes from "comps/_quizzes/BasicQuizzes";
import ToNihongoQuiz, { TNQuiz } from "comps/_quizzes/ToNihongoQuiz";
import StepProps from "types/StepProps";
import getSkuskuQuizId from "utils/getSkuskuQuizId";

const genQuizId = (extra: string) => {
  return getSkuskuQuizId({
    chapter: 1,
    isWorkBook: true,
    pageNumber: 45,
    extra,
  });
};

const quizzes: TNQuiz[] = [
  {
    id: genQuizId("tnquiz-1"),
    korean: "대단히 감사합니다.",
    answer: "どうも、ありがとうございます。",
    answers: [],
  },
  {
    id: genQuizId("tnquiz-2"),
    korean: "예, 덕분에요.",
    answer: "はい、おかげさまで。",
    answers: [],
  },
  {
    id: genQuizId("tnquiz-3"),
    korean: "잘 다녀오세요.",
    answer: "いってらっしゃい。",
    answers: [],
  },
  {
    id: genQuizId("tnquiz-4"),
    korean: "어서 돌아오세요.",
    answer: "お{帰|かえ}りなさい。",
    answers: [],
  },
];

const ToNihongoQuizzes: React.FC<StepProps> = ({ onEnd }) => {
  return (
    <BasicQuizzes quizzes={quizzes} QuizComp={ToNihongoQuiz} onEnd={onEnd} />
  );
};

export default ToNihongoQuizzes;
