import styled from "@emotion/styled";
import Dialogue from "comps/Dialogue";
import BorderBluredImg from "comps/imgs/BorderBlurredImg";
import useAutoPlayOnMount from "hooks/useAutoPlayOnMount";
import useDialogueVoiceController from "hooks/useDialogueVoiceController";
import { FormEventHandler } from "react";
import { Container, Form, Row } from "react-bootstrap";
import BaseQuiz from "types/quiz/BaseQuiz";
import BaseQuizProps from "types/quiz/BaseQuizProps";
import TSentence from "types/Sentence";

const Img = styled(BorderBluredImg)`
  width: 60%; // TODO: 크기 responsive 로 될수 있도록
  height: 400px; // TODO: 크기 responsive 로 될수 있도록
`;

export interface TDDQuiz extends BaseQuiz {
  imgUrl: string;
  fullVoice: string;
  sentenceA: Required<TSentence>;
  sentenceB: Required<TSentence>;
}

export interface DDQuizProps extends TDDQuiz, BaseQuizProps {}

const DDQuiz: React.FC<DDQuizProps> = ({
  imgUrl,
  fullVoice,
  sentenceA: sA,
  sentenceB: sB,
  onAnswer,
}) => {
  const voiceCtrl = useDialogueVoiceController({
    full: fullVoice,
    sentences: [sA.voiceUrl, sB.voiceUrl],
  });

  useAutoPlayOnMount(voiceCtrl);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const quiz_answer = formData.get("quiz_answer");
    e.currentTarget.reset();
    if (onAnswer) {
      onAnswer(quiz_answer);
    }
  };

  return (
    <Container>
      <Row>
        <Img
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      </Row>
      <Dialogue>
        <Dialogue.P>
          <Dialogue.Speecher>
            <Dialogue.Sentence>{sA.speaker}</Dialogue.Sentence>
          </Dialogue.Speecher>
          <Dialogue.Speech>
            <Dialogue.Row>
              <Dialogue.SentenceSpeechButton
                onClick={() => {
                  voiceCtrl.playSentence(1);
                }}
              />
              <Dialogue.Sentence>{sA.nihongo}</Dialogue.Sentence>
            </Dialogue.Row>
          </Dialogue.Speech>
        </Dialogue.P>
        <Dialogue.P>
          <Dialogue.Speecher>
            <Dialogue.Sentence>{sB.speaker}</Dialogue.Sentence>
          </Dialogue.Speecher>
          <Dialogue.Speech>
            <Dialogue.Row>
              <Dialogue.SentenceSpeechButton
                onClick={() => {
                  voiceCtrl.playSentence(2);
                }}
              />
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="quiz_answer">
                  <Form.Control
                    type="text"
                    size="lg"
                    autoFocus
                    name="quiz_answer"
                    autoComplete="off"
                  />
                </Form.Group>
              </Form>
            </Dialogue.Row>
          </Dialogue.Speech>
        </Dialogue.P>
      </Dialogue>
    </Container>
  );
};

export default DDQuiz;
