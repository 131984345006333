import MyBreadcrumb from "comps/MyBreadcrumb";
import { Navigate, Route, Routes } from "react-router-dom";
import ChapterBase from "../ChapterBase";

export interface TStep {
  slug: string;
  breadCrumb: string;
  elem: React.ReactNode;
}

export interface ChapterProps {
  index: number;
  steps: TStep[];
}

const Chapter: React.FC<ChapterProps> = ({ index, steps }) => {
  return (
    <ChapterBase>
      <ChapterBase.Header>
        <ChapterBase.Heading>CH {index}.</ChapterBase.Heading>
        <MyBreadcrumb>
          {steps.map((step, index) => {
            return (
              <MyBreadcrumb.Item to={step.slug} key={index}>
                {step.breadCrumb}
              </MyBreadcrumb.Item>
            );
          })}
        </MyBreadcrumb>
      </ChapterBase.Header>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={steps[0].slug} replace={true} />}
        />
        {steps.map((step, index) => {
          return <Route path={step.slug} element={step.elem} key={index} />;
        })}
      </Routes>
    </ChapterBase>
  );
};

export default Chapter;
