import { useQuery } from "@apollo/client";
import { gql } from "__generated__";

const GET_QUIZHIT = gql(`
  query GetQuizHit($quiz_id: String!) {
    quizHit(quiz_id: $quiz_id) {
      total
      correct
      wrong
    }
  }
`);

const useQuizHit = (quiz_id: string) => {
  const { data } = useQuery(GET_QUIZHIT, {
    variables: {
      quiz_id,
    },
    fetchPolicy: "no-cache",
  });

  const quizHit = data?.quizHit;

  return quizHit;
};

export default useQuizHit;
