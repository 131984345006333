import _ from "lodash";
import { useMemo } from "react";
import BaseQuiz from "types/quiz/BaseQuiz";

interface Options<Q, H> {
  unsortedQuizzes: Q[];
  quizHits?: H[];
}

const useSortQuizByHits = <
  Q extends BaseQuiz,
  H extends { correct: number; total: number; quiz_id: string }
>({
  unsortedQuizzes,
  quizHits,
}: Options<Q, H>) => {
  return useMemo(() => {
    if (!quizHits) return unsortedQuizzes;

    return _.sortBy(unsortedQuizzes, [
      (quiz) => {
        const hit = _.find(quizHits, (hit) => {
          return hit.quiz_id === quiz.id;
        });
        if (!hit) return 0;
        return hit.correct / hit.total;
      },
    ]);
  }, [unsortedQuizzes, quizHits]);
};

export default useSortQuizByHits;
