import MyProgress from "comps/MyProgress";
import useGlobalKeyNav from "hooks/useGlobalKeyNav";
import useListIdxMethods from "hooks/useListIdxMethods";
import NoWords from "./NoWords";
import Word, { TWord } from "./Word";

export interface WordsProps {
  words: TWord[];
  onEnd?: VoidFunction;
}

const Words: React.FC<WordsProps> = ({ words, onEnd }) => {
  const total = words.length;
  const { index, decreaseIndex, increaseIndex } = useListIdxMethods({
    total,
    onEnd,
  });

  useGlobalKeyNav({
    onPrev: decreaseIndex,
    onNext: increaseIndex,
  });

  if (total === 0) {
    return <NoWords />;
  }

  return (
    <div>
      <MyProgress total={total} done={index + 1} />
      <Word word={words[index]} />
    </div>
  );
};

export default Words;
