import Words from "comps/Words";
import injectQuizInput from "hocs/injectQuizInput";
import quizzesToWords from "hocs/quizzesToWords";
import toStepComp from "hocs/toStepComp";
import withQuizzes from "hocs/withQuizzes";
import { ComponentProps } from "react";

const MyWords = injectQuizInput({
  tags: ["스쿠스쿠일본어", "ch2", "단어4", "나라"],
})(withQuizzes(quizzesToWords(toStepComp(Words))));

type Props = ComponentProps<typeof MyWords>;

const CountryWords: React.FC<Props> = (props) => {
  return (
    <div>
      {/* <Title>
        <RubyText>{`お{仕|し}{事|ごと}は{何|なん}ですか。`}</RubyText>
      </Title> */}
      <MyWords {...props} />
    </div>
  );
};

export default CountryWords;
