import { buildAssetsUrl } from "utils/url";
import MainConv from "../../MainConv";

const MainConv12: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch12/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch12/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんは",
                      trans: "야마다 씨는",
                    },
                    {
                      nihongo: "{何|なん}{人|にん}",
                      trans: "몇 명",
                    },
                    {
                      nihongo: "{家|か}{族|ぞく}",
                      trans: "가족",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "４{人|にん}",
                      trans: "4인",
                    },
                    {
                      nihongo: "{家|か}{族|ぞく}",
                      trans: "가족",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{父|ちち}と",
                      trans: "아버지와",
                    },
                    {
                      nihongo: "{母|はは}と",
                      trans: "어머니와",
                    },
                    {
                      nihongo: "{姉|あね}が",
                      trans: "언니가",
                    },
                    {
                      nihongo: "います。",
                      trans: "있어요.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "お{父|とう}さんは",
                      trans: "아버지는",
                    },
                    {
                      nihongo: "{会|かい}{社|しゃ}{員|いん}",
                      trans: "회사원",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니요,",
                    },
                    {
                      nihongo: "{会|かい}{社|しゃ}{員|いん}じゃ",
                      trans: "회사원이",
                    },
                    {
                      nihongo: "ありません。",
                      trans: "아닙니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{医|い}{者|しゃ}です。",
                      trans: "의사입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "お{父|とう}さんは",
                      trans: "아버지는",
                    },
                    {
                      nihongo: "どんな",
                      trans: "어떤",
                    },
                    {
                      nihongo: "{方|かた}",
                      trans: "분",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "이세요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{父|ちち}は",
                      trans: "아버지는",
                    },
                    {
                      nihongo: "{背|せ}が",
                      trans: "키가",
                    },
                    {
                      nihongo: "{高|たか}くて、",
                      trans: "크고,",
                    },
                    {
                      nihongo: "{真|ま}{面|じ}{目|め}な",
                      trans: "성실한",
                    },
                    {
                      nihongo: "{人|ひと}",
                      trans: "사람",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんは",
                      trans: "김 씨는",
                    },
                    {
                      nihongo: "{何|なん}{人|にん}",
                      trans: "몇 명",
                    },
                    {
                      nihongo: "{家|か}{族|ぞく}",
                      trans: "가족",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch12/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{私|わたし}も",
                      trans: "저도",
                    },
                    {
                      nihongo: "４{人|にん}",
                      trans: "4인",
                    },
                    {
                      nihongo: "{家|か}{族|ぞく}",
                      trans: "가족",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv12;
