import useProfile from "hooks/useProfile";
import { HTMLAttributes } from "react";
import SignInMenu from "./SignInMenu";
import SignOutMenu from "./SignOutMenu";

interface Props extends HTMLAttributes<HTMLElement> {}

const ProfileMenu: React.FC<Props> = (props) => {
  const profile = useProfile();

  if (!profile) {
    return <SignInMenu {...props} />;
  }

  return <SignOutMenu {...props} />;
};

export default ProfileMenu;
