import { useSearchParams } from "react-router-dom";
import qs from "qs";
import QuizInputProps from "types/quiz/QuizInputProps";
import { QuizzesQueryInput } from "__generated__/graphql";

const withQuizInputFromQuery =
  <T,>(Comp: React.ComponentType<T>) =>
  (props: Omit<T, keyof QuizInputProps>) => {
    const [searchParams] = useSearchParams();
    const { answer, examples, hiragana, level, question, tags } = qs.parse(
      searchParams.toString()
    );

    const quizInput: QuizzesQueryInput = {
      answer: answer as string | undefined,
      examples: examples as string[] | undefined,
      hiragana: hiragana as string | undefined,
      level: level ? Number(level) : undefined,
      question: question as string | undefined,
      tags: tags as string[] | undefined,
    };

    return <Comp quizInput={quizInput} {...(props as T)} />;
  };

export default withQuizInputFromQuery;
