import { genDefaultChapterProps } from "../utils";
import Ch11MainConv from "./steps/MainConv";

const ch11 = genDefaultChapterProps(11);

ch11.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <Ch11MainConv />,
});

export default ch11;
