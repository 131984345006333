import styled from "@emotion/styled";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "react-bootstrap";

const MyButton = styled(Button)`
  margin-right: 0.7em;
`;

const SentenceSpeechButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <MyButton variant="success" {...props}>
      <FontAwesomeIcon icon={faVolumeHigh} />
    </MyButton>
  );
};

export default SentenceSpeechButton;
