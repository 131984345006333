import styled from "@emotion/styled";
import Dialogue from "comps/Dialogue";
import BorderBluredImg from "comps/imgs/BorderBlurredImg";
import useAutoPlayOnMount from "hooks/useAutoPlayOnMount";
import useVoiceController from "hooks/useVoiceController";
import { Button, Container, Row } from "react-bootstrap";
import BaseQuiz from "types/quiz/BaseQuiz";
import BaseQuizProps from "types/quiz/BaseQuizProps";
import WithAnswer from "types/quiz/WithAnswer";
import WithReason from "types/quiz/WithReason";

const Img = styled(BorderBluredImg)`
  width: 60%; // TODO: 크기 responsive 로 될수 있도록
  height: 400px; // TODO: 크기 responsive 로 될수 있도록
`;

const Buttons = styled.div`
  display: flex;
  gap: 1em;
  > button {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

export interface TDOXQuiz extends WithReason<WithAnswer<BaseQuiz>> {
  imgUrl: string;
  fullVoice: string;
}

interface Props extends TDOXQuiz, BaseQuizProps {
  onAnswer: (answer: boolean) => void;
}

const DOXQuiz: React.FC<Props> = ({ fullVoice, imgUrl, onAnswer }) => {
  const voiceCtrl = useVoiceController(fullVoice);

  useAutoPlayOnMount(voiceCtrl);

  return (
    <Container>
      <Row>
        <Dialogue.MainSpeechButton
          onClick={() => {
            voiceCtrl.play();
          }}
        />
      </Row>
      <Row>
        <Img
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      </Row>
      <Buttons>
        <Button
          variant="success"
          onClick={() => {
            onAnswer(true);
          }}
        >
          O
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onAnswer(false);
          }}
        >
          X
        </Button>
      </Buttons>
    </Container>
  );
};

export default DOXQuiz;
