import BasicQuizzes from "comps/_quizzes/BasicQuizzes";
import PartialDictationQuiz, {
  PDQuiz,
} from "comps/_quizzes/PartialDictationQuiz";
import getSkuskuQuizId from "utils/getSkuskuQuizId";
import voice1 from "assets/learn/ch1/dictation-1/1.mp3";
import voice2 from "assets/learn/ch1/dictation-1/2.mp3";
import voice3 from "assets/learn/ch1/dictation-1/3.mp3";
import StepProps from "types/StepProps";

const genQuizId = (extra: string) => {
  return getSkuskuQuizId({
    chapter: 1,
    isWorkBook: false,
    pageNumber: 25,
    extra,
  });
};

const quizzes: PDQuiz[] = [
  {
    id: genQuizId("pdquiz-1"),
    sentence: [
      {
        nihongo: "はじめまして",
        isQuestion: true,
        trans: "",
      },
      {
        nihongo: "、",
        isQuestion: false,
        trans: "",
      },
      {
        nihongo: "{山|やま}{田|だ}です。",
        isQuestion: false,
        trans: "",
      },
    ],
    voice: voice1,
  },
  {
    id: genQuizId("pdquiz-2"),
    sentence: [
      {
        nihongo: "どうも、",
        isQuestion: false,
        trans: "",
      },
      {
        nihongo: "ありがとうございます",
        isQuestion: true,
        trans: "",
      },
      {
        nihongo: "。",
        isQuestion: false,
        trans: "",
      },
    ],
    voice: voice2,
  },
  {
    id: genQuizId("pdquiz-3"),
    sentence: [
      {
        nihongo: "こちらこそ",
        isQuestion: true,
        trans: "",
      },
      {
        nihongo: "、",
        isQuestion: false,
        trans: "",
      },
      {
        nihongo: "どうぞよろしくお{願|ねが}いします。",
        isQuestion: false,
        trans: "",
      },
    ],
    voice: voice3,
  },
];

const PDQuizzes: React.FC<StepProps> = ({ onEnd }) => {
  return (
    <BasicQuizzes
      quizzes={quizzes}
      QuizComp={PartialDictationQuiz}
      onEnd={onEnd}
    />
  );
};

export default PDQuizzes;
