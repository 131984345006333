import { genDefaultChapterProps } from "../utils";
import MainConv15 from "./steps/MainConv";

const ch15 = genDefaultChapterProps(15);

ch15.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv15 />,
});

export default ch15;
