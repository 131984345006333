import Dialogue from "comps/Dialogue";
import Phrase, { TPhrase } from "./Phrase";
import Utter, { TUtter } from "./Utter";

export interface TSpeech {
  speaker: TPhrase;
  utters: TUtter[];
}

const Speech: React.FC<TSpeech> = ({ speaker, utters }) => {
  return (
    <Dialogue.P>
      <Dialogue.Speecher>
        <Phrase {...speaker} nihongo={`${speaker.nihongo} :`} />
      </Dialogue.Speecher>
      <Dialogue.Speech>
        {utters.map((u, i) => {
          return <Utter {...u} key={i} />;
        })}
      </Dialogue.Speech>
    </Dialogue.P>
  );
};

export default Speech;
