import Phrase, { TPhrase } from "./Phrase";

export interface TSentence {
  phrases: TPhrase[];
}

const Sentence: React.FC<TSentence> = ({ phrases }) => {
  return (
    <>
      {phrases.map((p, i) => {
        return <Phrase {...p} key={i} />;
      })}
    </>
  );
};

export default Sentence;
