import { genDefaultChapterProps } from "../utils";
import MainConv20 from "./steps/MainConv";

const ch20 = genDefaultChapterProps(20);

ch20.steps.push({
  slug: "main-conv",
  breadCrumb: "메인 대화",
  elem: <MainConv20 />,
});

export default ch20;
