import conv10Bg from "assets/learn/ch1/learn/conv10/conv10.jpeg";
import conv10VoiceA from "assets/learn/ch1/learn/conv10/tadaima-A.mp3";
import conv10VoiceB from "assets/learn/ch1/learn/conv10/tadaima-B.mp3";
import conv10Voice from "assets/learn/ch1/learn/conv10/tadaima.mp3";
import conv2Bg from "assets/learn/ch1/learn/conv2/conv2.jpeg";
import conv2VoiceA from "assets/learn/ch1/learn/conv2/konnichiha-A.mp3";
import conv2VoiceB from "assets/learn/ch1/learn/conv2/konnichiha-B.mp3";
import conv2Voice from "assets/learn/ch1/learn/conv2/konnichiha.mp3";
import conv5VoiceA from "assets/learn/ch1/learn/conv5/arigatou-A.mp3";
import conv5VoiceB from "assets/learn/ch1/learn/conv5/arigatou-B.mp3";
import conv5Voice from "assets/learn/ch1/learn/conv5/arigatou.mp3";
import conv5Bg from "assets/learn/ch1/learn/conv5/conv5.jpeg";
import conv6Bg from "assets/learn/ch1/learn/conv6/conv6.jpeg";
import conv6VoiceA from "assets/learn/ch1/learn/conv6/sumimasen-A.mp3";
import conv6VoiceB from "assets/learn/ch1/learn/conv6/sumimasen-B.mp3";
import conv6Voice from "assets/learn/ch1/learn/conv6/sumimasen.mp3";
import conv8Bg from "assets/learn/ch1/learn/conv8/conv8.jpeg";
import conv8VoiceA from "assets/learn/ch1/learn/conv8/ogenkidesuka-A.mp3";
import conv8VoiceB from "assets/learn/ch1/learn/conv8/ogenkidesuka-B.mp3";
import conv8Voice from "assets/learn/ch1/learn/conv8/ogenkidesuka.mp3";
import conv9Bg from "assets/learn/ch1/learn/conv9/conv9.jpeg";
import conv9VoiceA from "assets/learn/ch1/learn/conv9/ittekimasu-A.mp3";
import conv9VoiceB from "assets/learn/ch1/learn/conv9/ittekimasu-B.mp3";
import conv9Voice from "assets/learn/ch1/learn/conv9/ittekimasu.mp3";
import DialogueDictationQuizzes from "comps/_quizzes/DialogueDictationQuizzes";
import { TDDQuiz } from "comps/_quizzes/DialogueDictationQuizzes/DDQuiz";
import injectQuizzes from "hocs/injectQuizzes";
import withQuizHits from "hocs/withQuizHits";
import getSkuskuQuizId from "utils/getSkuskuQuizId";

const genQuizId = (extra: string) => {
  return getSkuskuQuizId({
    chapter: 1,
    isWorkBook: false,
    pageNumber: 24,
    extra,
  });
};

const quizzes: TDDQuiz[] = [
  {
    id: genQuizId("conv2"),
    fullVoice: conv2Voice,
    imgUrl: conv2Bg,
    sentenceA: {
      speaker: "A :",
      nihongo: "こんにちは。",
      trans: "안녕하세요.",
      voiceUrl: conv2VoiceA,
    },
    sentenceB: {
      speaker: "B :",
      nihongo: "こんにちは。",
      trans: "안녕하세요.",
      voiceUrl: conv2VoiceB,
    },
  },
  {
    id: genQuizId("conv5"),
    fullVoice: conv5Voice,
    imgUrl: conv5Bg,
    sentenceA: {
      speaker: "A :",
      nihongo: "どうも、ありがとうございます。",
      trans: "대단히 감사합니다.",
      voiceUrl: conv5VoiceA,
    },
    sentenceB: {
      speaker: "B :",
      nihongo: "いいえ、どういたしまして。",
      trans: "아니오, 천만에요.",
      voiceUrl: conv5VoiceB,
    },
  },
  {
    id: genQuizId("conv6"),
    fullVoice: conv6Voice,
    imgUrl: conv6Bg,
    sentenceA: {
      speaker: "A :",
      nihongo: "すみません。",
      trans: "죄송합니다.",
      voiceUrl: conv6VoiceA,
    },
    sentenceB: {
      speaker: "B :",
      nihongo: "いいえ、だいじょうぶです。",
      trans: "아니오, 괜찮습니다.",
      voiceUrl: conv6VoiceB,
    },
  },
  {
    id: genQuizId("conv8"),
    fullVoice: conv8Voice,
    imgUrl: conv8Bg,
    sentenceA: {
      speaker: "A :",
      nihongo: "お{元|げん}{気|き}ですか。",
      trans: "잘 지내세요?",
      voiceUrl: conv8VoiceA,
    },
    sentenceB: {
      speaker: "B :",
      nihongo: "はい、おかげさまで。",
      trans: "예, 덕분에요.",
      voiceUrl: conv8VoiceB,
    },
  },
  {
    id: genQuizId("conv9"),
    fullVoice: conv9Voice,
    imgUrl: conv9Bg,
    sentenceA: {
      speaker: "A :",
      nihongo: "いってきます。",
      trans: "다녀오겠습니다.",
      voiceUrl: conv9VoiceA,
    },
    sentenceB: {
      speaker: "B :",
      nihongo: "いってらっしゃい。",
      trans: "잘 다녀오세요.",
      voiceUrl: conv9VoiceB,
    },
  },
  {
    id: genQuizId("conv10"),
    fullVoice: conv10Voice,
    imgUrl: conv10Bg,
    sentenceA: {
      speaker: "A :",
      nihongo: "ただいま。",
      trans: "다녀왔습니다.",
      voiceUrl: conv10VoiceA,
    },
    sentenceB: {
      speaker: "B :",
      nihongo: "お{帰|かえ}りなさい。",
      trans: "어서 돌아오세요.",
      voiceUrl: conv10VoiceB,
    },
  },
];

export default injectQuizzes(quizzes)(withQuizHits(DialogueDictationQuizzes));
