import MainConv from "pages/Learn/chapters/MainConv";
import { buildAssetsUrl } from "utils/url";

const MainConv14: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch14/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch14/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさん、",
                      trans: "김 씨,",
                    },
                    {
                      nihongo: "{今|こん}{週|しゅう}{末|まつ}は",
                      trans: "이번 주말은",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "뭐",
                    },
                    {
                      nihongo: "しますか。",
                      trans: "하세요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{友|とも}だちに",
                      trans: "친구를",
                    },
                    {
                      nihongo: "{会|あ}います。",
                      trans: "만납니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "それから、",
                      trans: "그리고 나서,",
                    },
                    {
                      nihongo: "カラオケに",
                      trans: "노래방에",
                    },
                    {
                      nihongo: "{行|い}きます。",
                      trans: "갑니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "よく",
                      trans: "자주",
                    },
                    {
                      nihongo: "カラオケに",
                      trans: "노래방에",
                    },
                    {
                      nihongo: "{行|い}きますか。",
                      trans: "가세요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{歌|うた}が",
                      trans: "노래를",
                    },
                    {
                      nihongo: "{好|す}きだから、",
                      trans: "좋아하기 때문에,",
                    },
                    {
                      nihongo: "よく",
                      trans: "자주",
                    },
                    {
                      nihongo: "{行|い}きます。",
                      trans: "갑니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "とても",
                      trans: "아주",
                    },
                    {
                      nihongo: "{楽|たの}しいですよ。",
                      trans: "즐겁습니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "{山|やま}{田|だ}さんは",
                      trans: "야마다 씨는",
                    },
                    {
                      nihongo: "{何|なに}を",
                      trans: "뭐",
                    },
                    {
                      nihongo: "しますか。",
                      trans: "하세요?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{図|と}{書|しょ}{館|かん}に",
                      trans: "도서관에",
                    },
                    {
                      nihongo: "{行|い}きます。",
                      trans: "갑니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "よく",
                      trans: "자주",
                    },
                    {
                      nihongo: "{図|と}{書|しょ}{館|かん}に",
                      trans: "도서관에",
                    },
                    {
                      nihongo: "{行|い}きますか。",
                      trans: "갑니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch14/main-conv/8.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "あまり",
                      trans: "별로",
                    },
                    {
                      nihongo: "{行|い}きません。",
                      trans: "안 갑니다.",
                    },
                  ],
                },
                {
                  phrases: [
                    {
                      nihongo: "でも、",
                      trans: "하지만,",
                    },
                    {
                      nihongo: "{来|らい}{週|しゅう}から",
                      trans: "다음 주부터",
                    },
                    {
                      nihongo: "テストだから、",
                      trans: "시험이기 때문에,",
                    },

                    {
                      nihongo: "{図|と}{書|しょ}{館|かん}で",
                      trans: "도서관에서",
                    },
                    {
                      nihongo: "{勉|べん}{強|きょう}を",
                      trans: "공부할",
                    },
                    {
                      nihongo: "します。",
                      trans: "겁니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv14;
