import Textfit from "@namhong2001/react-textfit";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { rubyTextToHiragana, strToRuby } from "utils";
import tts from "utils/voices/tts";

export interface TWord {
  nihongo: string; // ruby text form
  trans: string;
  imgUrl?: string | null;
}

interface Props {
  word: TWord;
}

const Word: React.FC<Props> = ({ word }) => {
  useEffect(() => {
    tts.speak(rubyTextToHiragana(word.nihongo));
    return () => {
      tts.cancelAllSpeak();
    };
  }, [word.nihongo]);

  return (
    <Container className="text-center">
      {word.imgUrl && (
        <div>
          <img src={word.imgUrl} alt="" width="20%" />
        </div>
      )}
      <Textfit mode="single" max={200}>
        {strToRuby(word.nihongo)}
      </Textfit>
      <Textfit max={50}>{word.trans}</Textfit>
    </Container>
  );
};

export default Word;
