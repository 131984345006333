import { useNavigate } from "react-router-dom";

const useNavFnFactory = () => {
  const navigate = useNavigate();
  const navFnFactory = (to: string) => () => {
    navigate(to);
  };

  return navFnFactory;
};

export default useNavFnFactory;
