import { buildAssetsUrl } from "utils/url";
import MainConv from "pages/Learn/chapters/MainConv";

const MainConv3: React.FC = () => {
  return (
    <MainConv
      fullVoice={buildAssetsUrl("skusku/ch3/main-conv/full.mp3")}
      bg={buildAssetsUrl("skusku/ch3/main-conv/bg.jpeg")}
      speeches={[
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/1.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさん、",
                      trans: "김 씨,",
                    },
                    {
                      nihongo: "それは",
                      trans: "그것은",
                    },

                    {
                      nihongo: "{何|なん}",
                      trans: "무엇",
                    },
                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/2.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "これは",
                      trans: "이것은",
                    },
                    {
                      nihongo: "パソコン",
                      trans: "컴퓨터",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/3.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "キムさんの",
                      trans: "김 씨의",
                    },
                    {
                      nihongo: "パソコン",
                      trans: "컴퓨터",
                    },

                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/4.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "はい、",
                      trans: "예,",
                    },
                    {
                      nihongo: "{私|わたし}",
                      trans: "제",
                    },
                    {
                      nihongo: "のです。",
                      trans: "것입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "{山|やま}{田|だ}", trans: "야마다" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/5.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{韓|かん}{国|こく}の",
                      trans: "한국의",
                    },
                    {
                      nihongo: "パソコン",
                      trans: "컴퓨터",
                    },

                    {
                      nihongo: "ですか。",
                      trans: "입니까?",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          speaker: { nihongo: "キム", trans: "김" },
          utters: [
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/6.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "いいえ、",
                      trans: "아니오,",
                    },
                    {
                      nihongo: "{韓|かん}{国|こく}の",
                      trans: "한국의",
                    },
                    {
                      nihongo: "パソコン",
                      trans: "컴퓨터",
                    },
                    {
                      nihongo: "じゃ",
                      trans: "가",
                    },
                    {
                      nihongo: "ありません。",
                      trans: "아닙니다.",
                    },
                  ],
                },
              ],
            },
            {
              voice: buildAssetsUrl("skusku/ch3/main-conv/7.mp3"),
              sentences: [
                {
                  phrases: [
                    {
                      nihongo: "{日|に}{本|ほん}の",
                      trans: "일본의",
                    },
                    {
                      nihongo: "パソコン",
                      trans: "컴퓨터",
                    },
                    {
                      nihongo: "です。",
                      trans: "입니다.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default MainConv3;
