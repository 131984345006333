import styled from "@emotion/styled";
import FontGaegu from "comps/typographies/FontGaegu";
import { PropsWithChildren } from "react";
import img from "./tip-icon.png";

const Wrapper = styled(FontGaegu)`
  display: flex;
  align-items: center;
  font-size: 1.125em;
`;

const Img = styled.img`
  width: 30px;
  margin-right: 0.5rem;
`;

const Tip: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <Img src={img} alt="tip" />
      <div>{children}</div>
    </Wrapper>
  );
};

export default Tip;
