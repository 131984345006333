import { useQuery } from "@apollo/client";
import { GET_QUIZHITS } from "gqls";
import BaseQuiz from "types/quiz/BaseQuiz";

const useQuizHits = (quizzes: BaseQuiz[]) => {
  const { loading, error, data } = useQuery(GET_QUIZHITS, {
    variables: {
      quiz_ids: quizzes.map((quiz) => quiz.id),
    },
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    error,
    quizHits: data?.quizHits,
  };
};

export default useQuizHits;
